import React, { useState } from "react";
import Header from "./header"; // Import the Header component
import Footer from "./footer";

const BookTablePage = () => {
  const [tableData, setTableData] = useState([
    { id: 1, publicationTitle: "NVIF", noOfBooksAvailable: 25 },
    { id: 2, publicationTitle: "IFCT", noOfBooksAvailable: 5 },
  ]);

  // Handle update button click
  const handleUpdate = (id) => {
    alert(`Update clicked for publication with ID: ${id}`);
    // You can add your update logic here
  };

  return (
    <div className="book-table-page">
      <Header />
      <div className="table-container">
        <table className="book-table">
          <thead>
            <tr>
              <th>Sl. No.</th>
              <th>Publication Title</th>
              <th>No. of Books Available</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={row.id}>
                <td>{index + 1}</td>
                <td>{row.publicationTitle}</td>
                <td>{row.noOfBooksAvailable}</td>
                <td>
                  <button className="update-btn" onClick={() => handleUpdate(row.id)}>
                    Update
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  );
};



export default BookTablePage;
