
// // // // // // // import React, { useRef } from 'react';
// // // // // // // import BASE_URL from '../../components/config/apiConfig';

// // // // // // // const divStyle = {
// // // // // // //   backgroundImage: `url(${process.env.PUBLIC_URL}/assets/aboutnin.jpg)`,
// // // // // // //   backgroundSize: '100% 100%', // Ensure the image covers the entire div
// // // // // // //   backgroundRepeat: 'no-repeat',
// // // // // // //   height: '100vh', // Adjust height as needed
// // // // // // //   display: 'flex',
// // // // // // //   flexDirection: 'column',
// // // // // // //   justifyContent: 'center',
// // // // // // //   alignItems: 'center',
// // // // // // //   color: 'white', // Text color
// // // // // // //   fontSize: '24px', // Adjust font size as needed
// // // // // // //   textAlign: 'center'
// // // // // // // };

// // // // // // // const DownloadAPKButton = () => {
// // // // // // //   const handleDownload = () => {
// // // // // // //     window.location.href = `${BASE_URL}/dashboard/apk`;
// // // // // // //   };

// // // // // // //   return (
// // // // // // //     <button onClick={handleDownload} style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#007bff', color: 'white', border: 'none', cursor: 'pointer' }}>
// // // // // // //       Download Nutrify India Now 2.0 App
// // // // // // //     </button>
// // // // // // //   );
// // // // // // // };

// // // // // // // const UploadAPKButton = () => {
// // // // // // //   const fileInputRef = useRef(null);

// // // // // // //   const handleUpload = () => {
// // // // // // //     fileInputRef.current.click(); // Trigger click on file input
// // // // // // //   };

// // // // // // //   const handleFileChange = (event) => {
// // // // // // //     const file = event.target.files[0];
// // // // // // //     if (file) {
// // // // // // //       // You can handle the file here, e.g., upload it using FormData
// // // // // // //       console.log('Selected file:', file);
// // // // // // //       // Example: Upload file using fetch or axios
// // // // // // //       const formData = new FormData();
// // // // // // //       formData.append('file', file);

// // // // // // //       fetch(`${BASE_URL}/dashboard/uploadApk`, {
// // // // // // //         method: 'POST',
// // // // // // //         body: formData
// // // // // // //       })
// // // // // // //       .then(response => {
// // // // // // //         // Handle response as needed
// // // // // // //         console.log('Upload response:', response);
// // // // // // //       })
// // // // // // //       .catch(error => {
// // // // // // //         console.error('Error uploading file:', error);
// // // // // // //       });
// // // // // // //     }
// // // // // // //   };

// // // // // // //   return (
// // // // // // //     <div>
// // // // // // //       <input
// // // // // // //         type="file"
// // // // // // //         ref={fileInputRef}
// // // // // // //         style={{ display: 'none' }}
// // // // // // //         onChange={handleFileChange}
// // // // // // //       />
// // // // // // //       <button onClick={handleUpload} style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#28a745', color: 'white', border: 'none', cursor: 'pointer' }}>
// // // // // // //         Upload APK
// // // // // // //       </button>
// // // // // // //     </div>
// // // // // // //   );
// // // // // // // };

// // // // // // // const DisplayMessage = () => {
// // // // // // //   return (
// // // // // // //     <div style={divStyle}>
// // // // // // //      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '20px' }}>
// // // // // // //   <h1 style={{ marginBottom: '20px' }}>Download Our App</h1>
// // // // // // //   <p style={{ marginBottom: '20px', maxWidth: '500px' }}>Click the button below to download our app:</p>
// // // // // // //   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
// // // // // // //     <DownloadAPKButton /> 
// // // // // // //     {/* <UploadAPKButton />
// // // // // // //     Coming soon..... */}
// // // // // // //   </div>
// // // // // // // </div>

// // // // // // //     </div>
// // // // // // //   );
// // // // // // // };

// // // // // // // export default DisplayMessage;






// // // // // // import React from 'react';
// // // // // // import styled, { createGlobalStyle } from 'styled-components';

// // // // // // // Global styles to reset margin and padding for body and html
// // // // // // const GlobalStyle = createGlobalStyle`
// // // // // //   * {
// // // // // //     margin: 0;
// // // // // //     padding: 0;
// // // // // //     box-sizing: border-box;
// // // // // //   }

// // // // // //   html, body {
// // // // // //     width: 100%;
// // // // // //     height: 100%;
// // // // // //   }
// // // // // // `;

// // // // // // // Page Wrapper with solid background color
// // // // // // const PageWrapper = styled.div`
// // // // // //   background: #e5eff3;  /* Light background color */
// // // // // //   min-height: 100vh;
// // // // // //   padding: 20px;
// // // // // //   font-family: 'Arial', sans-serif;
// // // // // //   color: #324e6a;
// // // // // // `;

// // // // // // // Simple Heading and Subheading with increased text size
// // // // // // const Heading = styled.h1`
// // // // // //   text-align: center;
// // // // // //   color: #324e6a;
// // // // // //   font-size: 3rem;  /* Increased font size */
// // // // // // `;

// // // // // // const SubHeading = styled.h2`
// // // // // //   text-align: center;
// // // // // //   color: #bb1111;
// // // // // //   font-size: 2rem;  /* Increased font size */
// // // // // //   margin-bottom: 20px;
// // // // // // `;

// // // // // // // Main content section with minimal margin and padding
// // // // // // const Content = styled.div`
// // // // // //   margin: 0 auto;
// // // // // //   padding: 0;  /* Removed padding */
// // // // // //   max-width: 100%;  /* Ensure it takes full width of the screen */
// // // // // //   line-height: 1.8;
// // // // // // `;

// // // // // // // Paragraph with zero padding on left-right
// // // // // // const Paragraph = styled.p`
// // // // // //   font-size: 1.2rem;  /* Increased font size */
// // // // // //   margin-bottom: 15px;
// // // // // //   padding: 0;  /* Removed padding */
// // // // // // `;

// // // // // // const KeyFeatureList = styled.ul`
// // // // // //   margin-left: 20px;
// // // // // //   list-style-type: disc;
// // // // // // `;

// // // // // // const Button = styled.button`
// // // // // //   display: block;
// // // // // //   margin: 30px auto;
// // // // // //   padding: 14px 35px; /* Increased padding for a bigger button */
// // // // // //   font-size: 1.4rem;  /* Increased font size */
// // // // // //   background-color: #324e6a;
// // // // // //   color: white;
// // // // // //   border: none;
// // // // // //   border-radius: 5px;
// // // // // //   cursor: pointer;

// // // // // //   &:hover {
// // // // // //     background-color: #2871a6;
// // // // // //   }
// // // // // // `;

// // // // // // // Download section styled for the Play Store link
// // // // // // const DownloadSection = styled.div`
// // // // // //   display: flex;
// // // // // //   flex-direction: column;
// // // // // //   align-items: center;
// // // // // //   justify-content: center;
// // // // // //   margin-top: 50px;
// // // // // //   background-color: #324e6a;
// // // // // //   padding: 40px;
// // // // // //   border-radius: 10px;
// // // // // //   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
// // // // // //   color: white;
// // // // // //   max-width: 90%;
// // // // // //   margin-left: auto;
// // // // // //   margin-right: auto;
// // // // // // `;

// // // // // // const DownloadHeading = styled.h3`
// // // // // //   font-size: 2rem;
// // // // // //   margin-bottom: 20px;
// // // // // //   text-align: center;
// // // // // // `;

// // // // // // const DownloadButton = styled.a`
// // // // // //   text-decoration: none;
// // // // // //   background-color: #3b9f6b;
// // // // // //   padding: 15px 30px;
// // // // // //   font-size: 1.5rem;
// // // // // //   border-radius: 5px;
// // // // // //   color: white;
// // // // // //   margin-top: 20px;

// // // // // //   &:hover {
// // // // // //     background-color: #2f7b56;
// // // // // //   }

// // // // // //   display: inline-block;
// // // // // //   text-align: center;
// // // // // // `;

// // // // // // const VisitorPage = () => {
// // // // // //   return (
// // // // // //     <>
// // // // // //       <GlobalStyle /> {/* Global reset */}
// // // // // //       <PageWrapper>
// // // // // //         <Heading>Welcome to Nutrify India Now 2.0</Heading>
// // // // // //         <SubHeading>Your Comprehensive Health and Nutrition Companion</SubHeading>

// // // // // //         <Content>
// // // // // //           <Paragraph>
// // // // // //             <strong>Nutrify India Now 2.0</strong>, developed in collaboration with ICMR-NIN, is designed to empower you with tools to take charge of your health and nutrition. With a range of features aimed at simplifying health tracking and providing insights into your well-being, Nutrify India Now 2.0 is your perfect health partner.
// // // // // //           </Paragraph>

// // // // // //           <hr />

// // // // // //           <h3><strong>Key Features</strong></h3>

// // // // // //           <h4><strong>Track and Record Your Meals</strong></h4>
// // // // // //           <Paragraph>
// // // // // //             Nutrify India Now 2.0 allows users to log all their meals, whether it’s breakfast, lunch, dinner, or snacks. The app offers the flexibility to create personalized meals or use pre-designed options. Simply input your food items, and the app will record their nutritional values. You can then analyze your nutrition intake daily, weekly, or monthly. This feature enables you to maintain a balanced diet and keep track of your nutritional goals effortlessly.
// // // // // //           </Paragraph>

// // // // // //           <h4><strong>Comprehensive Health Monitoring</strong></h4>
// // // // // //           <KeyFeatureList>
// // // // // //             <li><strong>Weight Tracking</strong>: Keep a record of your weight changes and trends over time. This feature helps you monitor your progress and stay motivated.</li>
// // // // // //             <li><strong>Water Intake Monitoring</strong>: Stay hydrated by logging your daily water consumption. The app ensures you meet your hydration goals.</li>
// // // // // //             <li><strong>Sleep Analysis</strong>: Log your sleep patterns and analyze their impact on your overall health. This helps you identify areas where you can improve your rest and recovery.</li>
// // // // // //           </KeyFeatureList>

// // // // // //           <h4><strong>24-Hour Activity Tracking</strong></h4>
// // // // // //           <Paragraph>
// // // // // //             Monitor your physical activity throughout the day. Whether you’re walking, running, or engaging in any other form of exercise, Nutrify India Now 2.0 keeps a record of your activity levels, providing insights that help you stay active and healthy.
// // // // // //           </Paragraph>

// // // // // //           <h4><strong>Smartwatch Integration</strong></h4>
// // // // // //           <Paragraph>
// // // // // //             The app seamlessly integrates with your smartwatch, enabling real-time tracking of your health and fitness data. This connectivity ensures that all your health metrics are consolidated in one place, making it easier to track your progress.
// // // // // //           </Paragraph>

// // // // // //           <h4><strong>Health Calculators</strong></h4>
// // // // // //           <KeyFeatureList>
// // // // // //             <li><strong>BMI (Body Mass Index) Calculator</strong>: Determine your weight category and understand where you stand.</li>
// // // // // //             <li><strong>BMR (Basal Metabolic Rate) Calculator</strong>: Estimate your daily calorie needs based on your activity level and metabolism.</li>
// // // // // //           </KeyFeatureList>
// // // // // //           <Paragraph>
// // // // // //             These calculators provide a clear picture of your health and help you make informed decisions.
// // // // // //           </Paragraph>

// // // // // //           <h4><strong>Access NIN Publications</strong></h4>
// // // // // //           <Paragraph>
// // // // // //             Explore and purchase health and nutrition publications directly through the app. Gain access to valuable resources created by the experts at ICMR-NIN, covering various aspects of health and wellness.
// // // // // //           </Paragraph>

// // // // // //           <hr />

// // // // // //           <h3><strong>Why Choose Nutrify India Now 2.0?</strong></h3>
// // // // // //           <Paragraph>
// // // // // //             Nutrify India Now 2.0 combines advanced features with an intuitive interface to make health tracking simple and effective. Whether you’re focused on meal tracking, activity monitoring, or understanding your body’s needs, this app offers a comprehensive solution. Designed for users of all ages, it’s a must-have tool for anyone looking to lead a healthier lifestyle.
// // // // // //           </Paragraph>

// // // // // //           <hr />

// // // // // //           <h3><strong>Download the App Today</strong></h3>
// // // // // //           <Paragraph>
// // // // // //             Start your journey toward better health with Nutrify India Now 2.0. The app is available for download on Android:
// // // // // //           </Paragraph>

// // // // // //           <DownloadSection>
// // // // // //             <DownloadHeading>Download Nutrify India Now 2.0</DownloadHeading>
// // // // // //             <DownloadButton href="https://play.google.com/store/apps/details?id=nutrify.india.now" target="_blank" rel="noopener noreferrer">
// // // // // //               Download Now from Google Play
// // // // // //             </DownloadButton>
// // // // // //           </DownloadSection>

// // // // // //           <Button>Take the first step in transforming your health today with Nutrify India Now 2.0!</Button>
// // // // // //         </Content>
// // // // // //       </PageWrapper>
// // // // // //     </>
// // // // // //   );
// // // // // // };

// // // // // // export default VisitorPage;
// // // // // import React from 'react';
// // // // // import styled, { createGlobalStyle } from 'styled-components';

// // // // // // Global styles to reset margin and padding for body and html
// // // // // const GlobalStyle = createGlobalStyle`
// // // // //   * {
// // // // //     margin: 0;
// // // // //     padding: 0;
// // // // //     box-sizing: border-box;
// // // // //   }

// // // // //   html, body {
// // // // //     font-family: 'Arial', sans-serif;
// // // // //     color: #324e6a;
// // // // //     width: 100%;
// // // // //     height: 100%;
// // // // //   }
// // // // // `;

// // // // // // Page Wrapper with solid background color
// // // // // const PageWrapper = styled.div`
// // // // //   // background: #f4f8fb;  /* Light background color */
// // // // //   min-height: 100vh;
// // // // //   padding: 20px;
// // // // //   font-family: 'Arial', sans-serif;
// // // // //   color: #324e6a;
// // // // // `;

// // // // // // Header Section
// // // // // const HeaderSection = styled.section`
// // // // //   text-align: center;
  

// // // // //   color: black;

// // // // //   margin-bottom: 40px;
// // // // // `;

// // // // // const Heading = styled.h1`
// // // // //   font-size: 3rem;
// // // // //   font-weight: bold;
// // // // //   margin-bottom: 20px;
// // // // // `;

// // // // // const SubHeading = styled.h2`
// // // // //   font-size: 1.8rem;
// // // // //   margin-bottom: 20px;
// // // // //   font-weight: 300;
// // // // // `;

// // // // // const Paragraph = styled.p`
// // // // //   font-size: 1.2rem;
// // // // //   line-height: 1.8;
// // // // //   margin-bottom: 30px;
// // // // //   padding: 0 10px;
// // // // // `;

// // // // // // Features Section
// // // // // const FeaturesSection = styled.section`
// // // // //   margin-bottom: 50px;
// // // // // `;

// // // // // const FeatureHeading = styled.h3`
// // // // //   text-align: center;
// // // // //   font-size: 2.5rem;
// // // // //   margin-bottom: 20px;
// // // // //   font-weight: 600;
// // // // // `;

// // // // // const FeatureItem = styled.div`
// // // // //   display: flex;
// // // // //   align-items: center;
// // // // //   margin-bottom: 20px;
// // // // //   padding: 10px;
// // // // //   background-color: #ffffff;
// // // // //   border-radius: 10px;
// // // // //   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
// // // // //   transition: transform 0.3s ease;

// // // // //   &:hover {
// // // // //     transform: translateY(-5px);
// // // // //   }
// // // // // `;

// // // // // const FeatureIcon = styled.div`
// // // // //   background-color: #3b9f6b;
// // // // //   color: white;
// // // // //   width: 50px;
// // // // //   height: 50px;
// // // // //   border-radius: 50%;
// // // // //   display: flex;
// // // // //   justify-content: center;
// // // // //   align-items: center;
// // // // //   font-size: 1.8rem;
// // // // //   margin-right: 20px;
// // // // // `;

// // // // // const FeatureText = styled.div`
// // // // //   flex: 1;
// // // // // `;

// // // // // const DownloadSection = styled.section`
// // // // //   display: flex;
// // // // //   flex-direction: column;
// // // // //   align-items: center;
// // // // //   justify-content: center;
// // // // //   // background-color: #324e6a;
// // // // //   padding: 40px 20px;
// // // // //   border-radius: 10px;
// // // // //   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
// // // // //   color: white;
// // // // // `;

// // // // // const DownloadHeading = styled.h3`
// // // // //   font-size: 2.5rem;
// // // // //   margin-bottom: 20px;
// // // // // `;

// // // // // const DownloadButton = styled.a`
// // // // //   text-decoration: none;
// // // // //   background-color: #3b9f6b;
// // // // //   padding: 15px 40px;
// // // // //   font-size: 1.5rem;
// // // // //   border-radius: 5px;
// // // // //   color: white;
// // // // //   margin-top: 20px;
// // // // //   text-align: center;

// // // // //   &:hover {
// // // // //     background-color: #2f7b56;
// // // // //   }
// // // // // `;

// // // // // const VisitorPage = () => {
// // // // //   return (
// // // // //     <>
// // // // //       <GlobalStyle />
// // // // //       <PageWrapper>
// // // // //         {/* Header Section */}
// // // // //         <HeaderSection>
// // // // //           <Heading>Welcome to Nutrify India Now 2.0</Heading>
// // // // //           </HeaderSection>
// // // // //           <SubHeading>Your Comprehensive Health and Nutrition Companion</SubHeading>
// // // // //           <Paragraph>
// // // // //             Nutrify India Now 2.0, is your go-to app for managing health and nutrition. From meal tracking to health monitoring, we provide tools to make your health journey easier.
// // // // //           </Paragraph>
        

// // // // //         {/* Features Section */}
// // // // //         <FeaturesSection>
// // // // //           <FeatureHeading>Key Features</FeatureHeading>

// // // // //           <FeatureItem>
// // // // //             <FeatureIcon>🍽️</FeatureIcon>
// // // // //             <FeatureText>
// // // // //               <h4>Track and Record Your Meals</h4>
// // // // //               <p>
// // // // //                 Log your meals and get nutritional insights on the go. With easy meal creation or pre-designed options, track calories and nutrients efficiently.
// // // // //               </p>
// // // // //             </FeatureText>
// // // // //           </FeatureItem>

// // // // //           <FeatureItem>
// // // // //             <FeatureIcon>🏃‍♂️</FeatureIcon>
// // // // //             <FeatureText>
// // // // //               <h4>Comprehensive Health Monitoring</h4>
// // // // //               <p>
// // // // //                 Track your weight, water intake, sleep patterns, and daily activities. Get real-time updates on your health progress.
// // // // //               </p>
// // // // //             </FeatureText>
// // // // //           </FeatureItem>

// // // // //           <FeatureItem>
// // // // //             <FeatureIcon>📱</FeatureIcon>
// // // // //             <FeatureText>
// // // // //               <h4>Smartwatch Integration</h4>
// // // // //               <p>
// // // // //                 Sync your health data with your smartwatch for real-time tracking of your fitness activities.
// // // // //               </p>
// // // // //             </FeatureText>
// // // // //           </FeatureItem>

// // // // //           <FeatureItem>
// // // // //             <FeatureIcon>📚</FeatureIcon>
// // // // //             <FeatureText>
// // // // //               <h4>Access NIN Publications</h4>
// // // // //               <p>
// // // // //                 Browse through health and nutrition publications by ICMR-NIN. Learn more about nutrition science and stay informed.
// // // // //               </p>
// // // // //             </FeatureText>
// // // // //           </FeatureItem>
// // // // //         </FeaturesSection>

// // // // //         {/* Download Section */}
// // // // //         <DownloadSection>
// // // // //           <DownloadHeading>Download Nutrify India Now 2.0</DownloadHeading>
// // // // //           <DownloadButton href="https://play.google.com/store/apps/details?id=nutrify.india.now" target="_blank" rel="noopener noreferrer">
// // // // //             Download Now from Google Play
// // // // //           </DownloadButton>
// // // // //         </DownloadSection>

// // // // //         {/* Call to Action Section */}
// // // // //         <section style={{ textAlign: 'center', marginTop: '40px' }}>
// // // // //           <h2 style={{ fontSize: '2rem', color: '#324e6a' }}>
// // // // //             Take control of your health today with Nutrify India Now 2.0!
// // // // //           </h2>
// // // // //         </section>
// // // // //       </PageWrapper>
// // // // //     </>
// // // // //   );
// // // // // };

// // // // // export default VisitorPage;
// // // // import React from 'react';
// // // // import styled, { createGlobalStyle } from 'styled-components';
// // // // import Footer from "../footer/Footer"; 
// // // // const GlobalStyle = createGlobalStyle`
// // // //   * {
// // // //     margin: 0;
// // // //     padding: 0;
// // // //     box-sizing: border-box;
// // // //   }

// // // //   html, body {
// // // //     font-family: 'Arial', sans-serif;
// // // //     color: #324e6a;
// // // //     width: 100%;
// // // //     height: 100%;
// // // //   }
// // // // `;

// // // // // Page Wrapper with solid background color
// // // // const PageWrapper = styled.div`
// // // //   min-height: 100vh;
// // // //   padding: 20px;
// // // //   font-family: 'Arial', sans-serif;
// // // //   color: #324e6a;
// // // // `;

// // // // // Header Section
// // // // const HeaderSection = styled.section`
// // // //   text-align: center;
// // // //   color: black;
// // // //   margin-bottom: 40px;
// // // // `;

// // // // const Heading = styled.h1`
// // // //   font-size: 3rem;
// // // //   font-weight: bold;
// // // //   margin-bottom: 20px;
// // // // `;

// // // // const SubHeading = styled.h2`
// // // //   font-size: 1.8rem;
// // // //   margin-bottom: 20px;
// // // //   font-weight: 300;
// // // // `;

// // // // const Paragraph = styled.p`
// // // //   font-size: 1.2rem;
// // // //   line-height: 1.8;
// // // //   margin-bottom: 30px;
// // // //   padding: 0 10px;
// // // // `;

// // // // // Features Section
// // // // const FeaturesSection = styled.section`
// // // //   margin-bottom: 50px;
// // // // `;

// // // // const FeatureHeading = styled.h3`
// // // //   text-align: center;
// // // //   font-size: 2.5rem;
// // // //   margin-bottom: 20px;
// // // //   font-weight: 600;
// // // // `;

// // // // const FeatureItem = styled.div`
// // // //   display: flex;
// // // //   align-items: center;
// // // //   margin-bottom: 20px;
// // // //   padding: 10px;
// // // //   background-color: #ffffff;
// // // //   border-radius: 10px;
// // // //   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
// // // //   transition: transform 0.3s ease;

// // // //   &:hover {
// // // //     transform: translateY(-5px);
// // // //   }
// // // // `;

// // // // const FeatureIcon = styled.div`
// // // //   background-color: #3b9f6b;
// // // //   color: white;
// // // //   width: 50px;
// // // //   height: 50px;
// // // //   border-radius: 50%;
// // // //   display: flex;
// // // //   justify-content: center;
// // // //   align-items: center;
// // // //   font-size: 1.8rem;
// // // //   margin-right: 20px;
// // // // `;

// // // // const FeatureText = styled.div`
// // // //   flex: 1;
// // // // `;

// // // // // Download Section
// // // // const DownloadSection = styled.section`
// // // //   display: flex;
// // // //   align-items: center;
// // // //   justify-content: center;
// // // //   gap: 20px; /* Space between the heading and the button */
// // // //   margin-top: 40px;
// // // //   padding: 20px;
// // // // `;

// // // // const DownloadHeading = styled.h3`
// // // //   font-size: 2rem;
// // // //   margin: 0;
// // // // `;

// // // // const DownloadButton = styled.a`
// // // //   text-decoration: none;
// // // //   background-color: #3b9f6b;
// // // //   padding: 15px 40px;
// // // //   font-size: 1.5rem;
// // // //   border-radius: 5px;
// // // //   color: white;
// // // //   text-align: center;

// // // //   &:hover {
// // // //     background-color: #2f7b56;
// // // //   }
// // // // `;

// // // // const VisitorPage = () => {
// // // //   return (
// // // //     <>
// // // //       <GlobalStyle />
// // // //       <PageWrapper>
// // // //         {/* Header Section */}
// // // //         <HeaderSection>
// // // //           <Heading>Welcome to Nutrify India Now 2.0</Heading>
// // // //         </HeaderSection>
// // // //         <SubHeading>Your Comprehensive Health and Nutrition Companion</SubHeading>
// // // //         <Paragraph>
// // // //           Nutrify India Now 2.0, is your go-to app for managing health and nutrition. From meal tracking to health monitoring, we provide tools to make your health journey easier.
// // // //         </Paragraph>

// // // //         {/* Features Section */}
// // // //         <FeaturesSection>
// // // //           <FeatureHeading>Key Features</FeatureHeading>

// // // //           <FeatureItem>
// // // //             <FeatureIcon>🍽️</FeatureIcon>
// // // //             <FeatureText>
// // // //               <h4>Track and Record Your Meals</h4>
// // // //               <p>
// // // //                 Nutrify India Now 2.0 allows users to log all their meals, whether it’s breakfast, lunch, dinner, or snacks. The app offers the flexibility to create personalized meals or use pre-designed options. Simply input your food items, and the app will record their nutritional values. You can then analyze your nutrition intake daily, weekly, or monthly. This feature enables you to maintain a balanced diet and keep track of your nutritional goals effortlessly.
// // // //               </p>
// // // //             </FeatureText>
// // // //           </FeatureItem>

// // // //           <FeatureItem>
// // // //             <FeatureIcon>🏃‍♂️</FeatureIcon>
// // // //             <FeatureText>
// // // //               <h4>Comprehensive Health Monitoring</h4>
// // // //               <p>
// // // //                 Stay on top of your health with features designed to track key metrics:
// // // //                 <br /> 
// // // //                 <strong>Weight Tracking:</strong> Keep a record of your weight changes and trends over time.
// // // //                 <br /> 
// // // //                 <strong>Water Intake Monitoring:</strong> Stay hydrated by logging your daily water consumption.
// // // //                 <br /> 
// // // //                 <strong>Sleep Analysis:</strong> Log your sleep patterns and analyze their impact on your overall health.
// // // //                 <br /> 
// // // //                 <strong>24-Hour Activity Tracking:</strong> Monitor your physical activity throughout the day.
// // // //               </p>
// // // //             </FeatureText>
// // // //           </FeatureItem>

// // // //           <FeatureItem>
// // // //             <FeatureIcon>📱</FeatureIcon>
// // // //             <FeatureText>
// // // //               <h4>Smartwatch Integration</h4>
// // // //               <p>
// // // //                 The app seamlessly integrates with your smartwatch, enabling real-time tracking of your health and fitness data. This connectivity ensures that all your health metrics are consolidated in one place, making it easier to track your progress.
// // // //               </p>
// // // //             </FeatureText>
// // // //           </FeatureItem>

// // // //           <FeatureItem>
// // // //             <FeatureIcon>🧮</FeatureIcon>
// // // //             <FeatureText>
// // // //               <h4>Health Calculators</h4>
// // // //               <p>
// // // //                 Nutrify India Now 2.0 includes powerful tools such as:
// // // //                 <br />
// // // //                 <strong>BMI (Body Mass Index) Calculator:</strong> Determine your weight category and understand where you stand.
// // // //                 <br />
// // // //                 <strong>BMR (Basal Metabolic Rate) Calculator:</strong> Estimate your daily calorie needs based on your activity level and metabolism.
// // // //               </p>
// // // //             </FeatureText>
// // // //           </FeatureItem>

// // // //           <FeatureItem>
// // // //             <FeatureIcon>📚</FeatureIcon>
// // // //             <FeatureText>
// // // //               <h4>Access NIN Publications</h4>
// // // //               <p>
// // // //                 Explore and purchase health and nutrition publications directly through the app. Gain access to valuable resources created by the experts at ICMR-NIN, covering various aspects of health and wellness.
// // // //               </p>
// // // //             </FeatureText>
// // // //           </FeatureItem>
// // // //         </FeaturesSection>

// // // //         {/* Download Section */}
// // // //        <DownloadSection>
// // // //   <DownloadHeading>Download Nutrify India Now 2.0</DownloadHeading>
// // // //   <DownloadButton
// // // //     href="https://play.google.com/store/apps/details?id=nutrify.india.now"
// // // //     target="_blank"
// // // //     rel="noopener noreferrer"
// // // //   >
// // // //     Download Now from Google Play
// // // //   </DownloadButton>
// // // // </DownloadSection>


// // // // <section style={{ textAlign: 'center', marginTop: '20px' }}>
// // // //   <h2 style={{ fontSize: '1.6rem', color: '#324e6a' }}>
// // // //     Take control of your health today with Nutrify India Now 2.0!
// // // //   </h2>
// // // // </section>

// // // //       </PageWrapper>
     
// // // //     </>
// // // //   );
// // // // };

// // // // export default VisitorPage;
// // // import React from 'react';
// // // import styled, { createGlobalStyle } from 'styled-components';
// // // import Footer from "../footer/Footer"; // Importing the Footer component

// // // const GlobalStyle = createGlobalStyle`
// // //   * {
// // //     margin: 0;
// // //     padding: 0;
// // //     box-sizing: border-box;
// // //   }

// // //   html, body {
// // //     font-family: 'Arial', sans-serif;
// // //     color: #324e6a;
// // //     width: 100%;
// // //     height: 100%;
// // //   }
// // // `;

// // // const PageWrapper = styled.div`
// // //   min-height: 100vh;
// // //   display: flex;
// // //   flex-direction: column;
// // // `;

// // // const ContentWrapper = styled.div`
// // //   flex: 1; /* Ensures content takes up available space */
// // //   padding: 20px;
// // //   font-family: 'Arial', sans-serif;
// // //   color: #324e6a;
// // // `;

// // // // Header Section
// // // const HeaderSection = styled.section`
// // //   text-align: center;
// // //   color: black;
// // //   margin-bottom: 40px;
// // // `;

// // // const Heading = styled.h1`
// // //   font-size: 3rem;
// // //   font-weight: bold;
// // //   margin-bottom: 20px;
// // // `;

// // // const SubHeading = styled.h2`
// // //   font-size: 1.8rem;
// // //   margin-bottom: 20px;
// // //   font-weight: 300;
// // // `;

// // // const Paragraph = styled.p`
// // //   font-size: 1.2rem;
// // //   line-height: 1.8;
// // //   margin-bottom: 30px;
// // //   padding: 0 10px;
// // // `;

// // // // Features Section
// // // const FeaturesSection = styled.section`
// // //   margin-bottom: 50px;
// // // `;

// // // const FeatureHeading = styled.h3`
// // //   text-align: center;
// // //   font-size: 2.5rem;
// // //   margin-bottom: 20px;
// // //   font-weight: 600;
// // // `;

// // // const FeatureItem = styled.div`
// // //   display: flex;
// // //   align-items: center;
// // //   margin-bottom: 20px;
// // //   padding: 10px;
// // //   background-color: #ffffff;
// // //   border-radius: 10px;
// // //   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
// // //   transition: transform 0.3s ease;

// // //   &:hover {
// // //     transform: translateY(-5px);
// // //   }
// // // `;

// // // const FeatureIcon = styled.div`
// // //   background-color: #3b9f6b;
// // //   color: white;
// // //   width: 50px;
// // //   height: 50px;
// // //   border-radius: 50%;
// // //   display: flex;
// // //   justify-content: center;
// // //   align-items: center;
// // //   font-size: 1.8rem;
// // //   margin-right: 20px;
// // // `;

// // // const FeatureText = styled.div`
// // //   flex: 1;
// // // `;

// // // // Download Section
// // // const DownloadSection = styled.section`
// // //   display: flex;
// // //   align-items: center;
// // //   justify-content: center;
// // //   gap: 20px;
// // //   margin-top: 40px;
// // //   padding: 20px;
// // // `;

// // // const DownloadHeading = styled.h3`
// // //   font-size: 2rem;
// // //   margin: 0;
// // // `;

// // // const DownloadButton = styled.a`
// // //   text-decoration: none;
// // //   background-color: #3b9f6b;
// // //   padding: 15px 40px;
// // //   font-size: 1.5rem;
// // //   border-radius: 5px;
// // //   color: white;
// // //   text-align: center;

// // //   &:hover {
// // //     background-color: #2f7b56;
// // //   }
// // // `;

// // // const VisitorPage = () => {
// // //   return (
// // //     <>
// // //       <GlobalStyle />
// // //       <PageWrapper>
// // //         <ContentWrapper>
// // //           {/* Header Section */}
// // //           <HeaderSection>
// // //             <Heading>Welcome to Nutrify India Now 2.0</Heading>
// // //           </HeaderSection>
// // //           <SubHeading>Your Comprehensive Health and Nutrition Companion</SubHeading>
// // //           <Paragraph>
// // //             Nutrify India Now 2.0, is your go-to app for managing health and nutrition. From meal tracking to health monitoring, we provide tools to make your health journey easier.
// // //           </Paragraph>

// // //           {/* Features Section */}
// // //           <FeaturesSection>
// // //             <FeatureHeading>Key Features</FeatureHeading>

// // //             <FeatureItem>
// // //               <FeatureIcon>🍽️</FeatureIcon>
// // //               <FeatureText>
// // //                 <h4>Track and Record Your Meals</h4>
// // //                 <p>
// // //                   Nutrify India Now 2.0 allows users to log all their meals, whether it’s breakfast, lunch, dinner, or snacks. The app offers the flexibility to create personalized meals or use pre-designed options. Simply input your food items, and the app will record their nutritional values. You can then analyze your nutrition intake daily, weekly, or monthly. This feature enables you to maintain a balanced diet and keep track of your nutritional goals effortlessly.
// // //                 </p>
// // //               </FeatureText>
// // //             </FeatureItem>

// // //             <FeatureItem>
// // //               <FeatureIcon>🏃‍♂️</FeatureIcon>
// // //               <FeatureText>
// // //                 <h4>Comprehensive Health Monitoring</h4>
// // //                 <p>
// // //                   Stay on top of your health with features designed to track key metrics:
// // //                   <br />
// // //                   <strong>Weight Tracking:</strong> Keep a record of your weight changes and trends over time.
// // //                   <br />
// // //                   <strong>Water Intake Monitoring:</strong> Stay hydrated by logging your daily water consumption.
// // //                   <br />
// // //                   <strong>Sleep Analysis:</strong> Log your sleep patterns and analyze their impact on your overall health.
// // //                   <br />
// // //                   <strong>24-Hour Activity Tracking:</strong> Monitor your physical activity throughout the day.
// // //                 </p>
// // //               </FeatureText>
// // //             </FeatureItem>

// // //             {/* Additional Feature Items */}
// // //           </FeaturesSection>

// // //           {/* Download Section */}
// // //           <DownloadSection>
// // //             <DownloadHeading>Download Nutrify India Now 2.0</DownloadHeading>
// // //             <DownloadButton
// // //               href="https://play.google.com/store/apps/details?id=nutrify.india.now"
// // //               target="_blank"
// // //               rel="noopener noreferrer"
// // //             >
// // //               Download Now from Google Play
// // //             </DownloadButton>
// // //           </DownloadSection>

// // //           <section style={{ textAlign: 'center', marginTop: '20px' }}>
// // //             <h2 style={{ fontSize: '1.6rem', color: '#324e6a' }}>
// // //               Take control of your health today with Nutrify India Now 2.0!
// // //             </h2>
// // //           </section>
// // //         </ContentWrapper>

// // //         {/* Footer Section */}
// // //         <Footer />
// // //       </PageWrapper>
// // //     </>
// // //   );
// // // };

// // // export default VisitorPage;
// // import React from 'react';
// // import styled, { createGlobalStyle } from 'styled-components';
// // import Footer from "../footer/Footer"; // Importing the Footer component

// // const GlobalStyle = createGlobalStyle`
// //   * {
// //     margin: 0;
// //     padding: 0;
// //     box-sizing: border-box;
// //   }

// //   html, body {
// //     font-family: 'Arial', sans-serif;
// //     color: #324e6a;
// //     width: 100%;
// //     height: 100%;
// //   }
// // `;

// // const PageWrapper = styled.div`
// //   display: flex;
// //   flex-direction: column;
// //   min-height: 100vh; /* Ensures the page takes up the full height of the viewport */
// // `;

// // const ContentWrapper = styled.div`
// //   flex: 1; /* Ensures content takes up available space */
// //   padding: 20px;
// //   font-family: 'Arial', sans-serif;
// //   color: #324e6a;
// // `;

// // // Header Section
// // const HeaderSection = styled.section`
// //   text-align: center;
// //   color: black;
// //   margin-bottom: 40px;
// // `;

// // const Heading = styled.h1`
// //   font-size: 3rem;
// //   font-weight: bold;
// //   margin-bottom: 20px;
// // `;

// // const SubHeading = styled.h2`
// //   font-size: 1.8rem;
// //   margin-bottom: 20px;
// //   font-weight: 300;
// // `;

// // const Paragraph = styled.p`
// //   font-size: 1.2rem;
// //   line-height: 1.8;
// //   margin-bottom: 30px;
// //   padding: 0 10px;
// // `;

// // // Features Section
// // const FeaturesSection = styled.section`
// //   margin-bottom: 50px;
// // `;

// // const FeatureHeading = styled.h3`
// //   text-align: center;
// //   font-size: 2.5rem;
// //   margin-bottom: 20px;
// //   font-weight: 600;
// // `;

// // const FeatureItem = styled.div`
// //   display: flex;
// //   align-items: center;
// //   margin-bottom: 20px;
// //   padding: 10px;
// //   background-color: #ffffff;
// //   border-radius: 10px;
// //   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
// //   transition: transform 0.3s ease;

// //   &:hover {
// //     transform: translateY(-5px);
// //   }
// // `;

// // const FeatureIcon = styled.div`
// //   background-color: #3b9f6b;
// //   color: white;
// //   width: 50px;
// //   height: 50px;
// //   border-radius: 50%;
// //   display: flex;
// //   justify-content: center;
// //   align-items: center;
// //   font-size: 1.8rem;
// //   margin-right: 20px;
// // `;

// // const FeatureText = styled.div`
// //   flex: 1;
// // `;

// // // Download Section
// // const DownloadSection = styled.section`
// //   display: flex;
// //   align-items: center;
// //   justify-content: center;
// //   gap: 20px;
// //   margin-top: 40px;
// //   padding: 20px;
// // `;

// // const DownloadHeading = styled.h3`
// //   font-size: 2rem;
// //   margin: 0;
// // `;

// // const DownloadButton = styled.a`
// //   text-decoration: none;
// //   background-color: #3b9f6b;
// //   padding: 15px 40px;
// //   font-size: 1.5rem;
// //   border-radius: 5px;
// //   color: white;
// //   text-align: center;

// //   &:hover {
// //     background-color: #2f7b56;
// //   }
// // `;

// // const VisitorPage = () => {
// //   return (
// //     <>
// //       <GlobalStyle />
// //       <PageWrapper>
// //         <ContentWrapper>
// //           {/* Header Section */}
// //           <HeaderSection>
// //             <Heading>Welcome to Nutrify India Now 2.0</Heading>
// //           </HeaderSection>
// //           <SubHeading>Your Comprehensive Health and Nutrition Companion</SubHeading>
// //           <Paragraph>
// //             Nutrify India Now 2.0, is your go-to app for managing health and nutrition. From meal tracking to health monitoring, we provide tools to make your health journey easier.
// //           </Paragraph>

// //           {/* Features Section */}
// //           <FeaturesSection>
// //             <FeatureHeading>Key Features</FeatureHeading>

// //             <FeatureItem>
// //               <FeatureIcon>🍽️</FeatureIcon>
// //               <FeatureText>
// //                 <h4>Track and Record Your Meals</h4>
// //                 <p>
// //                   Nutrify India Now 2.0 allows users to log all their meals, whether it’s breakfast, lunch, dinner, or snacks. The app offers the flexibility to create personalized meals or use pre-designed options. Simply input your food items, and the app will record their nutritional values. You can then analyze your nutrition intake daily, weekly, or monthly. This feature enables you to maintain a balanced diet and keep track of your nutritional goals effortlessly.
// //                 </p>
// //               </FeatureText>
// //             </FeatureItem>

// //             <FeatureItem>
// //               <FeatureIcon>🏃‍♂️</FeatureIcon>
// //               <FeatureText>
// //                 <h4>Comprehensive Health Monitoring</h4>
// //                 <p>
// //                   Stay on top of your health with features designed to track key metrics:
// //                   <br />
// //                   <strong>Weight Tracking:</strong> Keep a record of your weight changes and trends over time.
// //                   <br />
// //                   <strong>Water Intake Monitoring:</strong> Stay hydrated by logging your daily water consumption.
// //                   <br />
// //                   <strong>Sleep Analysis:</strong> Log your sleep patterns and analyze their impact on your overall health.
// //                   <br />
// //                   <strong>24-Hour Activity Tracking:</strong> Monitor your physical activity throughout the day.
// //                 </p>
// //               </FeatureText>
// //             </FeatureItem>

// //             {/* Additional Feature Items */}
// //           </FeaturesSection>

// //           {/* Download Section */}
// //           <DownloadSection>
// //             <DownloadHeading>Download Nutrify India Now 2.0</DownloadHeading>
// //             <DownloadButton
// //               href="https://play.google.com/store/search?q=nutrify+india+now+2.0&c=apps&hl=en"
// //               target="_blank"
// //               rel="noopener noreferrer"
// //             >
// //               Download Now from Google Play
// //             </DownloadButton>
// //           </DownloadSection>

// //           <section style={{ textAlign: 'center', marginTop: '20px' }}>
// //             <h2 style={{ fontSize: '1.6rem', color: '#324e6a' }}>
// //               Take control of your health today with Nutrify India Now 2.0!
// //             </h2>
// //           </section>
// //         </ContentWrapper>

// //         {/* Footer Section */}
// //         <Footer />
// //       </PageWrapper>
// //     </>
// //   );
// // };

// // export default VisitorPage;
//  import React from 'react';
// import styled, { createGlobalStyle } from 'styled-components';
// import Footer from "../footer/Footer"; 
// const GlobalStyle = createGlobalStyle`
//   * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//   }

//   html, body {
//     font-family: 'Arial', sans-serif;
//     color: #324e6a;
//     width: 100%;
//     height: 100%;
//   }
// `;

// // Page Wrapper with solid background color
// const PageWrapper = styled.div`
//   min-height: 100vh;
//   // padding: 20px;
//   font-family: 'Arial', sans-serif;
//   color: #324e6a;
// `;

// // Header Section
// const HeaderSection = styled.section`
//   text-align: center;
//   color: black;
//   margin-bottom: 40px;
// `;

// const Heading = styled.h1`
//   // font-size: 3rem;
//   // font-weight: bold;
//   // margin-bottom: 20px;
//   background-color: #324e6a; /* Background color */
//   color: white; /* Text color */
//   // padding: 20px; /* Padding around the text */
//   // border-radius: 10px; /* Optional: Rounded corners */
//   // text-align: center; /* Center text */
// `;


// const SubHeading = styled.h2`
//   font-size: 1.8rem;
//   margin-bottom: 20px;
//   font-weight: 300;
// `;

// const Paragraph = styled.p`
//   font-size: 1.2rem;
//   line-height: 1.8;
//   margin-bottom: 30px;
//   padding: 0 10px;
// `;

// // Features Section
// const FeaturesSection = styled.section`
//   margin-bottom: 50px;
// `;

// const FeatureHeading = styled.h3`
//   text-align: center;
//   font-size: 2.5rem;
//   margin-bottom: 20px;
//   font-weight: 600;
// `;

// const FeatureItem = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 20px;
//   padding: 10px;
//   background-color: #ffffff;
//   border-radius: 10px;
//   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
//   transition: transform 0.3s ease;

//   &:hover {
//     transform: translateY(-5px);
//   }
// `;

// const FeatureIcon = styled.div`
//   background-color: #3b9f6b;
//   color: white;
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 1.8rem;
//   margin-right: 20px;
// `;

// const FeatureText = styled.div`
//   flex: 1;
// `;

// // Download Section
// const DownloadSection = styled.section`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 20px; /* Space between the heading and the button */
//   margin-top: 40px;
//   padding: 20px;
// `;

// const DownloadHeading = styled.h3`
//   font-size: 2rem;
//   margin: 0;
// `;

// const DownloadButton = styled.a`
//   text-decoration: none;
//   background-color: #3b9f6b;
//   padding: 15px 40px;
//   font-size: 1.5rem;
//   border-radius: 5px;
//   color: white;
//   text-align: center;

//   &:hover {
//     background-color: #2f7b56;
//   }
// `;

// const VisitorPage = () => {
//   return (
//     <>
//       <GlobalStyle />
//       <PageWrapper>
//         {/* Header Section */}
//         <HeaderSection>
//           <Heading>Welcome to Nutrify India Now 2.0</Heading>
//         </HeaderSection>
//         <SubHeading>Your Comprehensive Health and Nutrition Companion</SubHeading>
//         <Paragraph>
//           Nutrify India Now 2.0, is your go-to app for managing health and nutrition. From meal tracking to health monitoring, we provide tools to make your health journey easier.
//         </Paragraph>

//         {/* Features Section */}
//         <FeaturesSection>
//           <FeatureHeading>Key Features</FeatureHeading>

//           <FeatureItem>
//             <FeatureIcon>🍽️</FeatureIcon>
//             <FeatureText>
//               <h4>Track and Record Your Meals</h4>
//               <p>
//                 Nutrify India Now 2.0 allows users to log all their meals, whether it’s breakfast, lunch, dinner, or snacks. The app offers the flexibility to create personalized meals or use pre-designed options. Simply input your food items, and the app will record their nutritional values. You can then analyze your nutrition intake daily, weekly, or monthly. This feature enables you to maintain a balanced diet and keep track of your nutritional goals effortlessly.
//               </p>
//             </FeatureText>
//           </FeatureItem>

//           <FeatureItem>
//             <FeatureIcon>🏃‍♂️</FeatureIcon>
//             <FeatureText>
//               <h4>Comprehensive Health Monitoring</h4>
//               <p>
//                 Stay on top of your health with features designed to track key metrics:
//                 <br /> 
//                 <strong>Weight Tracking:</strong> Keep a record of your weight changes and trends over time.
//                 <br /> 
//                 <strong>Water Intake Monitoring:</strong> Stay hydrated by logging your daily water consumption.
//                 <br /> 
//                 <strong>Sleep Analysis:</strong> Log your sleep patterns and analyze their impact on your overall health.
//                 <br /> 
//                 <strong>24-Hour Activity Tracking:</strong> Monitor your physical activity throughout the day.
//               </p>
//             </FeatureText>
//           </FeatureItem>

//           <FeatureItem>
//             <FeatureIcon>📱</FeatureIcon>
//             <FeatureText>
//               <h4>Smartwatch Integration</h4>
//               <p>
//                 The app seamlessly integrates with your smartwatch, enabling real-time tracking of your health and fitness data. This connectivity ensures that all your health metrics are consolidated in one place, making it easier to track your progress.
//               </p>
//             </FeatureText>
//           </FeatureItem>

//           <FeatureItem>
//             <FeatureIcon>🧮</FeatureIcon>
//             <FeatureText>
//               <h4>Health Calculators</h4>
//               <p>
//                 Nutrify India Now 2.0 includes powerful tools such as:
//                 <br />
//                 <strong>BMI (Body Mass Index) Calculator:</strong> Determine your weight category and understand where you stand.
//                 <br />
//                 <strong>BMR (Basal Metabolic Rate) Calculator:</strong> Estimate your daily calorie needs based on your activity level and metabolism.
//               </p>
//             </FeatureText>
//           </FeatureItem>

//           <FeatureItem>
//             <FeatureIcon>📚</FeatureIcon>
//             <FeatureText>
//               <h4>Access NIN Publications</h4>
//               <p>
//                 Explore and purchase health and nutrition publications directly through the app. Gain access to valuable resources created by the experts at ICMR-NIN, covering various aspects of health and wellness.
//               </p>
//             </FeatureText>
//           </FeatureItem>
//         </FeaturesSection>

//         {/* Download Section */}
//        <DownloadSection>
//   <DownloadHeading>Download Nutrify India Now 2.0</DownloadHeading>
//   <DownloadButton
//     href="https://play.google.com/store/search?q=nutrify+india+now+2.0&c=apps&hl=en"
//     target="_blank"
//     rel="noopener noreferrer"
//   >
//     Download Now from Google Play
//   </DownloadButton>
// </DownloadSection>


// <section style={{ textAlign: 'center', marginTop: '20px' }}>
//   <h2 style={{ fontSize: '1.6rem', color: '#324e6a' }}>
//     Take control of your health today with Nutrify India Now 2.0!
//   </h2>
// </section>
//  <Footer />
//       </PageWrapper>
     
//     </>
//   );
// };

// export default VisitorPage;

import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Footer from "../footer/Footer"; 

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    font-family: 'Arial', sans-serif;
    color: #324e6a;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #E5EFF3, #D0E0E5); /* Soft gradient for a professional look */
  }
`;

// Page Wrapper with solid background color
const PageWrapper = styled.div`
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  color: #324e6a;
  background-color: #F4F9FB; /* Light background to keep the page clean and professional */
`;

const HeaderSection = styled.section`
  text-align: center;
  color: black;
  margin-bottom: 40px;
`;

const Heading = styled.h1`
  background-color: #324e6a; /* Background color */
  color: white; /* Text color */
`;

const SubHeading = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-weight: 300;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 30px;
  padding: 0 10px;
`;

const FeaturesSection = styled.section`
  margin-bottom: 50px;
`;

const FeatureHeading = styled.h3`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 600;
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const FeatureIcon = styled.div`
  background-color: #3b9f6b;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  margin-right: 20px;
`;

const FeatureText = styled.div`
  flex: 1;
`;

const DownloadSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Space between the heading and the button */
  margin-top: 40px;
  padding: 20px;
`;

const DownloadHeading = styled.h3`
  font-size: 2rem;
  margin: 0;
`;

const DownloadButton = styled.a`
  text-decoration: none;
  background-color: #3b9f6b;
  padding: 15px 40px;
  font-size: 1.5rem;
  border-radius: 5px;
  color: white;
  text-align: center;

  &:hover {
    background-color: #2f7b56;
  }
`;

const VisitorPage = () => {
  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        {/* Header Section */}
        <HeaderSection>
          <Heading>Welcome to Nutrify India Now 2.0</Heading>
        </HeaderSection>
        <SubHeading>Your Comprehensive Health and Nutrition Companion</SubHeading>
        <Paragraph>
          Nutrify India Now 2.0, is your go-to app for managing health and nutrition. From meal tracking to health monitoring, we provide tools to make your health journey easier.
        </Paragraph>

        {/* Features Section */}
        <FeaturesSection>
          <FeatureHeading>Key Features</FeatureHeading>

          <FeatureItem>
            <FeatureIcon>🍽️</FeatureIcon>
            <FeatureText>
              <h4>Track and Record Your Meals</h4>
              <p>
                Nutrify India Now 2.0 allows users to log all their meals, whether it’s breakfast, lunch, dinner, or snacks. The app offers the flexibility to create personalized meals or use pre-designed options. Simply input your food items, and the app will record their nutritional values. You can then analyze your nutrition intake daily, weekly, or monthly. This feature enables you to maintain a balanced diet and keep track of your nutritional goals effortlessly.
              </p>
            </FeatureText>
          </FeatureItem>

          <FeatureItem>
            <FeatureIcon>🏃‍♂️</FeatureIcon>
            <FeatureText>
              <h4>Comprehensive Health Monitoring</h4>
              <p>
                Stay on top of your health with features designed to track key metrics:
                <br /> 
                <strong>Weight Tracking:</strong> Keep a record of your weight changes and trends over time.
                <br /> 
                <strong>Water Intake Monitoring:</strong> Stay hydrated by logging your daily water consumption.
                <br /> 
                <strong>Sleep Analysis:</strong> Log your sleep patterns and analyze their impact on your overall health.
                <br /> 
                <strong>24-Hour Activity Tracking:</strong> Monitor your physical activity throughout the day.
              </p>
            </FeatureText>
          </FeatureItem>

          <FeatureItem>
            <FeatureIcon>📱</FeatureIcon>
            <FeatureText>
              <h4>Smartwatch Integration</h4>
              <p>
                The app seamlessly integrates with your smartwatch, enabling real-time tracking of your health and fitness data. This connectivity ensures that all your health metrics are consolidated in one place, making it easier to track your progress.
              </p>
            </FeatureText>
          </FeatureItem>

          <FeatureItem>
            <FeatureIcon>🧮</FeatureIcon>
            <FeatureText>
              <h4>Health Calculators</h4>
              <p>
                Nutrify India Now 2.0 includes powerful tools such as:
                <br />
                <strong>BMI (Body Mass Index) Calculator:</strong> Determine your weight category and understand where you stand.
                <br />
                <strong>BMR (Basal Metabolic Rate) Calculator:</strong> Estimate your daily calorie needs based on your activity level and metabolism.
              </p>
            </FeatureText>
          </FeatureItem>

          <FeatureItem>
            <FeatureIcon>📚</FeatureIcon>
            <FeatureText>
              <h4>Access NIN Publications</h4>
              <p>
                Explore and purchase health and nutrition publications directly through the app. Gain access to valuable resources created by the experts at ICMR-NIN, covering various aspects of health and wellness.
              </p>
            </FeatureText>
          </FeatureItem>
        </FeaturesSection>

        {/* Download Section */}
        <DownloadSection>
          <DownloadHeading>Download Nutrify India Now 2.0</DownloadHeading>
          <DownloadButton
            href="https://play.google.com/store/search?q=nutrify+india+now+2.0&c=apps&hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Now from Google Play
          </DownloadButton>
        </DownloadSection>

        <section style={{ textAlign: 'center', marginTop: '20px' }}>
          <h2 style={{ fontSize: '1.6rem', color: '#324e6a' }}>
            Take control of your health today with Nutrify India Now 2.0!
          </h2>
        </section>

        <Footer />
      </PageWrapper>
    </>
  );
};

export default VisitorPage;
