import { Height } from "@mui/icons-material";
import React from "react";

const Footer = () => {
  const footerStyle = {
    backgroundColor: "#34495e",
    color: "#fff",
    textAlign: "center",
    padding: "20px 0", // Increased the padding to make the footer taller
    // position: "fixed",
    bottom: "0",
    width: "100%",
    fontSize: "14px",
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
  };

  return (
    <footer style={footerStyle}>
      © 2024 National Institute of Nutrition. All rights reserved.
    </footer>
  );
};

export default Footer;

