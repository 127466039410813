// // import React from "react";
// // import styled from "styled-components";
// // import { FaSignOutAlt, FaEdit } from "react-icons/fa";

// // const HeaderContainer = styled.div`
// //   display: flex;
// //   align-items: center;
// //   justify-content: space-between;
// //   background-color: #f5f5f5;
// //   padding: 10px 20px;
// //   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
// // `;

// // const LogoContainer = styled.div`
// //   display: flex;
// //   align-items: center;

// //   img {
// //     height: 50px;
// //   }
// // `;

// // const ActionsContainer = styled.div`
// //   display: flex;
// //   align-items: center;
// //   gap: 20px;

// //   .icon {
// //     display: flex;
// //     align-items: center;
// //     gap: 5px;
// //     cursor: pointer;
// //     color: #32506a;
// //     font-size: 18px;
// //     font-weight: bold;
// //     transition: all 0.3s ease;

// //     &:hover {
// //       color: #187bcd;
// //     }
// //   }
// // `;

// // const Header = ({ onUpdateStock, onLogout }) => {
// //   return (
// //     <HeaderContainer>
// //       <LogoContainer>
// //         <img
// //           src="./assets/NIN 2.0 Logo 12 June 2024.png"
// //           alt="NIN Logo"
// //           title="NIN Logo"
// //         />
// //       </LogoContainer>
// //       <ActionsContainer>
// //         <div className="icon" onClick={onUpdateStock}>
// //           <FaEdit />
// //           Update Stock
// //         </div>
// //         <div className="icon" onClick={onLogout}>
// //           <FaSignOutAlt />
// //           {/* Log Out */}
// //         </div>
// //       </ActionsContainer>
// //     </HeaderContainer>
// //   );
// // };

// // export default Header;
// import React from "react";
// import { useNavigate } from "react-router-dom";  // Import useNavigate hook
// import styled from "styled-components";
// import { FaSignOutAlt, FaEdit } from "react-icons/fa";

// const HeaderContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   background-color: #f5f5f5;
//   padding: 10px 20px;
//   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
// `;

// const LogoContainer = styled.div`
//   display: flex;
//   align-items: center;

//   img {
//     height: 50px;
//   }
// `;

// const ActionsContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 20px;

//   .icon {
//     display: flex;
//     align-items: center;
//     gap: 5px;
//     cursor: pointer;
//     color: #32506a;
//     font-size: 18px;
//     font-weight: bold;
//     transition: all 0.3s ease;

//     &:hover {
//       color: #187bcd;
//     }
//   }
// `;

// const Header = ({ onLogout }) => {
//   // Initialize useNavigate hook
//   const navigate = useNavigate();

//   // Handle click to navigate to the Update Stock page
//   const handleUpdateStockClick = () => {
//     navigate("/book");  // Navigates to /stock route
//   };

//   return (
//     <HeaderContainer>
//       <LogoContainer>
//         <img
//           src="./assets/NIN 2.0 Logo 12 June 2024.png"
//           alt="NIN Logo"
//           title="NIN Logo"
//         />
//       </LogoContainer>
//       <ActionsContainer>
//         {/* Update Stock Button with click event */}
//         <div className="icon" onClick={handleUpdateStockClick}>
//           <FaEdit />
//           Update Stock
//         </div>
//         <div className="icon" onClick={onLogout}>
//           <FaSignOutAlt />
//           {/* Log Out */}
//         </div>
//       </ActionsContainer>
//     </HeaderContainer>


//   );
// };

// export default Header;
import React from "react";
import { useNavigate } from "react-router-dom";  // Import useNavigate hook
import styled from "styled-components";
import { FaSignOutAlt, FaEdit } from "react-icons/fa";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 10px 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 50px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  .icon {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: #32506a;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.3s ease;

    &:hover {
      color: #187bcd;
    }
  }
`;

const Header = ({ onLogout }) => {
  // Initialize useNavigate hook
  const navigate = useNavigate();

  // Handle click to navigate to the Update Stock page
  const handleUpdateStockClick = () => {
    navigate("/book");  // Navigates to /book route
  };

  // Handle logout and redirection to homepage
  const handleLogout = () => {
    // Remove the JWT token from localStorage (or sessionStorage)
    localStorage.removeItem("jwtToken");  // Adjust the key based on where you store your token

    // Optionally, call the onLogout function if passed as a prop
    if (onLogout) onLogout();

    // Redirect to the homepage
    navigate("/");
  };

  return (
    <HeaderContainer>
      <LogoContainer>
        <img
          src="./assets/NIN 2.0 Logo 12 June 2024.png"
          alt="NIN Logo"
          title="NIN Logo"
        />
      </LogoContainer>
      <ActionsContainer>
        {/* Update Stock Button with click event */}
        <div className="icon" onClick={handleUpdateStockClick}>
          <FaEdit />
          Update Stock
        </div>
        <div className="icon" onClick={handleLogout}>
          <FaSignOutAlt />
          {/* Log Out */}
        </div>
      </ActionsContainer>
    </HeaderContainer>
  );
};

export default Header;
