// apiConfig.js

//  const BASE_URL = 'http://68.183.89.215:7073'; // Modify this according to your server configuration
// const BASE_URL = 'http://localhost:7073'; // Modify this according to your server configuration
const BASE_URL = 'https://apis.nutrifyindianow.in';
export default BASE_URL;


//=================jwt token================
// const getJwtToken = () => {
//     const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
//     return tokenCookie ? tokenCookie.split('=')[1] : null;
// };

// const getJwtToken = () => {
//     return localStorage.getItem('jwtToken');
//   };

export { BASE_URL };