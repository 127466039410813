// // // import React from 'react';
// // // import './ContactUs.css';

// // // const ContactUs = () => {
// // //   return (
// // //     <div className="contact-container">
// // //       <div className="contact-info">
// // //         <h2>Contact Us</h2>
// // //         <p>Our mailing address is:</p>
// // //         <p>152A Charlotte Street, Peterborough ON</p>
// // //         <p>Phone: 705-742-3221</p>
// // //         <div className="social-icons">
// // //           <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
// // //             <i className="fab fa-facebook-f"></i>
// // //           </a>
// // //           <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
// // //             <i className="fab fa-twitter"></i>
// // //           </a>
// // //           <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
// // //             <i className="fab fa-instagram"></i>
// // //           </a>
// // //           <a href="https://plus.google.com" target="_blank" rel="noopener noreferrer">
// // //             <i className="fab fa-google-plus-g"></i>
// // //           </a>
// // //         </div>
// // //       </div>
// // //       <div className="contact-form">
// // //         <p>Great vision without great people is irrelevant. Let's work together.</p>
// // //         <form>
// // //           <input type="text" placeholder="Enter your Name" required />
// // //           <input type="email" placeholder="Enter a valid email address" required />
// // //           <textarea placeholder="Enter your message" required></textarea>
// // //           <button type="submit">Submit</button>
// // //         </form>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default ContactUs;
// // import React, { useState } from 'react';
// // import './ContactUs.css';

// // const ContactUs = () => {
// //   const [formData, setFormData] = useState({
// //     name: '',
// //     number: '',
// //     email: '',
// //     queries: '',
// //     requestType: 'Contact',
// //   });

// //   const handleChange = (e) => {
// //     setFormData({
// //       ...formData,
// //       [e.target.name]: e.target.value,
// //     });
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     const url = 'http://68.183.89.215:7073/api/contact-us/upload';
    
// //     const formDataToSend = new FormData();
// //     Object.keys(formData).forEach(key => {
// //       formDataToSend.append(key, formData[key]);
// //     });

// //     try {
// //       const response = await fetch(url, {
// //         method: 'POST',
// //         body: formDataToSend,
// //       });

// //       if (!response.ok) {
// //         throw new Error('Network response was not ok');
// //       }

// //       const result = await response.json();
// //       console.log('Success:', result);
// //       alert('Form submitted successfully!');
// //     } catch (error) {
// //       console.error('Error:', error);
// //       alert('Form submission failed!');
// //     }
// //   };

// //   return (
// //     <div style="
// //         margin: 0; /* Remove default margin */
// //         font-family: Arial, sans-serif; /* Set a default font for the page */
// //         display: flex; /* Use Flexbox for centering */
// //         justify-content: center; /* Center horizontally */
// //         align-items: center; /* Center vertically */
// //         height: 100vh; /* Full viewport height */
// //         background-color: #add8e6; /* Light blue background color */
// //     ">
// //        <h3 className="query-heading">Drop Us a Message </h3>
// //     <div className="contact-container">
     
// //       <div className="contact-info">
// //         <h2>Contact Us</h2>
// //         {/* <p>Our mailing address is:</p> */}
// //         {/* <p>ICMR-National Institute of Nutrition Jamai-Osmania PO,Hyderabad-500 007, India </p>
        
// //         <p>Phone: +914027197200
// //         </p>
// //         <p>Email: nutrifyindianow.nin@gmail.com
// //         </p>
// //        */}
// // <p><span style={{ fontWeight: 'bold', color: 'black' }}>ICMR</span>-National Institute of Nutrition Jamai-Osmania PO,Hyderabad-500 007, India</p>

// // <p><span style={{ fontWeight: 'bold', color: 'black' }}>Phone:</span> +914027197200</p>
// // <p><span style={{ fontWeight: 'bold', color: 'black' }}>Email:</span> nutrifyindianow.nin@gmail.com</p>


// //       </div>
// //       <div className="contact-form">
        
// //         <form onSubmit={handleSubmit}>
// //           <input
// //             type="text"
// //             name="name"
// //             placeholder="Enter your Name"
// //             value={formData.name}
// //             onChange={handleChange}
// //             required
// //           />
// //           <input
// //             type="text"
// //             name="number"
// //             placeholder="Enter your Phone Number"
// //             value={formData.number}
// //             onChange={handleChange}
// //             required
// //           />
// //           <input
// //             type="email"
// //             name="email"
// //             placeholder="Enter a valid email address"
// //             value={formData.email}
// //             onChange={handleChange}
// //             required
// //           />
// //           <textarea
// //             name="queries"
// //             placeholder="Enter your message"
// //             value={formData.queries}
// //             onChange={handleChange}
// //             required
// //           ></textarea>
// //           <button type="submit">Submit</button>
// //         </form>
// //       </div>
// //     </div>
// //     </div>
// //   );
// // };

// // export default ContactUs;
// import React, { useState } from 'react';
// import './ContactUs.css';

// const ContactUs = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     number: '',
//     email: '',
//     queries: '',
//     requestType: 'Contact',
//   });

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const url = 'http://68.183.89.215:7073/api/contact-us/upload';
    
//     const formDataToSend = new FormData();
//     Object.keys(formData).forEach(key => {
//       formDataToSend.append(key, formData[key]);
//     });

//     try {
//       const response = await fetch(url, {
//         method: 'POST',
//         body: formDataToSend,
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const result = await response.json();
//       console.log('Success:', result);
//       alert('Form submitted successfully!');
//     } catch (error) {
//       console.error('Error:', error);
//       alert('Form submission failed!');
//     }
//   };

//   return (
    // <div
    //   style={{
    //     margin: 0, /* Remove default margin */
    //     fontFamily: 'Arial, sans-serif', /* Set a default font for the page */
    //     display: 'flex', /* Use Flexbox for centering */
    //     justifyContent: 'center', /* Center horizontally */
    //     alignItems: 'center', /* Center vertically */
    //     height: '100vh', /* Full viewport height */
    //     backgroundColor: '#add8e6', /* Light blue background color */
    //   }}
    // >
//       <h3 className="query-heading">Drop Us a Message</h3>
//       <div className="contact-container">
//         <div className="contact-info">
//           <h2>Contact Us</h2>
//           <p>
//             <span style={{ fontWeight: 'bold', color: 'black' }}>ICMR</span> - National Institute of Nutrition Jamai-Osmania PO, Hyderabad-500 007, India
//           </p>
//           <p>
//             <span style={{ fontWeight: 'bold', color: 'black' }}>Phone:</span> +914027197200
//           </p>
//           <p>
//             <span style={{ fontWeight: 'bold', color: 'black' }}>Email:</span> nutrifyindianow.nin@gmail.com
//           </p>
//         </div>
//         <div className="contact-form">
//           <form onSubmit={handleSubmit}>
//             <input
//               type="text"
//               name="name"
//               placeholder="Enter your Name"
//               value={formData.name}
//               onChange={handleChange}
//               required
//             />
//             <input
//               type="text"
//               name="number"
//               placeholder="Enter your Phone Number"
//               value={formData.number}
//               onChange={handleChange}
//               required
//             />
//             <input
//               type="email"
//               name="email"
//               placeholder="Enter a valid email address"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//             <textarea
//               name="queries"
//               placeholder="Enter your message"
//               value={formData.queries}
//               onChange={handleChange}
//               required
//             ></textarea>
//             <button type="submit">Submit</button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ContactUs;
import React, { useState } from 'react';
import './ContactUs.css';
// import Footer from "../footer/Footer"; 

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    queries: '',
    requestType: 'Contact',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = 'http://68.183.89.215:7073/api/contact-us/upload';
    
    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Success:', result);
      alert('Form submitted successfully!');
    } catch (error) {
      console.error('Error:', error);
      alert('Form submission failed!');
    }
  };

  return (
    <div>
      <h3 className="query-heading">Drop Us a Message</h3>
      <div className="contact-container">
        <div className="contact-info">
          <h2>Contact Us</h2>
          <p>
            <span style={{ fontWeight: 'bold', color: 'black' }}>ICMR</span> - National Institute of Nutrition Jamai-Osmania PO, Hyderabad-500 007, India
          </p>
          <p>
            <span style={{ fontWeight: 'bold', color: 'black' }}>Phone:</span> +914027197200
          </p>
          <p>
            <span style={{ fontWeight: 'bold', color: 'black' }}>Email:</span> nutrifyindianow.nin@gmail.com
          </p>
        </div>
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Enter your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="number"
              placeholder="Enter your Phone Number"
              value={formData.number}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Enter a valid email address"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <textarea
              name="queries"
              placeholder="Enter your message"
              value={formData.queries}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
       
    </div>
  
  );
};

export default ContactUs;
