
// // // // import React, { useState, useEffect } from 'react';
// // // // import BASE_URL from '../../components/config/apiConfig';
// // // // import { useNavigate } from 'react-router-dom';
// // // // import { FaEnvelope, FaLock } from 'react-icons/fa';

// // // // const Login = () => {
// // // //   const [email, setEmail] = useState('');
// // // //   const [password, setPassword] = useState('');
// // // //   const [rememberMe, setRememberMe] = useState(false);
// // // //   const history = useNavigate();

// // // //   useEffect(() => {
// // // //     const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
// // // //     setRememberMe(rememberMeValue);
// // // //   }, []);

// // // //   const getJwtToken = () => {
// // // //     const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
// // // //     return tokenCookie ? tokenCookie.split('=')[1] : null;
// // // //   };

// // // //   const handleEmailChange = (event) => {
// // // //     setEmail(event.target.value);
// // // //   };

// // // //   const handlePasswordChange = (event) => {
// // // //     setPassword(event.target.value);
// // // //   };

// // // //   const handleRememberMeChange = (event) => {
// // // //     setRememberMe(event.target.checked);
// // // //   };

// // // //   const handleSignIn = async () => {
// // // //     try {
// // // //       const response = await fetch(`${BASE_URL}/dashboard/user-login`, {
// // // //         method: 'POST',
// // // //         headers: {
// // // //           'Content-Type': 'application/json',
// // // //         },
// // // //         body: JSON.stringify({
// // // //           email: email,
// // // //           password: password,
// // // //         }),
// // // //       });
  
// // // //       if (!response.ok) {
// // // //         throw new Error('Invalid email or password');
// // // //       }
  
// // // //       const data = await response.json();
// // // //       console.log('Response Data:', data);
  
// // // //       // Store the JWT token in localStorage
// // // //       localStorage.setItem('jwtToken', data.jwt);

      
  
// // // //       const jwtToken = getJwtToken();
// // // //       console.log('JWT Token:', jwtToken);
  
// // // //       localStorage.setItem('rememberMe', rememberMe.toString());
  
// // // //       history('/home');
// // // //     } catch (error) {
// // // //       console.error('Error:', error.message);
// // // //     }
// // // //   };
// // // //   const styles = {
// // // //     container: {
// // // //       display: 'flex',
// // // //       flexDirection: 'column',
// // // //       position: 'relative',
// // // //       minHeight: '100vh',
// // // //     },
// // // //     navbar: {
// // // //       marginTop: 20,
// // // //       backgroundColor: '#f8f9fa',
// // // //       padding: '15px 0',
// // // //       position: 'fixed',
// // // //       width: '100%',
// // // //       zIndex: 1000,
// // // //       display: 'flex',
// // // //       justifyContent: 'space-between', // Align items to the left and right
// // // //     },
// // // //     iosDownloadContainer: {
// // // //       backgroundColor: '#f8f9fa',
// // // //       padding: '5px 15px',
// // // //       borderRadius: '20px', // Adjust the border radius as needed
// // // //       marginRight: '20px',
// // // //        // Adjust the margin to create space between the links
// // // //     },
// // // //     backgroundStyle: {
// // // //         backgroundImage: `url(${process.env.PUBLIC_URL}/assets/curved6.jpg)`,

// // // //       backgroundSize: 'cover',
// // // //       backgroundPosition: 'center center',
// // // //       backgroundRepeat: 'no-repeat',
// // // //       height: '100%',
// // // //       width: '50vw',
// // // //       position: 'absolute',
// // // //       right: 0,
// // // //       top: 0,
// // // //       zIndex: 0,
// // // //     },
// // // //     formContainer: {
        
// // // //       width: '50%',
// // // //       height: '90%',
// // // //       margin: 'auto',
// // // //       backgroundColor: '#ffffff',
// // // //       padding: '30px',
// // // //       marginTop: '0px',
// // // //       borderRadius: '0px',
// // // //       boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
// // // //       position: 'absolute',
// // // //       right: 650,
// // // //       top: 0,
// // // //       zIndex: 0,
// // // //     },
// // // //     formHeader: {
// // // //       textAlign: 'left',
// // // //       marginBottom: '0px',
// // // //       marginTop: '90px',
// // // //       color: 'darkblue',
// // // //     },
// // // //     formLabel: {
// // // //       fontWeight: 'bold',
// // // //     },
// // // //     formInput: {
// // // //       width: '100%',
// // // //       padding: '10px',
// // // //       marginBottom: '15px',
// // // //       borderRadius: '4px',
// // // //       border: '1px solid #ccc',
// // // //     },
// // // //     formCheckbox: {
// // // //       marginBottom: '20px',
// // // //     },
// // // //     formButton: {
// // // //       backgroundColor: '#17a2b8',
// // // //       color: '#fff',
// // // //       padding: '10px',
// // // //       borderRadius: '4px',
// // // //       cursor: 'pointer',
// // // //       width: '50%', // Adjust the width as needed
// // // //       border: 'none',
// // // //     },
// // // //     footer: {
// // // //       backgroundColor: '#f8f9fa',
// // // //       padding: '20px 0',
// // // //       marginTop: 'auto',
// // // //       position: 'relative',
// // // //       zIndex: 0,
// // // //     },
// // // //     // Media Query for smaller screens
// // // //     '@media (max-width: 768px)': {
// // // //       formContainer: {
// // // //         width: '80%', // Adjusted width for smaller screens
// // // //         margin: 'auto',
// // // //         marginTop: '50px',
// // // //       },
// // // //     },
// // // //   };

// // // //   return (
// // // //     <div style={styles.container}>
// // // //   <div className="navbar navbar-expand-lg navbar-light" style={{ ...styles.navbar, borderRadius: '50px', height: '30px' }}>
// // // //   <h3 className="navbar-brand" href="#" style={{ color: 'black' , marginLeft: '20px', textDecoration: 'none'}}>Nutrify India Now 2.0</h3>
// // // //   <div className="navbar-nav ml-auto" style={{ display: 'flex', flexDirection: 'row' }}>
// // // //     <div style={{ ...styles.iosDownloadContainer, backgroundColor: '#001f3f' }}>
// // // //       <a className="nav-item nav-link" href="#" style={{ color: '#fff',textDecoration: 'none' }}>Download for Android</a>
// // // //     </div>
// // // //     <div style={{ ...styles.iosDownloadContainer, backgroundColor: '#001f3f' }}>
// // // //       <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>Download for IOS</a>
// // // //     </div>
// // // //   </div>
// // // // </div>


// // // //       <div style={styles.backgroundStyle} />
// // // //       <div style={styles.formContainer}>
// // // //         <div style={styles.formHeader}>
// // // //           <h1 className="font-weight-bolder text-info text-gradient">Welcome back</h1>
// // // //         </div>
// // // //         <p style={{ textAlign: 'left', marginTop: '20px', marginBottom: '20px' }}>
// // // //           Enter your email and password to sign in
// // // //         </p>
// // // //         <form role="form">
// // // //         <div>
// // // //   <label style={styles.formLabel}></label>
// // // //   <div style={{ display: 'flex', alignItems: 'center' }}>
// // // //     <FaEnvelope style={{ marginRight: '10px' }} />
// // // //     <input
// // // //       type="email"
// // // //       style={{ ...styles.formInput, width: '45%', marginBottom: '30px' }}
// // // //       placeholder="Email"
// // // //       value={email}
// // // //       onChange={handleEmailChange}
// // // //     />
// // // //   </div>
// // // // </div>
// // // // <div>
// // // //   <label style={styles.formLabel}></label>
// // // //   <div style={{ display: 'flex', alignItems: 'center',marginBottom: '30px'}}>
// // // //     <FaLock style={{ marginRight: '10px' }} />
// // // //     <input
// // // //       type="password"
// // // //       style={{ ...styles.formInput, width: '45%', marginBottom: '30px' }}
// // // //       placeholder="Password"
// // // //       value={password}
// // // //       onChange={handlePasswordChange}
// // // //     />
// // // //   </div>
// // // // </div>

// // // //           <div className="form-check form-switch" style={styles.formCheckbox}>
// // // //             <input
// // // //               className="form-check-input"
// // // //               type="checkbox"
// // // //               checked={rememberMe}
// // // //               onChange={handleRememberMeChange}
// // // //             />
// // // //             <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
// // // //           </div>
// // // //           <div className="text-center">
// // // //   <button
// // // //     type="button"
// // // //     className="btn bg-gradient-info w-100 mt-2 mb-0"
// // // //     onClick={handleSignIn} 
// // // //     style={styles.formButton}
// // // //   >
// // // //     Sign in
// // // //   </button>
// // // // </div>
// // // //         </form>
// // // //       </div>
// // // //       <footer className="footer py-5" style={{ ...styles.footer, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
// // // //   <div className="container">
// // // //     <div className="row">
// // // //     <div className="col-12 text-center" style={{ display: 'flex' }}>
// // // //   <div className="mb-4" style={{ marginLeft: '70px' }}>
// // // //     <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Terms & Conditions</a>
// // // //   </div>
// // // //   <div className="mb-4" style={{ marginLeft: '70px' }}>
// // // //     <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>About Us</a>
// // // //   </div>
// // // //   <div className="mb-4" style={{ marginLeft: '70px' }}>
// // // //     <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Privacy Policy</a>
// // // //   </div>
// // // // </div>

// // // //     </div>
// // // //     <div className="row"  style={{  alignItems: 'center', marginLeft: '180px' }}>
// // // //       <div className="col-12 text-center mt-1">
// // // //         <p className="text-secondary mb-0">
// // // //           &copy; {new Date().getFullYear()} National Institute Of Nutrition
// // // //         </p>
// // // //       </div>
// // // //     </div>
// // // //   </div>
// // // // </footer>






// // // //     </div>
// // // //   );
// // // // }

// // // // export default Login;














// // // import React, { useState, useEffect } from 'react';
// // // import BASE_URL from '../../components/config/apiConfig';
// // // import { useNavigate } from 'react-router-dom';
// // // import { FaEnvelope, FaLock } from 'react-icons/fa';

// // // const Login = () => {
// // //   const [email, setEmail] = useState('');
// // //   const [password, setPassword] = useState('');
// // //   const [rememberMe, setRememberMe] = useState(false);
// // //   const history = useNavigate();

// // //   useEffect(() => {
// // //     const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
// // //     setRememberMe(rememberMeValue);
// // //   }, []);

// // //   const getJwtToken = () => {
// // //     const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
// // //     return tokenCookie ? tokenCookie.split('=')[1] : null;
// // //   };

// // //   const handleEmailChange = (event) => {
// // //     setEmail(event.target.value);
// // //   };

// // //   const handlePasswordChange = (event) => {
// // //     setPassword(event.target.value);
// // //   };

// // //   const handleRememberMeChange = (event) => {
// // //     setRememberMe(event.target.checked);
// // //   };

// // //   const handleSignIn = async () => {
// // //     try {
// // //       const response = await fetch(`${BASE_URL}/dashboard/user-login`, {
// // //         method: 'POST',
// // //         headers: {
// // //           'Content-Type': 'application/json',
// // //         },
// // //         body: JSON.stringify({
// // //           email: email,
// // //           password: password,
// // //         }),
// // //       });

// // //       if (!response.ok) {
// // //         throw new Error('Invalid email or password');
// // //       }

// // //       const data = await response.json();
// // //       console.log('Response Data:', data);

// // //       // Store the JWT token in localStorage
// // //       localStorage.setItem('jwtToken', data.jwt);

// // //       const jwtToken = getJwtToken();
// // //       console.log('JWT Token:', jwtToken);

// // //       localStorage.setItem('rememberMe', rememberMe.toString());

// // //       history('/home');
// // //     } catch (error) {
// // //       console.error('Error:', error.message);
// // //     }
// // //   };

// // //   const styles = {
// // //     container: {
// // //       display: 'flex',
// // //       flexDirection: 'column',
// // //       minHeight: '100vh',
// // //       position: 'relative',
// // //     },
// // //     header: {
// // //       //backgroundColor: '#f8f9fa',
// // //       padding: '10px',
// // //       borderRadius: '10px',
// // //       marginBottom: '20px',
// // //       //boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
// // //       zIndex: 2, // Increase z-index to overlay other elements
// // //       position: 'relative', // Ensure z-index works properly
// // //       marginTop: '18px',
// // //     },
// // //     headerImage: {
// // //       position: 'absolute',
// // //       top: 0,
// // //       left: 0,
// // //       width: '100%',
// // //       height: '100%',
// // //       objectFit: 'cover',
// // //       zIndex: 1, // Place the image behind the header content
// // //     },
// // //     navbar: {
// // //       display: 'flex',
// // //       alignItems: 'center',
// // //       justifyContent: 'space-between',
// // //       borderRadius: '90px',
// // //     },
// // //     logo: {
// // //       color: 'black',
// // //       marginLeft: '20px',
// // //       textDecoration: 'none',
// // //       borderRadius: '90px',
// // //     },
// // //     iosDownloadContainer: {
// // //       padding: '5px 10px',
// // //       borderRadius: '90px',
// // //       marginLeft: '10px',
// // //       cursor: 'pointer',
// // //     },
// // //     formContainer: {
// // //       width: '45%',
// // //       marginLeft: '20px',
// // //       padding: '80px',
// // //       zIndex: 1,
// // //      // backgroundColor: '#ffffff',
// // //       //borderRadius: '10px',
// // //       //boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
// // //     },
// // //     formHeader: {
// // //       textAlign: 'left',
// // //       marginBottom: '0px',
// // //       marginTop: '0px',
// // //       color: 'darkblue',
// // //     },
// // //     formLabel: {
// // //       fontWeight: 'bold',
// // //     },
// // //     formInput: {
// // //       width: '100%',
// // //       padding: '10px',
// // //       marginBottom: '15px',
// // //       borderRadius: '4px',
// // //       border: '1px solid #ccc',
// // //     },
// // //     backgroundStyle: {
// // //       backgroundImage: `url(${process.env.PUBLIC_URL}/assets/curved6.jpg)`,
// // //       backgroundSize: 'cover',
// // //       backgroundPosition: 'right top',
// // //       backgroundRepeat: 'no-repeat',
// // //       height: '100vh',
// // //       position: 'absolute',
// // //       top: 0,
// // //       right: 0,
// // //       width: '50%',
// // //       zIndex: 0,
// // //       borderTopLeftRadius: '500px', // Slightly cut shape on the left side
// // //   borderBottomLeftRadius: '0px',
// // //       // Rounded border on the left side
// // //     },
// // //     formCheckbox: {
// // //       marginBottom: '20px',
// // //     },
// // //     formButton: {
// // //       backgroundColor: '#17a2b8',
// // //       color: '#fff',
// // //       padding: '10px',
// // //       borderRadius: '4px',
// // //       cursor: 'pointer',
// // //       width: '45%',
// // //       border: 'none',
// // //     },
// // //     footer: {
// // //       backgroundColor: '#f8f9fa',
// // //       padding: '20px 0',
// // //       marginTop: 'auto',
// // //       position: 'relative',
// // //       zIndex: 0,
// // //       display: 'flex',
// // //       flexDirection: 'column',
// // //       alignItems: 'center',
// // //     },
// // //     formIcon: {
// // //       marginRight: '10px',
// // //       marginBottom: '33px', // Add bottom margin to the icons
// // //     },
// // //     '@media (max-width: 768px)': {
// // //       formContainer: {
// // //         width: '100%',
// // //         padding: '15px',
// // //       },
// // //       formInput: {
// // //         width: '100%',
// // //       },
// // //       formButton: {
// // //         width: '100%',
// // //       },
// // //     },
// // //   };
  
  
// // //   return (
// // //     <div style={styles.container}>
// // //       <div style={styles.header}>
// // //         <div className="navbar navbar-expand-lg navbar-light" style={styles.navbar}>
// // //           <h3 className="navbar-brand" href="#" style={styles.logo}>Nutrify India Now 2.0</h3>
// // //           <div className="navbar-nav ml-auto" style={{ display: 'flex', flexDirection: 'row' }}>
// // //             <div style={{ ...styles.iosDownloadContainer, backgroundColor: '#001f3f' }}>
// // //               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>Download for Android</a>
// // //             </div>
// // //             <div style={{ ...styles.iosDownloadContainer, backgroundColor: '#001f3f' }}>
// // //               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>Download for iOS</a>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </div>
// // //       <div style={styles.formContainer}>
// // //         <div style={styles.formHeader}>
// // //           <h1 className="font-weight-bolder text-info text-gradient">Welcome back</h1>
// // //         </div>
// // //         <p style={{ textAlign: 'left', marginTop: '20px', marginBottom: '20px' }}>
// // //           Enter your email and password to sign in
// // //         </p>
// // //         <form role="form">
// // //           <div>
// // //             <label style={styles.formLabel}></label>
// // //             <div style={{ display: 'flex', alignItems: 'center' }}>
// // //               <FaEnvelope style={styles.formIcon} />
// // //               <input
// // //                 type="email"
// // //                 style={{ ...styles.formInput, width: '45%', marginBottom: '30px' }}
// // //                 placeholder="Email"
// // //                 value={email}
// // //                 onChange={handleEmailChange}
// // //               />
// // //             </div>
// // //           </div>
// // //           <div>
// // //             <label style={styles.formLabel}></label>
// // //             <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
// // //             <FaLock style={styles.formIcon} />
// // //               <input
// // //                 type="password"
// // //                 style={{ ...styles.formInput, width: '45%', marginBottom: '30px' }}
// // //                 placeholder="Password"
// // //                 value={password}
// // //                 onChange={handlePasswordChange}
// // //               />
// // //             </div>
// // //           </div>

// // //           <div className="form-check form-switch" style={styles.formCheckbox}>
// // //             <input
// // //               className="form-check-input"
// // //               type="checkbox"
// // //               checked={rememberMe}
// // //               onChange={handleRememberMeChange}
// // //             />
// // //             <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
// // //           </div>
// // //           <div className="text-center">
// // //             <button
// // //               type="button"
// // //               className="btn bg-gradient-info w-100 mt-2 mb-0"
// // //               onClick={handleSignIn}
// // //               style={styles.formButton}
// // //             >
// // //               Sign in
// // //             </button>
// // //           </div>
// // //         </form>
// // //       </div>
      
// // //       <div style={styles.backgroundStyle} />
// // //       <footer className="footer py-5" style={styles.footer}>
// // //         <div className="container">
// // //           <div className="row">
// // //             <div className="col-12 text-center" style={{ display: 'flex' }}>
// // //               <div className="mb-4" style={{ marginLeft: '70px' }}>
// // //                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Terms & Conditions</a>
// // //               </div>
// // //               <div className="mb-4" style={{ marginLeft: '70px' }}>
// // //                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>About Us</a>
// // //               </div>
// // //               <div className="mb-4" style={{ marginLeft: '70px' }}>
// // //                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Privacy Policy</a>
// // //               </div>
// // //             </div>
// // //           </div>
// // //           <div className="row"  style={{ alignItems: 'center', marginLeft: '180px' }}>
// // //             <div className="col-12 text-center mt-1">
// // //               <p className="text-secondary mb-0">
// // //                 &copy; {new Date().getFullYear()} National Institute Of Nutrition
// // //               </p>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </footer>
// // //     </div>
// // //   );
// // // }

// // // export default Login;









// // // // // import React, { useState, useEffect } from 'react';
// // // // // import BASE_URL from '../../components/config/apiConfig';
// // // // // import { useNavigate } from 'react-router-dom';
// // // // // import { FaEnvelope, FaLock } from 'react-icons/fa';

// // // // // const Login = () => {
// // // // //   const [email, setEmail] = useState('');
// // // // //   const [password, setPassword] = useState('');
// // // // //   const [rememberMe, setRememberMe] = useState(false);
// // // // //   const history = useNavigate();

// // // // //   useEffect(() => {
// // // // //     const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
// // // // //     setRememberMe(rememberMeValue);
// // // // //   }, []);

// // // // //   const getJwtToken = () => {
// // // // //     const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
// // // // //     return tokenCookie ? tokenCookie.split('=')[1] : null;
// // // // //   };

// // // // //   const handleEmailChange = (event) => {
// // // // //     setEmail(event.target.value);
// // // // //   };

// // // // //   const handlePasswordChange = (event) => {
// // // // //     setPassword(event.target.value);
// // // // //   };

// // // // //   const handleRememberMeChange = (event) => {
// // // // //     setRememberMe(event.target.checked);
// // // // //   };

// // // // //   const handleSignIn = async () => {
// // // // //     try {
// // // // //       const response = await fetch(`${BASE_URL}/dashboard/user-login`, {
// // // // //         method: 'POST',
// // // // //         headers: {
// // // // //           'Content-Type': 'application/json',
// // // // //         },
// // // // //         body: JSON.stringify({
// // // // //           email: email,
// // // // //           password: password,
// // // // //         }),
// // // // //       });

// // // // //       if (!response.ok) {
// // // // //         throw new Error('Invalid email or password');
// // // // //       }

// // // // //       const data = await response.json();
// // // // //       console.log('Response Data:', data);

// // // // //       // Store the JWT token in localStorage
// // // // //       localStorage.setItem('jwtToken', data.jwt);

// // // // //       const jwtToken = getJwtToken();
// // // // //       console.log('JWT Token:', jwtToken);

// // // // //       localStorage.setItem('rememberMe', rememberMe.toString());

// // // // //       history('/home');
// // // // //     } catch (error) {
// // // // //       console.error('Error:', error.message);
// // // // //     }
// // // // //   };

// // // // //   const styles = {
// // // // //     container: {
// // // // //       display: 'flex',
// // // // //       flexDirection: 'column',
// // // // //       minHeight: '100vh',
// // // // //       position: 'relative',
// // // // //     },
// // // // //     header: {
// // // // //       //backgroundColor: '#f8f9fa',
// // // // //       padding: '10px',
// // // // //       borderRadius: '10px',
// // // // //       marginBottom: '20px',
// // // // //       //boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
// // // // //       zIndex: 2, // Increase z-index to overlay other elements
// // // // //       position: 'relative', // Ensure z-index works properly
// // // // //       marginTop: '18px',
// // // // //     },
// // // // //     headerImage: {
// // // // //       position: 'absolute',
// // // // //       top: 0,
// // // // //       left: 0,
// // // // //       width: '100%',
// // // // //       height: '100%',
// // // // //       objectFit: 'cover',
// // // // //       zIndex: 1, // Place the image behind the header content
// // // // //     },
// // // // //     navbar: {
// // // // //       display: 'flex',
// // // // //       alignItems: 'center',
// // // // //       justifyContent: 'space-between',
// // // // //       borderRadius: '90px',
// // // // //     },
// // // // //     logo: {
// // // // //       color: 'black',
// // // // //       marginLeft: '20px',
// // // // //       textDecoration: 'none',
// // // // //       borderRadius: '90px',
// // // // //     },
// // // // //     iosDownloadContainer: {
// // // // //       padding: '5px 10px',
// // // // //       borderRadius: '90px',
// // // // //       marginLeft: '10px',
// // // // //       cursor: 'pointer',
// // // // //     },
// // // // //     formContainer: {
// // // // //       width: '45%',
// // // // //       marginLeft: '20px',
// // // // //       padding: '80px',
// // // // //       zIndex: 1,
// // // // //      // backgroundColor: '#ffffff',
// // // // //       //borderRadius: '10px',
// // // // //       //boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
// // // // //     },
// // // // //     formHeader: {
// // // // //       textAlign: 'left',
// // // // //       marginBottom: '0px',
// // // // //       marginTop: '0px',
// // // // //       color: 'darkblue',
// // // // //     },
// // // // //     formLabel: {
// // // // //       fontWeight: 'bold',
// // // // //     },
// // // // //     formInput: {
// // // // //       width: '100%',
// // // // //       padding: '10px',
// // // // //       marginBottom: '15px',
// // // // //       borderRadius: '4px',
// // // // //       border: '1px solid #ccc',
// // // // //     },
// // // // //     backgroundStyle: {
// // // // //       backgroundImage: `url(${process.env.PUBLIC_URL}/assets/curved6.jpg)`,
// // // // //       backgroundSize: 'cover',
// // // // //       backgroundPosition: 'right top',
// // // // //       backgroundRepeat: 'no-repeat',
// // // // //       height: '100vh',
// // // // //       position: 'absolute',
// // // // //       top: 0,
// // // // //       right: 0,
// // // // //       width: '50%',
// // // // //       zIndex: 0,
// // // // //       borderTopLeftRadius: '500px', // Slightly cut shape on the left side
// // // // //   borderBottomLeftRadius: '0px',
// // // // //       // Rounded border on the left side
// // // // //     },
// // // // //     formCheckbox: {
// // // // //       marginBottom: '20px',
// // // // //     },
// // // // //     formButton: {
// // // // //       backgroundColor: '#17a2b8',
// // // // //       color: '#fff',
// // // // //       padding: '10px',
// // // // //       borderRadius: '4px',
// // // // //       cursor: 'pointer',
// // // // //       width: '45%',
// // // // //       border: 'none',
// // // // //     },
// // // // //     footer: {
// // // // //       backgroundColor: '#f8f9fa',
// // // // //       padding: '20px 0',
// // // // //       marginTop: 'auto',
// // // // //       position: 'relative',
// // // // //       zIndex: 0,
// // // // //       display: 'flex',
// // // // //       flexDirection: 'column',
// // // // //       alignItems: 'center',
// // // // //     },
// // // // //     formIcon: {
// // // // //       marginRight: '10px',
// // // // //       marginBottom: '33px', // Add bottom margin to the icons
// // // // //     },
// // // // //     '@media (max-width: 768px)': {
// // // // //       formContainer: {
// // // // //         width: '100%',
// // // // //         padding: '15px',
// // // // //       },
// // // // //       formInput: {
// // // // //         width: '100%',
// // // // //       },
// // // // //       formButton: {
// // // // //         width: '100%',
// // // // //       },
// // // // //     },
// // // // //   };
  
  
// // // // //   return (
// // // // //     <div style={styles.container}>
// // // // //       <div style={styles.header}>
// // // // //         <div className="navbar navbar-expand-lg navbar-light" style={styles.navbar}>
// // // // //           <h3 className="navbar-brand" href="#" style={styles.logo}>Nutrify India Now 2.0</h3>
// // // // //           <div className="navbar-nav ml-auto" style={{ display: 'flex', flexDirection: 'row' }}>
// // // // //             <div style={{ ...styles.iosDownloadContainer, backgroundColor: '#001f3f' }}>
// // // // //               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>Download for Android</a>
// // // // //             </div>
// // // // //             <div style={{ ...styles.iosDownloadContainer, backgroundColor: '#001f3f' }}>
// // // // //               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>Download for iOS</a>
// // // // //             </div>
// // // // //           </div>
// // // // //         </div>
// // // // //       </div>
// // // // //       <div style={styles.formContainer}>
// // // // //         <div style={styles.formHeader}>
// // // // //           <h1 className="font-weight-bolder text-info text-gradient">Welcome back</h1>
// // // // //         </div>
// // // // //         <p style={{ textAlign: 'left', marginTop: '20px', marginBottom: '20px' }}>
// // // // //           Enter your email and password to sign in
// // // // //         </p>
// // // // //         <form role="form">
// // // // //           <div>
// // // // //             <label style={styles.formLabel}></label>
// // // // //             <div style={{ display: 'flex', alignItems: 'center' }}>
// // // // //               <FaEnvelope style={styles.formIcon} />
// // // // //               <input
// // // // //                 type="email"
// // // // //                 style={{ ...styles.formInput, width: '45%', marginBottom: '30px' }}
// // // // //                 placeholder="Email"
// // // // //                 value={email}
// // // // //                 onChange={handleEmailChange}
// // // // //               />
// // // // //             </div>
// // // // //           </div>
// // // // //           <div>
// // // // //             <label style={styles.formLabel}></label>
// // // // //             <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
// // // // //             <FaLock style={styles.formIcon} />
// // // // //               <input
// // // // //                 type="password"
// // // // //                 style={{ ...styles.formInput, width: '45%', marginBottom: '30px' }}
// // // // //                 placeholder="Password"
// // // // //                 value={password}
// // // // //                 onChange={handlePasswordChange}
// // // // //               />
// // // // //             </div>
// // // // //           </div>

// // // // //           <div className="form-check form-switch" style={styles.formCheckbox}>
// // // // //             <input
// // // // //               className="form-check-input"
// // // // //               type="checkbox"
// // // // //               checked={rememberMe}
// // // // //               onChange={handleRememberMeChange}
// // // // //             />
// // // // //             <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
// // // // //           </div>
// // // // //           <div className="text-center">
// // // // //             <button
// // // // //               type="button"
// // // // //               className="btn bg-gradient-info w-100 mt-2 mb-0"
// // // // //               onClick={handleSignIn}
// // // // //               style={styles.formButton}
// // // // //             >
// // // // //               Sign in
// // // // //             </button>
// // // // //           </div>
// // // // //         </form>
// // // // //       </div>
      
// // // // //       <div style={styles.backgroundStyle} />
// // // // //       <footer className="footer py-5" style={styles.footer}>
// // // // //         <div className="container">
// // // // //           <div className="row">
// // // // //             <div className="col-12 text-center" style={{ display: 'flex' }}>
// // // // //               <div className="mb-4" style={{ marginLeft: '70px' }}>
// // // // //                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Terms & Conditions</a>
// // // // //               </div>
// // // // //               <div className="mb-4" style={{ marginLeft: '70px' }}>
// // // // //                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>About Us</a>
// // // // //               </div>
// // // // //               <div className="mb-4" style={{ marginLeft: '70px' }}>
// // // // //                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Privacy Policy</a>
// // // // //               </div>
// // // // //             </div>
// // // // //           </div>
// // // // //           <div className="row"  style={{ alignItems: 'center', marginLeft: '180px' }}>
// // // // //             <div className="col-12 text-center mt-1">
// // // // //               <p className="text-secondary mb-0">
// // // // //                 &copy; {new Date().getFullYear()} National Institute Of Nutrition
// // // // //               </p>
// // // // //             </div>
// // // // //           </div>
// // // // //         </div>
// // // // //       </footer>
// // // // //     </div>
// // // // //   );
// // // // // }

// // // // // export default Login;

// // // // import React, { useState, useEffect } from 'react';
// // // // import BASE_URL from '../../components/config/apiConfig';
// // // // import { useNavigate } from 'react-router-dom';
// // // // import { FaEnvelope, FaLock } from 'react-icons/fa';

// // // // const Login = () => {
// // // //   const [email, setEmail] = useState(localStorage.getItem('savedEmail') || '');
// // // //   const [password, setPassword] = useState(localStorage.getItem('savedPassword') || '');
// // // //   const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
// // // //   const history = useNavigate();

// // // //   useEffect(() => {
// // // //     const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
// // // //     setRememberMe(rememberMeValue);
// // // //   }, []);

// // // //   const getJwtToken = () => {
// // // //     const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
// // // //     return tokenCookie ? tokenCookie.split('=')[1] : null;
// // // //   };

// // // //   const handleEmailChange = (event) => {
// // // //     setEmail(event.target.value);
// // // //   };

// // // //   const handlePasswordChange = (event) => {
// // // //     setPassword(event.target.value);
// // // //   };

// // // //   const handleRememberMeChange = (event) => {
// // // //     setRememberMe(event.target.checked);
// // // //   };

// // // //   const handleSignIn = async () => {
// // // //     try {
// // // //       const response = await fetch(`${BASE_URL}/dashboard/user-login`, {
// // // //         method: 'POST',
// // // //         headers: {
// // // //           'Content-Type': 'application/json',
// // // //         },
// // // //         body: JSON.stringify({
// // // //           email: email,
// // // //           password: password,
// // // //         }),
// // // //       });

// // // //       if (!response.ok) {
// // // //         throw new Error('Invalid email or password');
// // // //       }

// // // //       const data = await response.json();
// // // //       console.log('Response Data:', data);

// // // //       // Store the JWT token in localStorage
// // // //       localStorage.setItem('jwtToken', data.jwt);

// // // //       const jwtToken = getJwtToken();
// // // //       console.log('JWT Token:', jwtToken);

// // // //       localStorage.setItem('rememberMe', rememberMe.toString());

// // // //       if (rememberMe) {
// // // //         localStorage.setItem('savedEmail', email);
// // // //         localStorage.setItem('savedPassword', password);
// // // //       } else {
// // // //         localStorage.removeItem('savedEmail');
// // // //         localStorage.removeItem('savedPassword');
// // // //       }

// // // //       history('/home');
// // // //     } catch (error) {
// // // //       console.error('Error:', error.message);
// // // //     }
// // // //   };

// // // //   return (
// // // //     <div className="container-fluid">
// // // //       {/* Header */}
// // // //       <div className="header" style={styles.header}>
// // // //         <div className="navbar navbar-expand-lg navbar-light" style={styles.navbar}>
// // // //           <h3 className="navbar-brand" href="#" style={styles.logo}>
// // // //             Nutrify India Now 2.0
// // // //           </h3>
// // // //           <div className="navbar-nav ml-auto" style={{ display: 'flex', flexDirection: 'row' }}>
// // // //             <div style={{ ...styles.iosDownloadContainer, backgroundColor: '#001f3f' }}>
// // // //               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
// // // //                 Download for Android
// // // //               </a>
// // // //             </div>
// // // //             <div style={{ ...styles.iosDownloadContainer, backgroundColor: '#001f3f' }}>
// // // //               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
// // // //                 Download for iOS
// // // //               </a>
// // // //             </div>
// // // //           </div>
// // // //         </div>
// // // //       </div>
  
// // // //       {/* Login Page */}
// // // //       <div className="row">
// // // //         <div className="col-md-6 col-12 p-5">
// // // //           <div className="d-flex flex-column justify-content-center h-100">
// // // //             <div>
// // // //               <h1 className="font-weight-bolder text-info text-gradient">Welcome back</h1>
// // // //               <p className="mb-4">Enter your email and password to sign in</p>
// // // //               <div className="mb-3">
// // // //                 <div className="d-flex align-items-center mb-3">
// // // //                   <FaEnvelope className="mr-2" style={styles.formIcon} />
// // // //                   <label style={styles.formLabel}></label>
// // // //                 <input
// // // //                   type="email"
// // // //                   className="form-control"
// // // //                   placeholder="Enter your email"
// // // //                   value={email}
// // // //                   onChange={handleEmailChange}
// // // //                   style={{ ...styles.formInput, width: '50%' }}
// // // //                   autoComplete="username"
// // // //                 />
                
// // // //                 </div>
// // // //                 <div className="d-flex align-items-center">
// // // //                   <FaLock className="mr-2" style={styles.formIcon} />
// // // //                   <label style={styles.formLabel}></label>
// // // //                   <input
// // // //                     type="password"
// // // //                     className="form-control"
// // // //                     placeholder="Enter your password"
// // // //                     value={password}
// // // //                     onChange={handlePasswordChange}
// // // //                     style={{ ...styles.formInput, width: '50%' }}
// // // //                     autoComplete="current-password"
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               <div className="form-check form-switch mb-3">
// // // //                 <input
// // // //                   className="form-check-input"
// // // //                   type="checkbox"
// // // //                   checked={rememberMe}
// // // //                   onChange={handleRememberMeChange}
// // // //                 />
// // // //                 <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
// // // //               </div>
// // // //               <button
// // // //   type="button"
// // // //   className="btn bg-gradient-info w-100 mb-3"
// // // //   onClick={handleSignIn}
// // // //   style={{ backgroundColor: 'rgb(23, 162, 184)' }}
// // // // >
// // // //   Sign in
// // // // </button>

// // // //               <div className="text-center">
// // // //                 <a href="#" className="text-secondary">Forgot your password?</a>
// // // //               </div>
// // // //             </div>
// // // //           </div>
// // // //         </div>
// // // //         <div className="col-md-6 d-none d-md-block" style={{ ...styles.backgroundContainer, height: 'auto' }}>
// // // //   <img
// // // //     src={`${process.env.PUBLIC_URL}/assets/curved6.jpg`}
// // // //     alt="background"
// // // //     className="img-fluid"
// // // //     style={{
// // // //       width: '100%',
// // // //       objectFit: 'cover',
// // // //       borderTopLeftRadius: '500px', // Slightly cut shape on the left side
// // // //       borderBottomLeftRadius: '0px', // Straight shape on the bottom left
// // // //     }}
// // // //   />
// // // // </div>


// // // //       </div>
// // // //     </div>
// // // //   );
// // // // }

// // // // export default Login;

// // // // const styles = {
// // // //   header: {
// // // //     position: 'relative',
// // // //     padding: '5px',
// // // //     borderRadius: '90px',
// // // //     marginBottom: '20px',
// // // //     marginTop: '18px',
// // // //     backgroundColor: '#F0F8FF',
// // // //   },
// // // //   navbar: {
// // // //     display: 'flex',
// // // //     alignItems: 'center',
// // // //     justifyContent: 'space-between',
// // // //     borderRadius: '90px',
// // // //   },
// // // //   logo: {
// // // //     color: 'black',
// // // //     marginLeft: '20px',
// // // //     textDecoration: 'none',
// // // //     borderRadius: '90px',
// // // //   },
// // // //   iosDownloadContainer: {
// // // //     padding: '1px 5px',
// // // //     borderRadius: '90px',
// // // //     marginLeft: '10px',
// // // //     cursor: 'pointer',
// // // //   },
// // // //   backgroundImage: {
// // // //     height: '100%',
// // // //     width: '100%',
// // // //     objectFit: 'cover',
// // // //   },
// // // //   backgroundContainer: {
// // // //     position: 'absolute',
// // // //     top: 0,
// // // //     right: 0,
// // // //     zIndex: -1,
// // // //     overflow: 'hidden',
// // // //     minHeight: '60vh',
// // // //   },
// // // //   formLabel: {
// // // //     fontWeight: 'bold',
// // // //   },
// // // //   formIcon: {
// // // //     marginRight: '10px',
// // // //     marginBottom: '33px',
// // // //   },
// // // //   formInput: {
// // // //     width: '100%',
// // // //     padding: '10px',
// // // //     marginBottom: '15px',
// // // //     borderRadius: '4px',
// // // //     border: '1px solid #ccc',
// // // //   },
// // // // };
// // // import React, { useState, useEffect } from 'react';
// // // import BASE_URL from '../../components/config/apiConfig';
// // // import { useNavigate } from 'react-router-dom';
// // // import { FaEnvelope, FaLock } from 'react-icons/fa';

// // // const Login = () => {
// // //   const [email, setEmail] = useState(localStorage.getItem('savedEmail') || '');
// // //   const [password, setPassword] = useState(localStorage.getItem('savedPassword') || '');
// // //   const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
// // //   const history = useNavigate();

// // //   useEffect(() => {
// // //     const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
// // //     setRememberMe(rememberMeValue);
// // //   }, []);

// // //   const getJwtToken = () => {
// // //     const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
// // //     return tokenCookie ? tokenCookie.split('=')[1] : null;
// // //   };

// // //   const handleEmailChange = (event) => {
// // //     setEmail(event.target.value);
// // //   };

// // //   const handlePasswordChange = (event) => {
// // //     setPassword(event.target.value);
// // //   };

// // //   const handleRememberMeChange = (event) => {
// // //     setRememberMe(event.target.checked);
// // //   };

// // //   const handleSignIn = async () => {
// // //     try {
// // //       const response = await fetch(`${BASE_URL}/dashboard/user-login`, {
// // //         method: 'POST',
// // //         headers: {
// // //           'Content-Type': 'application/json',
// // //         },
// // //         body: JSON.stringify({
// // //           email: email,
// // //           password: password,
// // //         }),
// // //       });

// // //       if (!response.ok) {
// // //         throw new Error('Invalid email or password');
// // //       }

// // //       const data = await response.json();
// // //       console.log('Response Data:', data);

// // //       // Store the JWT token in localStorage
// // //       localStorage.setItem('jwtToken', data.jwt);

// // //       const jwtToken = getJwtToken();
// // //       console.log('JWT Token:', jwtToken);

// // //       localStorage.setItem('rememberMe', rememberMe.toString());

// // //       if (rememberMe) {
// // //         localStorage.setItem('savedEmail', email);
// // //         localStorage.setItem('savedPassword', password);
// // //       } else {
// // //         localStorage.removeItem('savedEmail');
// // //         localStorage.removeItem('savedPassword');
// // //       }

// // //       history('/home');
// // //     } catch (error) {
// // //       console.error('Error:', error.message);
// // //     }
// // //   };

// // //   return (
// // //     <div className="container-fluid">
// // //       {/* Header */}
// // //       <div className="header" style={styles.header}>
// // //         <div className="navbar navbar-expand-lg navbar-light" style={styles.navbar}>
// // //           <h3 className="navbar-brand" href="#" style={styles.logo}>
// // //             Nutrify India Now 2.0
// // //           </h3>
// // //           <div className="navbar-nav ml-auto" style={{ display: 'flex', flexDirection: 'row' }}>
// // //             <div style={{ ...styles.iosDownloadContainer, backgroundColor: '#001f3f' }}>
// // //               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
// // //                 Download for Android
// // //               </a>
// // //             </div>
// // //             <div style={{ ...styles.iosDownloadContainer, backgroundColor: '#001f3f' }}>
// // //               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
// // //                 Download for iOS
// // //               </a>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </div>
  
// // //       {/* Login Page */}
// // //       <div className="row">
// // //         <div className="col-md-6 col-12 p-5">
// // //           <div className="d-flex flex-column justify-content-center h-100">
// // //             <div>
// // //               <h1 className="font-weight-bolder text-info text-gradient">Welcome back</h1>
// // //               <p className="mb-4">Enter your email and password to sign in</p>
// // //               <div className="mb-3">
// // //                 <div className="d-flex align-items-center mb-3">
// // //                   <FaEnvelope className="mr-2" style={styles.formIcon} />
// // //                   <label style={styles.formLabel}></label>
// // //                   <input
// // //                     type="email"
// // //                     className="form-control"
// // //                     placeholder="Enter your email"
// // //                     value={email}
// // //                     onChange={handleEmailChange}
// // //                     style={{ ...styles.formInput, width: '50%' }}
// // //                     autoComplete="username"
// // //                   />
// // //                 </div>
// // //                 <div className="d-flex align-items-center">
// // //                   <FaLock className="mr-2" style={styles.formIcon} />
// // //                   <label style={styles.formLabel}></label>
// // //                   <input
// // //                     type="password"
// // //                     className="form-control"
// // //                     placeholder="Enter your password"
// // //                     value={password}
// // //                     onChange={handlePasswordChange}
// // //                     style={{ ...styles.formInput, width: '50%' }}
// // //                     autoComplete="current-password"
// // //                   />
// // //                 </div>
// // //               </div>
// // //               <div className="form-check form-switch mb-3">
// // //                 <input
// // //                   className="form-check-input"
// // //                   type="checkbox"
// // //                   checked={rememberMe}
// // //                   onChange={handleRememberMeChange}
// // //                 />
// // //                 <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
// // //               </div>
// // //               <button
// // //                 type="button"
// // //                 className="btn bg-gradient-info w-100 mb-3"
// // //                 onClick={handleSignIn}
// // //                 style={{ backgroundColor: 'rgb(23, 162, 184)' }}
// // //               >
// // //                 Sign in
// // //               </button>
// // //               <div className="text-center">
// // //                 <a href="#" className="text-secondary">Forgot your password?</a>
// // //               </div>
// // //             </div>
// // //           </div>
// // //         </div>
// // //         <div className="col-md-6 d-none d-md-block" style={{ ...styles.backgroundContainer, height: 'auto' }}>
// // //           <img
// // //             src={`${process.env.PUBLIC_URL}/assets/curved6.jpg`}
// // //             alt="background"
// // //             className="img-fluid"
// // //             style={{
// // //               width: '100%',
// // //               objectFit: 'cover',
// // //               borderTopLeftRadius: '500px', // Slightly cut shape on the left side
// // //               borderBottomLeftRadius: '0px', // Straight shape on the bottom left
// // //             }}
// // //           />
// // //         </div>
// // //       </div>

// // //       {/* Footer */}
// // //       <footer className="footer py-1" style={{ ...styles.footer, marginTop: 'auto', paddingBottom: '20px' }}>
// // //   <div className="row">
// // //     <div className="col-12 text-center" style={{ display: 'flex' }}>
// // //       <div className="mb-4" style={{ marginLeft: '80px' }}>
// // //         <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Terms & Conditions</a>
// // //       </div>
// // //       <div className="mb-4" style={{ marginLeft: '80px' }}>
// // //         <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>About Us</a>
// // //       </div>
// // //       <div className="mb-4" style={{ marginLeft: '80px' }}>
// // //         <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Privacy Policy</a>
// // //       </div>
// // //     </div>
// // //   </div>
// // //   <div className="row" style={{ alignItems: 'center' }}>
// // //     <div className="col-12 text-center mt-1">
// // //       <p className="text-secondary mb-0">
// // //         &copy; {new Date().getFullYear()} National Institute Of Nutrition
// // //       </p>
// // //     </div>
// // //   </div>
// // // </footer>


// // //     </div>
// // //   );
// // // }

// // // export default Login;

// // // const styles = {
  
// // //   header: {
// // //     position: 'relative',
// // //     padding: '5px',
// // //     borderRadius: '90px',
// // //     marginBottom: '20px',
// // //     marginTop: '18px',
// // //     backgroundColor: '#F0F8FF',
// // //   },
// // //   navbar: {
// // //     display: 'flex',
// // //     alignItems: 'center',
// // //     justifyContent: 'space-between',
// // //     borderRadius: '90px',
// // //   },
// // //   logo: {
// // //     color: 'black',
// // //     marginLeft: '20px',
// // //     textDecoration: 'none',
// // //     borderRadius: '90px',
// // //   },
// // //   iosDownloadContainer: {
// // //     padding: '1px 5px',
// // //     borderRadius: '90px',
// // //     marginLeft: '10px',
// // //     cursor: 'pointer',
// // //   },
// // //   backgroundImage: {
// // //     height: '100%',
// // //     width: '100%',
// // //     objectFit: 'cover',
// // //   },
// // //   backgroundContainer: {
// // //     position: 'absolute',
// // //     top: 0,
// // //     right: 0,
// // //     zIndex: -1,
// // //     overflow: 'hidden',
// // //     minHeight: '60vh',
// // //   },
// // //   formLabel: {
// // //     fontWeight: 'bold',
// // //   },
// // //   formIcon: {
// // //     marginRight: '10px',
// // //     marginBottom: '33px',
// // //   },
// // //   formInput: {
// // //     width: '100%',
// // //     padding: '10px',
// // //     marginBottom: '15px',
// // //     borderRadius: '4px',
// // //     border: '1px solid #ccc',
// // //   },
// // //   footer: {
// // //     backgroundColor: '#F0F8FF',
// // //     borderRadius: '00px',
// // //     marginTop: '30px',
// // //     paddingBottom: '0px',
// // //   },
// // // };

// // //=====================================================16-4-2024================================================


// // import React, { useState, useEffect } from 'react';
// // import BASE_URL from '../../components/config/apiConfig';
// // import { useNavigate } from 'react-router-dom';
// // import { FaEnvelope, FaLock } from 'react-icons/fa';

// // const Login = () => {
// //   const [email, setEmail] = useState(localStorage.getItem('savedEmail') || '');
// //   const [password, setPassword] = useState(localStorage.getItem('savedPassword') || '');
// //   const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
// //   const history = useNavigate();

// //   useEffect(() => {
// //     const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
// //     setRememberMe(rememberMeValue);
// //   }, []);

// //   const getJwtToken = () => {
// //     const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
// //     return tokenCookie ? tokenCookie.split('=')[1] : null;
// //   };

// //   const handleEmailChange = (event) => {
// //     setEmail(event.target.value);
// //   };

// //   const handlePasswordChange = (event) => {
// //     setPassword(event.target.value);
// //   };

// //   const handleRememberMeChange = (event) => {
// //     setRememberMe(event.target.checked);
// //   };

// //   const handleSignIn = async () => {
// //     try {
// //       const response = await fetch(`${BASE_URL}/dashboard/user-login`, {
// //         method: 'POST',
// //         headers: {
// //           'Content-Type': 'application/json',
// //         },
// //         body: JSON.stringify({
// //           email: email,
// //           password: password,
// //         }),
// //       });

// //       if (!response.ok) {
// //         throw new Error('Invalid email or password');
// //       }

// //       const data = await response.json();
// //       console.log('Response Data:', data);

// //       // Store the JWT token in localStorage
// //       localStorage.setItem('jwtToken', data.jwt);

// //       const jwtToken = getJwtToken();
// //       console.log('JWT Token:', jwtToken);

// //       localStorage.setItem('rememberMe', rememberMe.toString());

// //       if (rememberMe) {
// //         localStorage.setItem('savedEmail', email);
// //         localStorage.setItem('savedPassword', password);
// //       } else {
// //         localStorage.removeItem('savedEmail');
// //         localStorage.removeItem('savedPassword');
// //       }

// //       history('/home');
// //     } catch (error) {
// //       console.error('Error:', error.message);
// //     }
// //   };

// //   return (
// //     <div className="container-fluid" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
// //     {/* Header */}
// //       {/* Header */}
// //       <div className="header" style={styles.header}>
// //         <div className="navbar navbar-expand-lg navbar-light" style={styles.navbar}>
// //           <h3 className="navbar-brand" href="#" style={styles.logo}>
// //             Nutrify India Now 2.0
// //           </h3>
// //           <div className="navbar-nav ml-auto" style={{ display: 'flex', flexDirection: 'row' }}>
// //             <div style={{ ...styles.iosDownloadContainer, backgroundColor: '#001f3f' }}>
// //               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
// //                 Download for Android
// //               </a>
// //             </div>
// //             <div style={{ ...styles.iosDownloadContainer, backgroundColor: '#001f3f' }}>
// //               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
// //                 Download for iOS
// //               </a>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
  
// //       {/* Login Page */}
// //       <div className="row">
// //         <div className="col-md-6 col-12 p-5">
// //           <div className="d-flex flex-column justify-content-center h-100">
// //             <div>
// //               <h1 className="font-weight-bolder text-info text-gradient">Welcome back</h1>
// //               <p className="mb-4">Enter your email and password to sign in</p>
// //               <div className="mb-3">
// //                 <div className="d-flex align-items-center mb-3">
// //                   <FaEnvelope className="mr-2" style={styles.formIcon} />
// //                   <label style={styles.formLabel}></label>
// //                   <input
// //                     type="email"
// //                     className="form-control"
// //                     placeholder="Enter your email"
// //                     value={email}
// //                     onChange={handleEmailChange}
// //                     style={{ ...styles.formInput, width: '50%' }}
// //                     autoComplete="username"
// //                   />
// //                 </div>
// //                 <div className="d-flex align-items-center">
// //                   <FaLock className="mr-2" style={styles.formIcon} />
// //                   <label style={styles.formLabel}></label>
// //                   <input
// //                     type="password"
// //                     className="form-control"
// //                     placeholder="Enter your password"
// //                     value={password}
// //                     onChange={handlePasswordChange}
// //                     style={{ ...styles.formInput, width: '50%' }}
// //                     autoComplete="current-password"
// //                   />
// //                 </div>
// //               </div>
// //               <div className="form-check form-switch mb-3">
// //                 <input
// //                   className="form-check-input"
// //                   type="checkbox"
// //                   checked={rememberMe}
// //                   onChange={handleRememberMeChange}
// //                 />
// //                 <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
// //               </div>
// //               <button
// //                 type="button"
// //                 className="btn bg-gradient-info w-100 mb-3"
// //                 onClick={handleSignIn}
// //                 style={{ backgroundColor: 'rgb(23, 162, 184)' }}
// //               >
// //                 Sign in
// //               </button>
// //               <div className="text-center">
// //                 <a href="#" className="text-secondary">Forgot your password?</a>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //         <div className="col-md-6 d-none d-md-block" style={{ ...styles.backgroundContainer, height: 'auto' }}>
// //           <img
// //             src={`${process.env.PUBLIC_URL}/assets/curved6.jpg`}
// //             alt="background"
// //             className="img-fluid"
// //             style={{
// //               width: '100%',
// //               height: '100%', 
// //               objectFit: 'cover',
// //               borderTopLeftRadius: '500px', // Slightly cut shape on the left side
// //               borderBottomLeftRadius: '0px', // Straight shape on the bottom left
// //             }}
// //           />
// //         </div>
// //       </div>

// //       {/* Footer */}
// //       <footer className="footer py-0" style={{ ...styles.footer, marginTop: 'auto', paddingBottom: '20px' }}>
// //       <div className="container-fluid" style={styles.footerContainer}>
// //           <div className="row">
// //             <div className="col-12 text-center" style={{ display: 'flex' }}>
// //               <div className="mb-4" style={{ marginLeft: '80px' }}>
// //                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Terms & Conditions</a>
// //               </div>
// //               <div className="mb-4" style={{ marginLeft: '80px' }}>
// //                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>About Us</a>
// //               </div>
// //               <div className="mb-4" style={{ marginLeft: '80px' }}>
// //                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Privacy Policy</a>
// //               </div>
// //             </div>
// //           </div>
// //           <div className="row" style={{ alignItems: 'center' }}>
// //             <div className="col-12 text-center mt-1">
// //               <p className="text-secondary mb-0">
// //                 &copy; {new Date().getFullYear()} National Institute Of Nutrition
// //               </p>
// //             </div>
// //           </div>
// //         </div>
// //       </footer>
// //     </div>
// //   );
// // }

// // export default Login;

// // const styles = {
  
// //   header: {
// //     position: 'relative',
// //     padding: '5px',
// //     borderRadius: '90px',
// //     marginBottom: '20px',
// //     marginTop: '18px',
// //     backgroundColor: '#F0F8FF',
// //   },
// //   navbar: {
// //     display: 'flex',
// //     alignItems: 'center',
// //     justifyContent: 'space-between',
// //     borderRadius: '90px',
// //   },
// //   logo: {
// //     color: 'black',
// //     marginLeft: '20px',
// //     textDecoration: 'none',
// //     borderRadius: '90px',
// //   },
// //   iosDownloadContainer: {
// //     padding: '1px 5px',
// //     borderRadius: '90px',
// //     marginLeft: '10px',
// //     cursor: 'pointer',
// //   },
// //   backgroundImage: {
// //     height: '100%',
// //     width: '100%',
// //     objectFit: 'cover',
// //   },
// //   backgroundContainer: {
// //    // position: 'absolute',
// //     top: 0,
// //     right: 0,
// //     zIndex: -1,
// //     overflow: 'hidden',
// //     minHeight: '60vh',
// //   },
// //   formLabel: {
// //     fontWeight: 'bold',
// //   },
// //   formIcon: {
// //     marginRight: '10px',
// //     marginBottom: '33px',
// //   },
// //   formInput: {
// //     width: '100%',
// //     padding: '10px',
// //     marginBottom: '15px',
// //     borderRadius: '4px',
// //     border: '1px solid #ccc',
// //   },
// //   footer: {
// //     backgroundColor: '#F0F8FF',
// //     borderRadius: '00px',
// //     marginTop: '10px', // Adjusted value for reducing space above footer
// //     paddingBottom: '0px', // Adjusted value for controlling space below footer,

// //   },
// //   footerContainer: {
// //     marginBottom: '0', // Set marginBottom to 0 to remove space below footer
// //   },
// // };

// // //====================================this is correct code ===========================
// // import React, { useState, useEffect } from 'react';
// // import BASE_URL from '../../components/config/apiConfig';
// // import { useNavigate } from 'react-router-dom';
// // import { FaEnvelope, FaLock } from 'react-icons/fa';

// // const Login = () => {
// //   const [email, setEmail] = useState(localStorage.getItem('savedEmail') || '');
// //   const [password, setPassword] = useState(localStorage.getItem('savedPassword') || '');
// //   const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
// //   const history = useNavigate();

// //   useEffect(() => {
// //     const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
// //     setRememberMe(rememberMeValue);
// //   }, []);

// //   const getJwtToken = () => {
// //     const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
// //     return tokenCookie ? tokenCookie.split('=')[1] : null;
// //   };

// //   const handleEmailChange = (event) => {
// //     setEmail(event.target.value);
// //   };

// //   const handlePasswordChange = (event) => {
// //     setPassword(event.target.value);
// //   };

// //   const handleRememberMeChange = (event) => {
// //     setRememberMe(event.target.checked);
// //   };

// //   const handleSignIn = async () => {
// //     try {
// //       const response = await fetch(`${BASE_URL}/dashboard/user-login`, {
// //         method: 'POST',
// //         headers: {
// //           'Content-Type': 'application/json',
// //         },
// //         body: JSON.stringify({
// //           email: email,
// //           password: password,
// //         }),
// //       });

// //       if (!response.ok) {
// //         throw new Error('Invalid email or password');
// //       }

// //       const data = await response.json();
// //       console.log('Response Data:', data);

// //       // Store the JWT token in localStorage
// //       localStorage.setItem('jwtToken', data.jwt);

// //       const jwtToken = getJwtToken();
// //       console.log('JWT Token:', jwtToken);

// //       localStorage.setItem('rememberMe', rememberMe.toString());

// //       if (rememberMe) {
// //         localStorage.setItem('savedEmail', email);
// //         localStorage.setItem('savedPassword', password);
// //       } else {
// //         localStorage.removeItem('savedEmail');
// //         localStorage.removeItem('savedPassword');
// //       }

// //       history('/home');
// //     } catch (error) {
// //       console.error('Error:', error.message);
// //     }
// //   };

// //   // Internal CSS
// //   const internalStyles = `
// //     .header {
// //       position: relative;
// //       padding: 5px;
// //       border-radius: 90px;
// //       margin-bottom: 20px;
// //       margin-top: 18px;
// //       background-color: #F0F8FF;
// //     }

// //     .navbar {
// //       display: flex;
// //       align-items: center;
// //       justify-content: space-between;
// //       border-radius: 90px;
// //     }

// //     .logo {
// //       color: black;
// //       margin-left: 20px;
// //       text-decoration: none;
// //       border-radius: 90px;
// //     }

// //     .iosDownloadContainer {
// //       padding: 1px 5px;
// //       border-radius: 90px;
// //       margin-left: 10px;
// //       cursor: pointer;
// //     }

// //     .formLabel {
// //       font-weight: bold;
// //     }

// //     .formIcon {
// //       margin-right: 10px;
// //       margin-bottom: 33px;
// //     }

// //     .formInput {
// //       width: 100%;
// //       padding: 10px;
// //       margin-bottom: 15px;
// //       border-radius: 4px;
// //       border: 1px solid #ccc;
// //     }

// //     .footer {
// //       background-color: #F0F8FF;
// //       border-radius: 00px;
// //       margin-top: 10px;
// //       padding-bottom: 0px;
// //     }

// //     .footerContainer {
// //       margin-bottom: 0;
// //     }
// //   `;

// //   return (
// //     <div className="container-fluid" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
// //       <style>{internalStyles}</style>
// //       {/* Header */}
// //       <div className="header">
// //         <div className="navbar navbar-expand-lg navbar-light">
// //           <h3 className="navbar-brand" href="#">
// //             Nutrify India Now 2.0
// //           </h3>
// //           <div className="navbar-nav ml-auto">
// //             <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
// //               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
// //                 Download for Android
// //               </a>
// //             </div>
// //             <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
// //               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
// //                 Download for iOS
// //               </a>
// //             </div>
// //           </div>
// //         </div>
// //       </div>

// //       {/* Login Page */}
// //       <div className="row">
// //         <div className="col-md-6 col-12 p-5">
// //           <div className="d-flex flex-column justify-content-center h-100">
// //             <div>
// //               <h1 className="font-weight-bolder text-info text-gradient">Welcome back</h1>
// //               <p className="mb-4">Enter your email and password to sign in</p>
// //               <div className="mb-3">
// //                 <div className="d-flex align-items-center mb-3">
// //                   <FaEnvelope className="mr-2 formIcon" />
// //                   <label className="formLabel"></label>
// //                   <input
// //                     type="email"
// //                     className="form-control formInput"
// //                     placeholder="Enter your email"
// //                     value={email}
// //                     onChange={handleEmailChange}
// //                     autoComplete="username"
// //                   />
// //                 </div>
// //                 <div className="d-flex align-items-center">
// //                   <FaLock className="mr-2 formIcon" />
// //                   <label className="formLabel"></label>
// //                   <input
// //                     type="password"
// //                     className="form-control formInput"
// //                     placeholder="Enter your password"
// //                     value={password}
// //                     onChange={handlePasswordChange}
// //                     autoComplete="current-password"
// //                   />
// //                 </div>
// //               </div>
// //               <div className="form-check form-switch mb-3">
// //                 <input
// //                   className="form-check-input"
// //                   type="checkbox"
// //                   checked={rememberMe}
// //                   onChange={handleRememberMeChange}
// //                 />
// //                 <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
// //               </div>
// //               <button
// //                 type="button"
// //                 className="btn bg-gradient-info w-100 mb-3"
// //                 onClick={handleSignIn}
// //                 style={{ backgroundColor: 'rgb(23, 162, 184)' }}
// //               >
// //                 Sign in
// //               </button>
// //               <div className="text-center">
// //                 <a href="#" className="text-secondary">Forgot your password?</a>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //         <div className="col-md-6 d-none d-md-block backgroundContainer">
// //           <img
// //             src={`${process.env.PUBLIC_URL}/assets/curved6.jpg`}
// //             alt="background"
// //             className="img-fluid"
// //             style={{
// //               width: '100%',
// //               height: '100%', 
// //               objectFit: 'cover',
// //               borderTopLeftRadius: '500px', // Slightly cut shape on the left side
// //               borderBottomLeftRadius: '0px', // Straight shape on the bottom left
// //             }}
// //           />
// //         </div>
// //       </div>

// //       {/* Footer */}
// //       <footer className="footer py-0">
// //         <div className="container-fluid footerContainer">
// //           <div className="row">
// //             <div className="col-12 text-center">
// //               <div className="mb-4">
// //                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Terms & Conditions</a>
// //               </div>
// //               <div className="mb-4">
// //                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>About Us</a>
// //               </div>
// //               <div className="mb-4">
// //                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Privacy Policy</a>
// //               </div>
// //             </div>
// //           </div>
// //           <div className="row" style={{ alignItems: 'center' }}>
// //             <div className="col-12 text-center mt-1">
// //               <p className="text-secondary mb-0">
// //                 &copy; {new Date().getFullYear()} National Institute Of Nutrition
// //               </p>
// //             </div>
// //           </div>
// //         </div>
// //       </footer>
// //     </div>
// //   );
// // }

// // export default Login;
// //============================================testing===================
// import React, { useState, useEffect } from 'react';
// import BASE_URL from '../../components/config/apiConfig';
// import { useNavigate } from 'react-router-dom';
// import { FaEnvelope, FaLock , FaUserShield } from 'react-icons/fa';

// const Login = () => {
//   const [email, setEmail] = useState(localStorage.getItem('savedEmail') || '');
//   const [password, setPassword] = useState(localStorage.getItem('savedPassword') || '');
//   const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
//   const history = useNavigate();

//   useEffect(() => {
//     const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
//     setRememberMe(rememberMeValue);
//   }, []);

//   const getJwtToken = () => {
//     const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
//     return tokenCookie ? tokenCookie.split('=')[1] : null;
//   };

//   const handleEmailChange = (event) => {
//     setEmail(event.target.value);
//   };

//   const handlePasswordChange = (event) => {
//     setPassword(event.target.value);
//   };

//   const handleRememberMeChange = (event) => {
//     setRememberMe(event.target.checked);
//   };

//   const handleSignIn = async () => {
//     try {
//       const response = await fetch(`${BASE_URL}/dashboard/user-login`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           email: email,
//           password: password,
//         }),
//       });

//       if (!response.ok) {
//         throw new Error('Invalid email or password');
//       }

//       const data = await response.json();
//       console.log('Response Data:', data);

//       // Store the JWT token in localStorage
//       localStorage.setItem('jwtToken', data.jwt);

//       const jwtToken = getJwtToken();
//       console.log('JWT Token:', jwtToken);

//       localStorage.setItem('rememberMe', rememberMe.toString());

//       if (rememberMe) {
//         localStorage.setItem('savedEmail', email);
//         localStorage.setItem('savedPassword', password);
//       } else {
//         localStorage.removeItem('savedEmail');
//         localStorage.removeItem('savedPassword');
//       }

//       history('/home');
//     } catch (error) {
//       console.error('Error:', error.message);
//     }
//   };

//   // Internal CSS
//   const internalStyles = `
//     .header {
//       position: relative;
//       padding: 5px;
//       border-radius: 90px;
//       margin-bottom: 20px;
//       margin-top: 18px;
//       background-color: #F0F8FF;
//     }

//     .navbar {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       border-radius: 90px;
//     }

//     .logo {
//       color: black;
//       margin-left: 20px;
//       text-decoration: none;
//       border-radius: 90px;
//     }

//     .iosDownloadContainer {
//       padding: 1px 5px;
//       border-radius: 90px;
//       margin-left: 10px;
//       cursor: pointer;
//     }

//     .formLabel {
//       font-weight: bold;
//     }

//     .formIcon {
//       margin-right: 10px;
//       margin-bottom: 33px;
//     }

//     .formInput {
//       width: 100%;
//       padding: 10px;
//       margin-bottom: 15px;
//       border-radius: 4px;
//       border: 1px solid #ccc;
//     }

//     .footer {
//       background-color: #F0F8FF;
//       border-radius: 00px;
//       margin-top: 10px;
//       padding-bottom: 0px;
//     }

//     .footerContainer {
//       margin-bottom: 0;
//     }
//   `;

//   return (
//     <div className="container-fluid" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
//       <style>{internalStyles}</style>
//       {/* Header */}
//       <div className="header">
//         <div className="navbar navbar-expand-lg navbar-light">
//           <h3 className="navbar-brand" href="#">
//             Nutrify India Now 2.0
//           </h3>
//           <div className="navbar-nav ml-auto">
//             <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
//               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
//                 Download for Android
//               </a>
//             </div>
//             <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
//               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
//                 Download for iOS
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Login Page */}
//       <div className="row">
//         <div className="col-md-6 col-12 p-5">
//           <div className="d-flex flex-column justify-content-center h-100">
//           <div>
//   <h1 className="font-weight-bolder text-info text-gradient">Welcome back</h1>
//   <p className="mb-4">Enter your email and password to sign in</p>
  
//   {/* Role Selection Dropdown */}
//   <div className="mb-3">
//   <label htmlFor="roleSelect" className="formLabel" style={{ marginLeft: "23px" }}>
//     Select Your Role
//   </label>
//   <div className="d-flex align-items-center">
//     {/* Icon on the left */}
//     <FaUserShield className="mr-2 formIcon" style={{ color: '#000000', fontSize: '1.2rem' }} />
//     <select
//       id="roleSelect"
//       className="form-control formInput"
//       style={{
//         width: '100%',
//         padding: '10px',
//         marginBottom: '15px',
//         borderRadius: '4px',
//         border: '1px solid #ccc',
//       }}
//     >
//       <option value="admin">Admin Login</option>
//       <option value="publicationAdmin">Publication Admin</option>
//     </select>
//   </div>
// </div>


//   <div className="mb-3">
//     <div className="d-flex align-items-center mb-3">
//       <FaEnvelope className="mr-2 formIcon" />
//       <label className="formLabel"></label>
//       <input
//         type="email"
//         className="form-control formInput"
//         placeholder="Enter your email"
//         value={email}
//         onChange={handleEmailChange}
//         autoComplete="username"
//       />
//     </div>
//     <div className="d-flex align-items-center">
//       <FaLock className="mr-2 formIcon" />
//       <label className="formLabel"></label>
//       <input
//         type="password"
//         className="form-control formInput"
//         placeholder="Enter your password"
//         value={password}
//         onChange={handlePasswordChange}
//         autoComplete="current-password"
//       />
//     </div>
//   </div>
  
//   <div className="form-check form-switch mb-3">
//     <input
//       className="form-check-input"
//       type="checkbox"
//       checked={rememberMe}
//       onChange={handleRememberMeChange}
//     />
//     <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
//   </div>
//   <button
//     type="button"
//     className="btn bg-gradient-info w-100 mb-3"
//     onClick={handleSignIn}
//     style={{ backgroundColor: 'rgb(23, 162, 184)' }}
//   >
//     Sign in
//   </button>
//   <div className="text-center">
//     <a href="#" className="text-secondary">Forgot your password?</a>
//   </div>
// </div>

//           </div>
//         </div>
//         <div className="col-md-6 d-none d-md-block backgroundContainer">
//           <img
//             src={`${process.env.PUBLIC_URL}/assets/curved6.jpg`}
//             alt="background"
//             className="img-fluid"
//             style={{
//               width: '100%',
//               height: '100%', 
//               objectFit: 'cover',
//               borderTopLeftRadius: '500px', // Slightly cut shape on the left side
//               borderBottomLeftRadius: '0px', // Straight shape on the bottom left
//             }}
//           />
//         </div>
//       </div>

//       {/* Footer */}
//       <footer className="footer py-0">
//         <div className="container-fluid footerContainer">
//           <div className="row">
//             <div className="col-12 text-center">
//               <div className="mb-4">
//                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Terms & Conditions</a>
//               </div>
//               <div className="mb-4">
//                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>About Us</a>
//               </div>
//               <div className="mb-4">
//                 <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Privacy Policy</a>
//               </div>
//             </div>
//           </div>
//           <div className="row" style={{ alignItems: 'center' }}>
//             <div className="col-12 text-center mt-1">
//               <p className="text-secondary mb-0">
//                 &copy; {new Date().getFullYear()} National Institute Of Nutrition
//               </p>
//             </div>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// }

// export default Login;

//==============================final============================


// import React, { useState, useEffect } from 'react';
// import BASE_URL from '../../components/config/apiConfig';
// import { useNavigate } from 'react-router-dom';
// import { FaEnvelope, FaLock, FaUserShield } from 'react-icons/fa';
// import Footer from "../bookinfo/footer"; 
// const Login = () => {
//   const [email, setEmail] = useState(localStorage.getItem('savedEmail') || '');
//   const [password, setPassword] = useState(localStorage.getItem('savedPassword') || '');
//   const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
//   const [role, setRole] = useState('admin'); // New state for role
//   const history = useNavigate();
// const navigate = useNavigate();
//   useEffect(() => {
//     const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
//     setRememberMe(rememberMeValue);
//   }, []);

//   const getJwtToken = () => {
//     const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
//     return tokenCookie ? tokenCookie.split('=')[1] : null;
//   };

//   const handleEmailChange = (event) => {
//     setEmail(event.target.value);
//   };

//   const handlePasswordChange = (event) => {
//     setPassword(event.target.value);
//   };

//   const handleRememberMeChange = (event) => {
//     setRememberMe(event.target.checked);
//   };

//   const handleRoleChange = (event) => {
//     setRole(event.target.value); // Set the role value from the dropdown
//   };

//   // const handleSignIn = async () => {
//   //   try {
//   //     // Determine the correct API URL based on selected role
//   //     const apiUrl = role === 'admin' ? `${BASE_URL}/dashboard/user-login` : `${BASE_URL}/dashboard/book-publication-user-login`;

//   //     const response = await fetch(apiUrl, {
//   //       method: 'POST',
//   //       headers: {
//   //         'Content-Type': 'application/json',
//   //       },
//   //       body: JSON.stringify({
//   //         email: email,
//   //         password: password,
//   //       }),
//   //     });

//   //     if (!response.ok) {
//   //       throw new Error('Invalid email or password');
//   //     }

//   //     const data = await response.json();
//   //     console.log('Response Data:', data);

//   //     // Store the JWT token in localStorage
//   //     localStorage.setItem('jwtToken', data.jwt);

//   //     const jwtToken = getJwtToken();
//   //     console.log('JWT Token:', jwtToken);

//   //     localStorage.setItem('rememberMe', rememberMe.toString());

//   //     if (rememberMe) {
//   //       localStorage.setItem('savedEmail', email);
//   //       localStorage.setItem('savedPassword', password);
//   //     } else {
//   //       localStorage.removeItem('savedEmail');
//   //       localStorage.removeItem('savedPassword');
//   //     }

//   //     history('/home');
//   //   } catch (error) {
//   //     console.error('Error:', error.message);
//   //   }
//   // };
// const handleSignIn = async () => {
//   try {
//     // Determine the correct API URL based on selected role
//     const apiUrl = role === 'admin' 
//       ? `${BASE_URL}/dashboard/user-login` 
//       : `${BASE_URL}/dashboard/book-publication-user-login`;

//     const response = await fetch(apiUrl, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         email: email,
//         password: password,
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Invalid email or password');
//     }

//     const data = await response.json();
//     console.log('Response Data:', data);

//     // Store the JWT token in localStorage
//     localStorage.setItem('jwtToken', data.jwt);

//     const jwtToken = getJwtToken();
//     console.log('JWT Token:', jwtToken);

//     // Handle remember me functionality
//     localStorage.setItem('rememberMe', rememberMe.toString());

//     if (rememberMe) {
//       localStorage.setItem('savedEmail', email);
//       localStorage.setItem('savedPassword', password);
//     } else {
//       localStorage.removeItem('savedEmail');
//       localStorage.removeItem('savedPassword');
//     }

//     // Redirect based on role
//     if (role === 'admin') {
//       history('/home'); // Redirect to admin dashboard
//     } else if (role === 'publicationAdmin') {
//       history('/bookinfo'); // Redirect to book publication page
//     }
//   } catch (error) {
//     console.error('Error:', error.message);
//   }
// };

//   // Internal CSS
//   const internalStyles = `
//     .header {
//       position: relative;
//       padding: 5px;
//       border-radius: 90px;
//       margin-bottom: 20px;
//       margin-top: 18px;
//       background-color: #F0F8FF;
//     }

//     .navbar {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       border-radius: 90px;
//     }

//     .logo {
//       color: black;
//       margin-left: 20px;
//       text-decoration: none;
//       border-radius: 90px;
//     }

//     .iosDownloadContainer {
//       padding: 1px 5px;
//       border-radius: 90px;
//       margin-left: 10px;
//       cursor: pointer;
//     }

//     .formLabel {
//       font-weight: bold;
//     }

//     .formIcon {
//       margin-right: 10px;
//       margin-bottom: 33px;
//     }

//     .formInput {
//       width: 100%;
//       padding: 10px;
//       margin-bottom: 15px;
//       border-radius: 4px;
//       border: 1px solid #ccc;
//     }

//     .footer {
//       background-color: #F0F8FF;
//       border-radius: 00px;
//       margin-top: 10px;
//       padding-bottom: 0px;
//     }

//     .footerContainer {
//       margin-bottom: 0;
//     }
//   `;

//   return (
//     <div className="container-fluid" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' ,paddingLeft: 0}}>
//       <style>{internalStyles}</style>
//       {/* Header */}
//       <div className="header">
//         <div className="navbar navbar-expand-lg navbar-light">
//           <h3 className="navbar-brand" href="#">
//             Nutrify India Now 2.0
//           </h3>
//           {/* <div className="navbar-nav ml-auto">
//             <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
//               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
//                 Download for Android
//               </a>
//             </div>
//             <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
//               <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
//                 Download for iOS
//               </a>
//             </div>
//           </div> */}

// <div className="navbar-nav ml-auto">
//   <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
//     <a
//       className="nav-item nav-link"
//       href="https://play.google.com/store/search?q=nutrify+india+now+2.0&c=apps&hl=en"
//       style={{ color: '#fff', textDecoration: 'none' }}
//       target="_blank" // Opens the link in a new tab
//       rel="noopener noreferrer" // Security best practice
//     >
//       Download for Android
//     </a>
//   </div>
//   <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
//     <a
//       className="nav-item nav-link"
//       href="https://play.google.com/store/search?q=nutrify+india+now+2.0&c=apps&hl=en"
//       style={{ color: '#fff', textDecoration: 'none' }}
//       target="_blank" // Opens the link in a new tab
//       rel="noopener noreferrer" // Security best practice
//     >
//       Download for iOS
//     </a>
//   </div>
// </div>


//         </div>
//       </div>

//       {/* Login Page */}
//       <div className="row">
//         <div className="col-md-6 col-12 p-5">
//           <div className="d-flex flex-column justify-content-center h-100">
//             <div>
//               <h1 className="font-weight-bolder text-info text-gradient">Welcome back</h1>
//               <p className="mb-4">Enter your email and password to sign in</p>

//               {/* Role Selection Dropdown */}
//               <div className="mb-3">
//                 <label htmlFor="roleSelect" className="formLabel" style={{ marginLeft: "23px" }}>
//                   Select Your Role
//                 </label>
//                 <div className="d-flex align-items-center">
//                   {/* Icon on the left */}
//                   <FaUserShield className="mr-2 formIcon" style={{ color: '#000000', fontSize: '1.2rem' }} />
//                   <select
//                     id="roleSelect"
//                     className="form-control formInput"
//                     style={{
//                       width: '100%',
//                       padding: '10px',
//                       marginBottom: '15px',
//                       borderRadius: '4px',
//                       border: '1px solid #ccc',
//                     }}
//                     value={role}
//                     onChange={handleRoleChange}
//                   >
//                     <option value="admin">Admin Login</option>
//                     <option value="publicationAdmin">Publication Admin</option>
//                   </select>
//                 </div>
//               </div>

//               <div className="mb-3">
//                 <div className="d-flex align-items-center mb-3">
//                   <FaEnvelope className="mr-2 formIcon" />
//                   <label className="formLabel"></label>
//                   <input
//                     type="email"
//                     className="form-control formInput"
//                     placeholder="Enter your email"
//                     value={email}
//                     onChange={handleEmailChange}
//                     autoComplete="username"
//                   />
//                 </div>
//                 <div className="d-flex align-items-center">
//                   <FaLock className="mr-2 formIcon" />
//                   <label className="formLabel"></label>
//                   <input
//                     type="password"
//                     className="form-control formInput"
//                     placeholder="Enter your password"
//                     value={password}
//                     onChange={handlePasswordChange}
//                     autoComplete="current-password"
//                   />
//                 </div>
//               </div>

//               <div className="form-check form-switch mb-3">
//                 <input
//                   className="form-check-input"
//                   type="checkbox"
//                   checked={rememberMe}
//                   onChange={handleRememberMeChange}
//                 />
//                 <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
//               </div>
//               <button
//                 type="button"
//                 className="btn bg-gradient-info w-100 mb-3"
//                 onClick={handleSignIn}
//                 style={{ backgroundColor: 'rgb(23, 162, 184)' }}
//               >
//                 Sign in
//               </button>
//               {/* <div className="text-center">
//                 <a href="#" className="text-secondary">Forgot your password?</a>
//               </div> */}
//             </div>

//           </div>
//         </div>
//         <div className="col-md-6 d-none d-md-block backgroundContainer">
//           <img
//             src={`${process.env.PUBLIC_URL}/assets/curved6.jpg`}
//             alt="background"
//             className="img-fluid"
//             style={{
//               width: '100%',
//               height: '100%',
//               objectFit: 'cover',
//               borderTopLeftRadius: '500px', // Slightly cut shape on the left side
//               borderBottomLeftRadius: '0px', // Straight shape on the bottom left
//             }}
//           />
//         </div>
//       </div>

//       {/* Footer */}
//       {/* <footer className="footer py-5 mt-auto">
//         <div className="footerContainer text-center">
//           <p className="text-secondary mb-0">Nutrify India Now 2.0</p>
//         </div>
//       </footer> */}


//       <Footer />
//     </div>
//   );
// };

// export default Login;
//===============================testing=======================================


import React, { useState, useEffect } from 'react';
import BASE_URL from '../../components/config/apiConfig';
import { useNavigate } from 'react-router-dom';
import { FaEnvelope, FaLock, FaUserShield, FaEye, FaEyeSlash } from 'react-icons/fa';
import Footer from "../bookinfo/footer"; 
const Login = () => { 
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem('savedEmail') || '');
  const [password, setPassword] = useState(localStorage.getItem('savedPassword') || '');
  const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
  const [role, setRole] = useState('admin'); 
const [errorMessage, setErrorMessage] = useState('');


  const history = useNavigate();
const navigate = useNavigate();
  useEffect(() => {
    const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
    setRememberMe(rememberMeValue);
  }, []);

  const getJwtToken = () => {
    const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
    return tokenCookie ? tokenCookie.split('=')[1] : null;
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value); // Set the role value from the dropdown
  };
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
// const handleSignIn = async () => {
//   try {
//     // Determine the correct API URL based on selected role
//     const apiUrl = role === 'admin' 
//       ? `${BASE_URL}/dashboard/user-login` 
//       : `${BASE_URL}/dashboard/book-publication-user-login`;

//     const response = await fetch(apiUrl, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         email: email,
//         password: password,
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Invalid email or password');
//     }

//     const data = await response.json();
//     console.log('Response Data:', data);

//     // Store the JWT token in localStorage
//     localStorage.setItem('jwtToken', data.jwt);

//     const jwtToken = getJwtToken();
//     console.log('JWT Token:', jwtToken);

//     // Handle remember me functionality
//     localStorage.setItem('rememberMe', rememberMe.toString());

//     if (rememberMe) {
//       localStorage.setItem('savedEmail', email);
//       localStorage.setItem('savedPassword', password);
//     } else {
//       localStorage.removeItem('savedEmail');
//       localStorage.removeItem('savedPassword');
//     }

//     // Redirect based on role
//     if (role === 'admin') {
//       history('/home'); // Redirect to admin dashboard
//     } else if (role === 'publicationAdmin') {
//       history('/bookinfo'); // Redirect to book publication page
//     }
//   } catch (error) {
//     console.error('Error:', error.message);
//   }
// };
 const handleSignIn = async () => {
    try {
      const apiUrl = role === 'admin'
        ? `${BASE_URL}/dashboard/user-login`
        : `${BASE_URL}/dashboard/book-publication-user-login`;

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Invalid email or password');
      }

      const data = await response.json();
      localStorage.setItem('jwtToken', data.jwt);

      if (rememberMe) {
        localStorage.setItem('savedEmail', email);
        localStorage.setItem('savedPassword', password);
      } else {
        localStorage.removeItem('savedEmail');
        localStorage.removeItem('savedPassword');
      }

      if (role === 'admin') {
        history('/home');
      } else if (role === 'publicationAdmin') {
        history('/bookinfo');
      }
    } catch (error) {
      console.error('Error:', error.message);
      setErrorMessage(error.message); // Set the error message
      setTimeout(() => setErrorMessage(''), 4000); // Hide error after 4 seconds
    }
  };

  // Internal CSS
  const internalStyles = `
    .header {
      position: relative;
      padding: 5px;
      border-radius: 90px;
      margin-bottom: 20px;
      margin-top: 18px;
      background-color: #F0F8FF;
    }

    .navbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 90px;
    }

    .logo {
      color: black;
      margin-left: 20px;
      text-decoration: none;
      border-radius: 90px;
    }

    .iosDownloadContainer {
      padding: 1px 5px;
      border-radius: 90px;
      margin-left: 10px;
      cursor: pointer;
    }

    .formLabel {
      font-weight: bold;
    }

    .formIcon {
      margin-right: 10px;
      margin-bottom: 33px;
    }

    .formInput {
      width: 100%;
      padding: 10px;
      margin-bottom: 15px;
      border-radius: 4px;
      border: 1px solid #ccc;
    }

    .footer {
      background-color: #F0F8FF;
      border-radius: 00px;
      margin-top: 10px;
      padding-bottom: 0px;
    }
    .errorMessage {
      position: fixed;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ff4d4d;
      color: white;
      padding: 10px;
      border-radius: 5px;
      font-size: 14px;
      z-index: 9999;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .footerContainer {
      margin-bottom: 0;
    }
  `;

  return (
    <div className="container-fluid" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' ,paddingLeft: 0}}>
      <style>{internalStyles}</style>
       {/* Error Message Popup */}
      {errorMessage && (
        <div className="errorMessage">
          {errorMessage}
        </div>
      )}
      {/* Header */}
      <div className="header">
        <div className="navbar navbar-expand-lg navbar-light">
          <h3 className="navbar-brand" href="#">
            Nutrify India Now 2.0
          </h3>
          {/* <div className="navbar-nav ml-auto">
            <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
              <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
                Download for Android
              </a>
            </div>
            <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
              <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
                Download for iOS
              </a>
            </div>
          </div> */}

<div className="navbar-nav ml-auto">
  <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
    <a
      className="nav-item nav-link"
      href="https://play.google.com/store/search?q=nutrify+india+now+2.0&c=apps&hl=en"
      style={{ color: '#fff', textDecoration: 'none' }}
      target="_blank" // Opens the link in a new tab
      rel="noopener noreferrer" // Security best practice
    >
      Download for Android
    </a>
  </div>
  <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
    <a
      className="nav-item nav-link"
      href="https://play.google.com/store/search?q=nutrify+india+now+2.0&c=apps&hl=en"
      style={{ color: '#fff', textDecoration: 'none' }}
      target="_blank" // Opens the link in a new tab
      rel="noopener noreferrer" // Security best practice
    >
      Download for iOS
    </a>
  </div>
</div>


        </div>
      </div>

      {/* Login Page */}
      <div className="row">
        <div className="col-md-6 col-12 p-5">
          <div className="d-flex flex-column justify-content-center h-100">
            <div>
              <h1 className="font-weight-bolder text-info text-gradient">Welcome back</h1>
              <p className="mb-4">Enter your email and password to sign in</p>

              {/* Role Selection Dropdown */}
           <div className="mb-3">
  <label htmlFor="roleSelect" className="formLabel" style={{ marginLeft: "23px" }}>
    Select Your Role
  </label>
  <div className="d-flex align-items-center">
    <FaUserShield className="mr-2 formIcon" style={{ color: '#000000', fontSize: '1.2rem' }} />
    <div style={{ position: 'relative', width: '100%' }}>
      <select
        id="roleSelect"
        className="form-control formInput"
        style={{
          width: '100%',
          padding: '10px',
          paddingRight: '40px', // Extra space for the dropdown indicator
          marginBottom: '15px',
          borderRadius: '4px',
          border: '1px solid #ccc',
          appearance: 'none', // Remove default arrow for custom styling
          background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path d="M7 10l5 5 5-5H7z"/></svg>') no-repeat right 10px center`,
          backgroundSize: '16px 16px', // Adjust size of the arrow
        }}
        value={role}
        onChange={handleRoleChange}
      >
        <option value="admin">Admin Login</option>
        <option value="publicationAdmin">Publication Admin</option>
      </select>
    </div>
  </div>
</div>


              {/* <div className="mb-3">
                <div className="d-flex align-items-center mb-3">
                  <FaEnvelope className="mr-2 formIcon" />
                  <label className="formLabel"></label>
                  <input
                    type="email"
                    className="form-control formInput"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    autoComplete="username"
                  />
                </div>
                <div className="d-flex align-items-center">
                  <FaLock className="mr-2 formIcon" />
                  <label className="formLabel"></label>
                  <input
                    type="password"
                    className="form-control formInput"
                    placeholder="Enter your password"
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="current-password"
                  />
                </div>
              </div> */}



               <div className="mb-3">
      {/* Email Input */}
      <div className="d-flex align-items-center mb-3">
        <FaEnvelope className="mr-2 formIcon" />
        <label className="formLabel"></label>
        <input
          type="email"
          className="form-control formInput"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
          autoComplete="username"
        />
      </div>

      {/* Password Input with Show/Hide */}
      <div className="d-flex align-items-center position-relative">
        <FaLock className="mr-2 formIcon" />
        <label className="formLabel"></label>
        <input
          type={showPassword ? 'text' : 'password'} // Toggle input type
          className="form-control formInput"
          placeholder="Enter your password"
          value={password}
          onChange={handlePasswordChange}
          autoComplete="current-password"
        />
        <span
          onClick={togglePasswordVisibility}
          style={{
            position: 'absolute',
            right: '10px',
            cursor: 'pointer',
            color: '#555',
          }}
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Toggle Icon */}
        </span>
      </div>
    </div>

              <div className="form-check form-switch mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
                <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
              </div>
              <button
                type="button"
                className="btn bg-gradient-info w-100 mb-3"
                onClick={handleSignIn}
                style={{ backgroundColor: 'rgb(23, 162, 184)' }}
              >
                Sign in
              </button>
              {/* <div className="text-center">
                <a href="#" className="text-secondary">Forgot your password?</a>
              </div> */}
            </div>

          </div>
        </div>
        
        <div className="col-md-6 d-none d-md-block backgroundContainer">
          <img
            src={`${process.env.PUBLIC_URL}/assets/curved6.jpg`}
            alt="background"
            className="img-fluid"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderTopLeftRadius: '500px', // Slightly cut shape on the left side
              borderBottomLeftRadius: '0px', 
            }}
          />
        </div>


      </div>

     


      <Footer />
    </div>
  );
};

export default Login;
