// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import BASE_URL from '../../components/config/apiConfig';

// import "./bookdata.scss";
// import Header from '../bookinfo/header';  // Import Header
// import Footer from '../bookinfo/footer'; 

// const Book = () => {
//   const [books, setBooks] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [updatedQuantity, setUpdatedQuantity] = useState('');
//   const [updatedPrice, setUpdatedPrice] = useState('');
//   const [showAddBookPopup, setShowAddBookPopup] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [newBookData, setNewBookData] = useState({
//     title: '',
//     author: '',
//     quantity: '',
//     price: '',
//     image: '',
//     year: '',
//   });
//   const [expandedTitle, setExpandedTitle] = useState({});
//   const [selectedBook, setSelectedBook] = useState(null);

//   useEffect(() => {
//     fetchBooks();
//   }, []);

//   const fetchBooks = async () => {
//     try {
//       const key = localStorage.getItem('jwtToken');
//       const response = await axios.get(`${BASE_URL}/dashboard/get`, {
//         headers: {
//           'Content-Type': 'application/json',
//           'Auth': `Bearer ${key}`,
//         },
//       });
//       setBooks(response.data);
//     } catch (error) {
//       console.error('Error fetching book details:', error);
//     }
//   };

//   const toggleExpandedTitle = (bookId) => {
//     setExpandedTitle((prevState) => ({
//       ...prevState,
//       [bookId]: !prevState[bookId],
//     }));
//   };

//   const handleUpdateClick = (book) => {
//     setSelectedBook(book);
//     setUpdatedQuantity(book.quantity);
//     setUpdatedPrice(book.price);
//   };

//   // const handleUpdate = async () => {
//   //   try {
//   //     const key = localStorage.getItem('jwtToken');
//   //     if (!key) {
//   //       console.error('No JWT token found');
//   //       return;
//   //     }

//   //     const updatedBook = {
//   //       ...selectedBook,
//   //       quantity: updatedQuantity,
//   //       price: updatedPrice,
//   //     };

//   //     const config = {
//   //       headers: {
//   //         'Authorization': `Bearer ${key}`,
//   //       },
//   //     };

//   //     await axios.put(`${BASE_URL}/api/booktables/${selectedBook.id}`, updatedBook, config);
//   //     console.log("Book updated successfully");
//   //     fetchBooks();
//   //   } catch (error) {
//   //     console.error('Error updating book:', error);
//   //   }
//   // };
// const handleUpdate = async () => {
//   try {
//     const key = localStorage.getItem('jwtToken');
//     if (!key) {
//       console.error('No JWT token found');
//       return;
//     }

//     const updatedBook = {
//       quantity: updatedQuantity,  // Only update quantity, not price
//     };

//     // Making PUT request with query params for bookId and bookQuantity
//     const response = await axios.put(
//       `${BASE_URL}/dashboard/update-book-quantity?bookId=${selectedBook.id}&bookQuantity=${updatedQuantity}`,
//       updatedBook, {
//         headers: {
//           'Authorization': `Bearer ${key}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     if (response.status === 200) {
//       console.log("Book quantity updated successfully");
//       fetchBooks();  // Fetch the books again to refresh the list
//     } else {
//       console.error('Error updating book quantity:', response.status);
//     }
//   } catch (error) {
//     console.error('Error updating book:', error);
//   }
// };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setNewBookData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setNewBookData((prevData) => ({
//       ...prevData,
//       image: file,
//     }));
//   };

//   const handleAddBookSubmit = async () => {
//     try {
//       const key = localStorage.getItem('jwtToken');
//       const headers = {
//         'Auth': `Bearer ${key}`,
//         'Content-Type': 'multipart/form-data',
//       };

//       const formData = new FormData();
//       for (const key in newBookData) {
//         formData.append(key, newBookData[key]);
//       }

//       if (newBookData.image.type !== 'image/png') {
//         setErrorMessage('Invalid image file type. Only PNG images are allowed.');
//         return;
//       }

//       const response = await axios.post(`${BASE_URL}/dashboard/add`, formData, { headers });

//       if (response.status === 201) {
//         console.log("New book added");
//         fetchBooks();
//       } else {
//         console.error('Error adding new book. Unexpected response status:', response.status);
//       }
//     } catch (error) {
//       console.error('Error adding new book:', error);
//     }

//     if (errorMessage) {
//       setErrorMessage('');
//     }
//   };

//   const filteredBooks = books.books ? books.books.filter((book) =>
//     book && book.title && book.title.toLowerCase().includes(searchQuery.toLowerCase())
//   ) : [];

//   return (
//     <div className="book-page">
//       {/* <RedirectComponent /> */}
//       < Header />
//       <h2 style={{ textAlign: "center" }}>Book Details</h2>
//       <input
//         type="text"
//         placeholder="Search by title"
//         value={searchQuery}
//         onChange={(e) => setSearchQuery(e.target.value)}
//         className="book-input"
//       />
//       <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
//         {/* <Link to="/orderbook" style={{ textDecoration: "none" }}>
//           <button className="book-button">Ordered Books</button>
//         </Link> */}
//         <button className="book-button" onClick={() => setShowAddBookPopup(true)}>Add New Book</button>
//       </div>
//       <div className="book-container">
//         {filteredBooks.map((book, index) => (
//           <div key={book.id} className="book-card">
//             <div className="book-details">
//               <div className="book-title" onClick={() => toggleExpandedTitle(book.id)}>{expandedTitle[book.id] ? book.title : book.title.substring(0, 20)}</div>
//               <div className="book-image">
//                 <img
//                   src={`${BASE_URL}${book.imageUrl}`}
//                   alt={book.title}
//                   className="book-image"
//                 />
//               </div>
//               <p>Author: {book.author}</p>
//               <p>Year: {book.year}</p>
//               <p>Quantity: {book.quantity}</p>
//               <p>Price: ₹ {book.price}</p>
//               <p>Ratings: {parseFloat(book.ratings).toFixed(1)} <span>&#9733;</span></p>
//               <button className="book-button" onClick={() => handleUpdateClick(book)}>Update</button>
//               {/* {selectedBook && selectedBook.id === book.id && (
//                 <div className="book-update-form">
//                   <input
//                     type="number"
//                     placeholder="Enter new quantity"
//                     value={updatedQuantity}
//                     onChange={(e) => setUpdatedQuantity(e.target.value)}
//                     className="book-update-input"
//                   />
//                   <input
//                     type="number"
//                     placeholder="Enter new price"
//                     value={updatedPrice}
//                     onChange={(e) => setUpdatedPrice(e.target.value)}
//                     className="book-update-input"
//                   />
//                   <button className="book-button" onClick={handleUpdate}>Save Changes</button>
//                 </div>
//               )} */}
//               {selectedBook && selectedBook.id === book.id && (
//   <div className="book-update-form">
//     <input
//       type="number"
//       placeholder="Enter new quantity"
//       value={updatedQuantity}
//       onChange={(e) => setUpdatedQuantity(e.target.value)}
//       className="book-update-input"
//     />
//     <button className="book-button" onClick={handleUpdate}>Save Changes</button>
//   </div>
// )}

//             </div>
//           </div>
//         ))}
//       </div>
      // {showAddBookPopup && (
      //   <div className="book-popup">
      //     <div className="book-popup-content">
      //       <h2 className="book-popup-header">Add New Book</h2>
      //       {errorMessage && (
      //         <div className="book-error">{errorMessage}</div>
      //       )}
      //       <form className="book-form">
      //         <div className="book-form-group">
      //           <label>Title:</label>
      //           <input type="text" name="title" value={newBookData.title} onChange={handleInputChange} className="book-input" />
      //         </div>
      //         <div className="book-form-group">
      //           <label>Author:</label>
      //           <input type="text" name="author" value={newBookData.author} onChange={handleInputChange} className="book-input" />
      //         </div>
      //         <div className="book-form-group">
      //           <label>Quantity:</label>
      //           <input type="number" name="quantity" value={newBookData.quantity} onChange={handleInputChange} className="book-input" />
      //         </div>
      //         <div className="book-form-group">
      //           <label>Year:</label>
      //           <input type="text" name="year" value={newBookData.year} onChange={handleInputChange} className="book-input" />
      //         </div>
      //         <div className="book-form-group">
      //           <label>Price:</label>
      //           <input type="text" name="price" value={newBookData.price} onChange={handleInputChange} className="book-input" />
      //         </div>
      //         <div className="book-form-group">
      //           <label>Image:</label>
      //           <input type="file" name="image" accept="image/*" onChange={handleFileChange} className="book-input" />
      //         </div>
      //         <div className="book-form-buttons">
      //           <button className="book-button" type="button" onClick={handleAddBookSubmit}>Add Book</button>
      //           <button className="book-button" type="button" onClick={() => setShowAddBookPopup(false)}>Cancel</button>
      //         </div>
      //       </form>
      //     </div>
      //   </div>
      // )}
//       <Footer />
//     </div>
//   );
// };

// export default Book;












import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import BASE_URL from '../../components/config/apiConfig';
import 'font-awesome/css/font-awesome.min.css';
import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
import "./bookdata.scss";
import Header from '../bookinfo/header';  // Import Header
import Footer from '../bookinfo/footer'; 

const Book = () => {
  const [books, setBooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [updatedQuantity, setUpdatedQuantity] = useState('');
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
const [showAddBookPopup, setShowAddBookPopup] = useState(false);

  useEffect(() => {
    fetchBooks();
  }, []);

  const fetchBooks = async () => {
    try {
      const key = localStorage.getItem('jwtToken');
      const response = await axios.get(`${BASE_URL}/dashboard/get-all-book-admin`, {
        headers: {
          'Content-Type': 'application/json',
          'Auth': `Bearer ${key}`,
        },
      });
      setBooks(response.data);
    } catch (error) {
      console.error('Error fetching book details:', error);
    }
  };

  const handleUpdateClick = (book) => {
    setSelectedBook(book);
    setUpdatedQuantity(book.quantity);
    setShowUpdatePopup(true);
  };

  const handleUpdate = async () => {
    try {
      const key = localStorage.getItem('jwtToken');
      if (!key) {
        console.error('No JWT token found');
        return;
      }

      const updatedBook = {
        quantity: updatedQuantity,  // Only update quantity, not price
      };

      const response = await axios.put(
        `${BASE_URL}/dashboard/update-book-quantity?bookId=${selectedBook.id}&bookQuantity=${updatedQuantity}`,
        updatedBook, {
          headers: {
            'Authorization': `Bearer ${key}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        console.log("Book quantity updated successfully");
        fetchBooks();  // Fetch the books again to refresh the list
        setShowUpdatePopup(false);  // Close the popup
      } else {
        console.error('Error updating book quantity:', response.status);
      }
    } catch (error) {
      console.error('Error updating book:', error);
    }
  };

  const filteredBooks = books.books ? books.books.filter((book) =>
    book && book.title && book.title.toLowerCase().includes(searchQuery.toLowerCase())
  ) : [];

  return (
//     <div className="book-page">
//        <RedirectComponent />
//       <Header />
//       <h2 style={{ textAlign: "center" }}>Book Details</h2>
//       <input
//         type="text"
//         placeholder="Search by title"
//         value={searchQuery}
//         onChange={(e) => setSearchQuery(e.target.value)}
//         className="book-input"
//       />
//       {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
//         <button className="book-button" onClick={() => setShowAddBookPopup(true)}>Add New Book</button>
//       </div> */}
//       <div className="book-container">
//         {filteredBooks.map((book) => (
//           <div key={book.id} className="book-card">
//             <div className="book-details">
//               <div className="book-title">{book.title}</div>
//               <div className="book-image">
//                 <img
//                   src={`${BASE_URL}${book.imageUrl}`}
//                   alt={book.title}
//                   className="book-image"
//                 />
//               </div>
//               <p>Author: {book.author}</p>
//               <p>Year: {book.year}</p>
//               <p>Quantity: {book.quantity}</p>
//               <p>Price: ₹ {book.price}</p>
//               <p>Ratings: {parseFloat(book.ratings).toFixed(1)} <span>&#9733;</span></p>
//               <button className="book-button" onClick={() => handleUpdateClick(book)}>Update Quantity</button>
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Update Book Popup */}
//       {/* {showUpdatePopup && selectedBook && (
//         <div className="book-popup">
//           <div className="book-popup-content">
//             <h2 className="book-popup-header">Update Quantity</h2>
//             {errorMessage && <div className="book-error">{errorMessage}</div>}
//             <div className="book-update-form">
//               <input
//                 type="number"
//                 placeholder="Enter new quantity"
//                 value={updatedQuantity}
//                 onChange={(e) => setUpdatedQuantity(e.target.value)}
//                 className="book-update-input"
//               />
//               <button className="book-button" onClick={handleUpdate}>Save</button>
//               <button className="book-button" onClick={() => setShowUpdatePopup(false)}>Cancel</button>
//             </div>
//           </div>
//         </div>
//       )} */}
//       {showUpdatePopup && selectedBook && (
//   <div className="book-popup">
//     <div className="book-popup-content">
//  <button className="close-button" onClick={() => setShowUpdatePopup(false)}>
//   <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
//     <line x1="18" y1="6" x2="6" y2="18" />
//     <line x1="6" y1="6" x2="18" y2="18" />
//   </svg> {/* Custom X SVG */}
// </button>



//       <h2 className="book-popup-header">Update Quantity</h2>
      
//       {errorMessage && <div className="book-error">{errorMessage}</div>}

//       <div className="book-update-form">
//         <input
//           type="number"
//           placeholder="Enter new quantity"
//           value={updatedQuantity}
//           onChange={(e) => setUpdatedQuantity(e.target.value)}
//           className="book-update-input"
//         />
        
//         <button className="book-button" onClick={handleUpdate}>Save</button>
//       </div>
//     </div>
//   </div>
// )}


//       <Footer />
//     </div>

<div className="book-page">
  <RedirectComponent />
  <Header />
  
  <h2 style={{ textAlign: "center" }}>Book Details</h2>
  
  <input
    type="text"
    placeholder="Search by title"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    className="book-input"
  />
  
  <div className="book-container">
    {filteredBooks.map((book) => (
      <div key={book.id} className="book-card">
        <div className="book-details">
          <div className="book-title">{book.title}</div>
          <div className="book-image">
            <img
              src={`${BASE_URL}${book.imageUrl}`}
              alt={book.title}
              className="book-image"
            />
          </div>
          <p>Author: {book.author}</p>
          <p>Year: {book.year}</p>
          <p>Quantity: {book.quantity}</p>
          <p>Price: ₹ {book.price}</p>
          <p>Ratings: {parseFloat(book.ratings).toFixed(1)} <span>&#9733;</span></p>
          <button className="book-button" onClick={() => handleUpdateClick(book)}>Update Quantity</button>
        </div>
      </div>
    ))}
  </div>

  {/* Update Book Popup */}
  {showUpdatePopup && selectedBook && (
    <div className="book-popup">
      <div className="book-popup-content">
        <button className="close-button" onClick={() => setShowUpdatePopup(false)}>
          <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </button>

        <h2 className="book-popup-header">Update Quantity</h2>
        
        {errorMessage && <div className="book-error">{errorMessage}</div>}

        <div className="book-update-form">
          <input
            type="number"
            placeholder="Enter new quantity"
            value={updatedQuantity}
            onChange={(e) => setUpdatedQuantity(e.target.value)}
            className="book-update-input"
          />
          <button className="book-button" onClick={handleUpdate}>Save</button>
        </div>
      </div>
    </div>
  )}

  <Footer />
</div>

  );
};

export default Book;
