// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // import axios from "axios";

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.get("https://api.example.com/get-books"); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       setTableData(response.data); // Assuming response.data is an array of book data
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       setLoading(false);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       console.error("Error fetching table data:", error);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       setLoading(false);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       alert(`Data for ${row.user} saved successfully!`);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.user}.`);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     <div style={styles.container}>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       <h1 style={styles.heading}>Book Orders</h1>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       {loading ? (
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //         <p>Loading...</p>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       ) : (
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //         <table style={styles.table}>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //           <thead>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //             <tr>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>User</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Name</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Publication Title</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Contact Number</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Email ID</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Payment Status</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Action</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //             </tr>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //           </thead>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //           <tbody>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //             {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <tr key={index}>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.user}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.name}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.email}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.shippingAddress}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <button
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                     style={styles.submitButton}
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                     onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                   >
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                     Submit
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                   </button>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 </td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //               </tr>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //             ))}
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //           </tbody>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //         </table>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       )}
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // const styles = {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   container: {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     padding: "20px",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     fontFamily: "Arial, sans-serif",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   heading: {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     textAlign: "center",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     color: "#324e6a",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     marginBottom: "20px",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   table: {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     width: "100%",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     borderCollapse: "collapse",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     marginBottom: "20px",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   th: {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     backgroundColor: "#7CB2D0",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     color: "white",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     padding: "10px",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     border: "1px solid #ccc",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     textAlign: "left",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   td: {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     padding: "10px",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     border: "1px solid #ccc",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     textAlign: "left",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   submitButton: {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     padding: "5px 10px",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     backgroundColor: "#324e6a",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     color: "white",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     border: "none",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     borderRadius: "5px",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     cursor: "pointer",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);

// // // // // // // // // // // // // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.get("https://api.example.com/get-books"); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       setTableData(response.data); // Assuming response.data is an array of book data
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       setLoading(false);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       console.error("Error fetching table data:", error);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       setLoading(false);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       alert(`Data for ${row.user} saved successfully!`);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.user}.`);
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //     <div className="table-container">
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       <h1 className="heading">Book Orders</h1>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       {loading ? (
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //         <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       ) : (
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //         <table className="book-table">
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //           <thead>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //             <tr>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>User</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Name</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Publication Title</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Contact Number</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Email ID</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Payment Status</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <th>Action</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //             </tr>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //           </thead>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //           <tbody>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //             {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               <tr key={index}>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.user}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.name}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.email}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.shippingAddress}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <button
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                     className="submit-btn"
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                     onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                   >
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                     Submit
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                   </button>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //                 </td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //               </tr>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //             ))}
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //           </tbody>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //         </table>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //       )}
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design

// // // // // // // // // // // // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);

// // // // // // // // // // // // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         user: "John Doe",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         name: "The Great Gatsby",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK123456"
// // // // // // // // // // // // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         user: "Jane Smith",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         name: "To Kill a Mockingbird",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK654321"
// // // // // // // // // // // // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // // // // // // // // // // // //       alert(`Data for ${row.user} saved successfully!`);
// // // // // // // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.user}.`);
// // // // // // // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // // // // // // // //     <div className="table-container">
// // // // // // // // // // // // // // // // // // // // // // // // // // // //       <h1 className="heading">Book Orders</h1>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //       {loading ? (
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //       ) : (
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         <div className="table-wrapper">
// // // // // // // // // // // // // // // // // // // // // // // // // // // //           <table className="book-table">
// // // // // // // // // // // // // // // // // // // // // // // // // // // //             <thead>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //               <tr>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>User</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Name</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Publication Title</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Contact Number</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Email ID</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Payment Status</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Action</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //               </tr>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //             </thead>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //             <tbody>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //               {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 <tr key={index}>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.user}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.name}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.email}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.shippingAddress}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                     <button
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                       className="submit-btn"
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                       Submit
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                     </button>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // // // // // // // // // // // // // // // //             </tbody>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //           </table>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //       )}
// // // // // // // // // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design

// // // // // // // // // // // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);

// // // // // // // // // // // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // // // // // // //         username: "JohnDoe123", // Username only
// // // // // // // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK123456"
// // // // // // // // // // // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // // // // // // //         username: "JaneSmith456", // Username only
// // // // // // // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK654321"
// // // // // // // // // // // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // // // // // // //     <div className="table-container">
// // // // // // // // // // // // // // // // // // // // // // // // // // //       <h1 className="heading">Book Orders</h1>
// // // // // // // // // // // // // // // // // // // // // // // // // // //       {loading ? (
// // // // // // // // // // // // // // // // // // // // // // // // // // //         <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // // // // // // // // // // // //       ) : (
// // // // // // // // // // // // // // // // // // // // // // // // // // //         <div className="table-wrapper">
// // // // // // // // // // // // // // // // // // // // // // // // // // //           <table className="book-table">
// // // // // // // // // // // // // // // // // // // // // // // // // // //             <thead>
// // // // // // // // // // // // // // // // // // // // // // // // // // //               <tr>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>User Name</th> {/* Column name updated */}
// // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Publication Title</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Contact Number</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Email ID</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Payment Status</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Action</th>
// // // // // // // // // // // // // // // // // // // // // // // // // // //               </tr>
// // // // // // // // // // // // // // // // // // // // // // // // // // //             </thead>
// // // // // // // // // // // // // // // // // // // // // // // // // // //             <tbody>
// // // // // // // // // // // // // // // // // // // // // // // // // // //               {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // // // // // // // // // // //                 <tr key={index}>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.username}</td> {/* Only the username displayed here */}
// // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.email}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.shippingAddress}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                     <button
// // // // // // // // // // // // // // // // // // // // // // // // // // //                       className="submit-btn"
// // // // // // // // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // // // // // // // //                       Submit
// // // // // // // // // // // // // // // // // // // // // // // // // // //                     </button>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // // // // // // // // // // // // // // //             </tbody>
// // // // // // // // // // // // // // // // // // // // // // // // // // //           </table>
// // // // // // // // // // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // // // // // // // // // //       )}
// // // // // // // // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design

// // // // // // // // // // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // // // // // // // // // // // // // //   const [modalData, setModalData] = useState(null);

// // // // // // // // // // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // // // // // //         username: "JohnDoe123",
// // // // // // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK123456"
// // // // // // // // // // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // // // // // //         username: "JaneSmith456",
// // // // // // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK654321"
// // // // // // // // // // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // // //   const handleClick = (data) => {
// // // // // // // // // // // // // // // // // // // // // // // // // //     setModalData(data); // Show the modal with full data
// // // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // // //   const handleCloseModal = () => {
// // // // // // // // // // // // // // // // // // // // // // // // // //     setModalData(null); // Close the modal
// // // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // // // // // //     <div className="table-container">
// // // // // // // // // // // // // // // // // // // // // // // // // //       <h1 className="heading">Book Orders</h1>
// // // // // // // // // // // // // // // // // // // // // // // // // //       {loading ? (
// // // // // // // // // // // // // // // // // // // // // // // // // //         <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // // // // // // // // // // //       ) : (
// // // // // // // // // // // // // // // // // // // // // // // // // //         <div className="table-wrapper">
// // // // // // // // // // // // // // // // // // // // // // // // // //           <table className="book-table">
// // // // // // // // // // // // // // // // // // // // // // // // // //             <thead>
// // // // // // // // // // // // // // // // // // // // // // // // // //               <tr>
// // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>User Name</th>
// // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Publication Title</th>
// // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Contact Number</th>
// // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Email ID</th>
// // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Payment Status</th>
// // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Action</th>
// // // // // // // // // // // // // // // // // // // // // // // // // //               </tr>
// // // // // // // // // // // // // // // // // // // // // // // // // //             </thead>
// // // // // // // // // // // // // // // // // // // // // // // // // //             <tbody>
// // // // // // // // // // // // // // // // // // // // // // // // // //               {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // // // // // // // // // //                 <tr key={index}>
// // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // // // // // //                     <div className="tooltip" onClick={() => handleClick(row.username)}>
// // // // // // // // // // // // // // // // // // // // // // // // // //                       {row.username}
// // // // // // // // // // // // // // // // // // // // // // // // // //                       <span className="tooltiptext">{row.username}</span>
// // // // // // // // // // // // // // // // // // // // // // // // // //                     </div>
// // // // // // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.email}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.shippingAddress}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // // // // // //                     <button className="submit-btn" onClick={() => handleSubmit(row)}>
// // // // // // // // // // // // // // // // // // // // // // // // // //                       Submit
// // // // // // // // // // // // // // // // // // // // // // // // // //                     </button>
// // // // // // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // // // // // // // // // // // // // //             </tbody>
// // // // // // // // // // // // // // // // // // // // // // // // // //           </table>
// // // // // // // // // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // // // // // // // // //       )}

// // // // // // // // // // // // // // // // // // // // // // // // // //       {/* Modal for displaying full content */}
// // // // // // // // // // // // // // // // // // // // // // // // // //       {modalData && (
// // // // // // // // // // // // // // // // // // // // // // // // // //         <div className="modal">
// // // // // // // // // // // // // // // // // // // // // // // // // //           <div className="modal-content">
// // // // // // // // // // // // // // // // // // // // // // // // // //             <span className="close" onClick={handleCloseModal}>
// // // // // // // // // // // // // // // // // // // // // // // // // //               &times;
// // // // // // // // // // // // // // // // // // // // // // // // // //             </span>
// // // // // // // // // // // // // // // // // // // // // // // // // //             <h2>Details</h2>
// // // // // // // // // // // // // // // // // // // // // // // // // //             <p><strong>Username:</strong> {modalData}</p>
// // // // // // // // // // // // // // // // // // // // // // // // // //             {/* Add other fields you want to display here */}
// // // // // // // // // // // // // // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // // // // // // // // //       )}
// // // // // // // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design

// // // // // // // // // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // // // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility

// // // // // // // // // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // // // // //         username: "JohnDoe123", // Username only
// // // // // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK123456"
// // // // // // // // // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // // // // //         username: "JaneSmith456", // Username only
// // // // // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK654321"
// // // // // // // // // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // // // // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // // // // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // // // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // // // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // // // // //     <div className="table-container">
// // // // // // // // // // // // // // // // // // // // // // // // //       <h1 className="heading">Book Orders</h1>
// // // // // // // // // // // // // // // // // // // // // // // // //       {loading ? (
// // // // // // // // // // // // // // // // // // // // // // // // //         <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // // // // // // // // // //       ) : (
// // // // // // // // // // // // // // // // // // // // // // // // //         <div className="table-wrapper">
// // // // // // // // // // // // // // // // // // // // // // // // //           <table className="book-table">
// // // // // // // // // // // // // // // // // // // // // // // // //             <thead>
// // // // // // // // // // // // // // // // // // // // // // // // //               <tr>
// // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // // // // // // // // //                 <th>User Name</th> {/* Column name updated */}
// // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Publication Title</th>
// // // // // // // // // // // // // // // // // // // // // // // // //                 <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Contact Number</th>
// // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Email ID</th>
// // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Payment Status</th>
// // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // // // // // // // // //                 <th>Action</th>
// // // // // // // // // // // // // // // // // // // // // // // // //               </tr>
// // // // // // // // // // // // // // // // // // // // // // // // //             </thead>
// // // // // // // // // // // // // // // // // // // // // // // // //             <tbody>
// // // // // // // // // // // // // // // // // // // // // // // // //               {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // // // // // // // // //                 <tr key={index}>
// // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // // // // //                     {row.username.length > 15 ? (
// // // // // // // // // // // // // // // // // // // // // // // // //                       <>
// // // // // // // // // // // // // // // // // // // // // // // // //                         {row.username.substring(0, 15)}...{" "}
// // // // // // // // // // // // // // // // // // // // // // // // //                         <span
// // // // // // // // // // // // // // // // // // // // // // // // //                           className="show-more"
// // // // // // // // // // // // // // // // // // // // // // // // //                           onClick={() => handleShowPopup("Username", row.username)}
// // // // // // // // // // // // // // // // // // // // // // // // //                         >
// // // // // // // // // // // // // // // // // // // // // // // // //                           Show More
// // // // // // // // // // // // // // // // // // // // // // // // //                         </span>
// // // // // // // // // // // // // // // // // // // // // // // // //                       </>
// // // // // // // // // // // // // // // // // // // // // // // // //                     ) : (
// // // // // // // // // // // // // // // // // // // // // // // // //                       row.username
// // // // // // // // // // // // // // // // // // // // // // // // //                     )}
// // // // // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.email}</td>
// // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.shippingAddress}</td>
// // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // // // // //                     <button
// // // // // // // // // // // // // // // // // // // // // // // // //                       className="submit-btn"
// // // // // // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // // // // // //                       Submit
// // // // // // // // // // // // // // // // // // // // // // // // //                     </button>
// // // // // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // // // // // // // // // // // // //             </tbody>
// // // // // // // // // // // // // // // // // // // // // // // // //           </table>
// // // // // // // // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // // // // // // // //       )}

// // // // // // // // // // // // // // // // // // // // // // // // //       {popupVisible && (
// // // // // // // // // // // // // // // // // // // // // // // // //         <>
// // // // // // // // // // // // // // // // // // // // // // // // //           <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // // // // // // // // // // // // // //           <div className="popup">
// // // // // // // // // // // // // // // // // // // // // // // // //             <h3>Details</h3>
// // // // // // // // // // // // // // // // // // // // // // // // //             <p>{popupContent}</p>
// // // // // // // // // // // // // // // // // // // // // // // // //             <button className="submit-btn" onClick={closePopup}>
// // // // // // // // // // // // // // // // // // // // // // // // //               Close
// // // // // // // // // // // // // // // // // // // // // // // // //             </button>
// // // // // // // // // // // // // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // // // // // // // // // // // // //         </>
// // // // // // // // // // // // // // // // // // // // // // // // //       )}
// // // // // // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design

// // // // // // // // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility

// // // // // // // // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // // // //         username: "JohnDoe123", // Username only
// // // // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK123456"
// // // // // // // // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // // // //         username: "JaneSmith456", // Username only
// // // // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK654321"
// // // // // // // // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // // // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // // // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // // // //     <div className="table-container">
// // // // // // // // // // // // // // // // // // // // // // // //       <h1 className="heading">Book Orders</h1>
// // // // // // // // // // // // // // // // // // // // // // // //       {loading ? (
// // // // // // // // // // // // // // // // // // // // // // // //         <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // // // // // // // // //       ) : (
// // // // // // // // // // // // // // // // // // // // // // // //         <div className="table-wrapper">
// // // // // // // // // // // // // // // // // // // // // // // //           <table className="book-table">
// // // // // // // // // // // // // // // // // // // // // // // //             <thead>
// // // // // // // // // // // // // // // // // // // // // // // //               <tr>
// // // // // // // // // // // // // // // // // // // // // // // //                 <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // // // // // // // //                 <th>User Name</th> {/* Column name updated */}
// // // // // // // // // // // // // // // // // // // // // // // //                 <th>Publication Title</th>
// // // // // // // // // // // // // // // // // // // // // // // //                 <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // // // // // // // //                 <th>Contact Number</th>
// // // // // // // // // // // // // // // // // // // // // // // //                 <th>Email ID</th>
// // // // // // // // // // // // // // // // // // // // // // // //                 <th>Payment Status</th>
// // // // // // // // // // // // // // // // // // // // // // // //                 <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // // // // // // // //                 <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // // // // // // // //                 <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // // // // // // // //                 <th>Action</th>
// // // // // // // // // // // // // // // // // // // // // // // //               </tr>
// // // // // // // // // // // // // // // // // // // // // // // //             </thead>
// // // // // // // // // // // // // // // // // // // // // // // //             <tbody>
// // // // // // // // // // // // // // // // // // // // // // // //               {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // // // // // // // //                 <tr key={index}>
// // // // // // // // // // // // // // // // // // // // // // // //                   <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // // // //                     {row.username.length > 15 ? (
// // // // // // // // // // // // // // // // // // // // // // // //                       <>
// // // // // // // // // // // // // // // // // // // // // // // //                         {row.username.substring(0, 15)}...{" "}
// // // // // // // // // // // // // // // // // // // // // // // //                         <span
// // // // // // // // // // // // // // // // // // // // // // // //                           className="show-more"
// // // // // // // // // // // // // // // // // // // // // // // //                           onClick={() => handleShowPopup("Username", row.username)}
// // // // // // // // // // // // // // // // // // // // // // // //                         >
// // // // // // // // // // // // // // // // // // // // // // // //                           Show More
// // // // // // // // // // // // // // // // // // // // // // // //                         </span>
// // // // // // // // // // // // // // // // // // // // // // // //                       </>
// // // // // // // // // // // // // // // // // // // // // // // //                     ) : (
// // // // // // // // // // // // // // // // // // // // // // // //                       row.username
// // // // // // // // // // // // // // // // // // // // // // // //                     )}
// // // // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.email}</td>
// // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.shippingAddress}</td>
// // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // // // //                     <button
// // // // // // // // // // // // // // // // // // // // // // // //                       className="submit-btn"
// // // // // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // // // // //                       Submit
// // // // // // // // // // // // // // // // // // // // // // // //                     </button>
// // // // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // // // // // // // // // // // //             </tbody>
// // // // // // // // // // // // // // // // // // // // // // // //           </table>
// // // // // // // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // // // // // // //       )}

// // // // // // // // // // // // // // // // // // // // // // // //       {popupVisible && (
// // // // // // // // // // // // // // // // // // // // // // // //         <>
// // // // // // // // // // // // // // // // // // // // // // // //           <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // // // // // // // // // // // // //           <div className="popup show">
// // // // // // // // // // // // // // // // // // // // // // // //             <h3>Details</h3>
// // // // // // // // // // // // // // // // // // // // // // // //             <p>{popupContent}</p>
// // // // // // // // // // // // // // // // // // // // // // // //             <button className="submit-btn" onClick={closePopup}>
// // // // // // // // // // // // // // // // // // // // // // // //               Close
// // // // // // // // // // // // // // // // // // // // // // // //             </button>
// // // // // // // // // // // // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // // // // // // // // // // // //         </>
// // // // // // // // // // // // // // // // // // // // // // // //       )}
// // // // // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design

// // // // // // // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility

// // // // // // // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // // //         username: "JohnDoe123", // Username only
// // // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK123456"
// // // // // // // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // // //         username: "JaneSmith456", // Username only
// // // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK654321"
// // // // // // // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // // //     <div className="table-container">
// // // // // // // // // // // // // // // // // // // // // // //       <h1 className="heading">Book Orders</h1>
// // // // // // // // // // // // // // // // // // // // // // //       {loading ? (
// // // // // // // // // // // // // // // // // // // // // // //         <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // // // // // // // //       ) : (
// // // // // // // // // // // // // // // // // // // // // // //         <div className="table-wrapper">
// // // // // // // // // // // // // // // // // // // // // // //           <table className="book-table">
// // // // // // // // // // // // // // // // // // // // // // //             <thead>
// // // // // // // // // // // // // // // // // // // // // // //               <tr>
// // // // // // // // // // // // // // // // // // // // // // //                 <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // // // // // // //                 <th>User Name</th> {/* Column name updated */}
// // // // // // // // // // // // // // // // // // // // // // //                 <th>Publication Title</th>
// // // // // // // // // // // // // // // // // // // // // // //                 <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // // // // // // //                 <th>Contact Number</th>
// // // // // // // // // // // // // // // // // // // // // // //                 <th>Email ID</th>
// // // // // // // // // // // // // // // // // // // // // // //                 <th>Payment Status</th>
// // // // // // // // // // // // // // // // // // // // // // //                 <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // // // // // // //                 <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // // // // // // //                 <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // // // // // // //                 <th>Action</th>
// // // // // // // // // // // // // // // // // // // // // // //               </tr>
// // // // // // // // // // // // // // // // // // // // // // //             </thead>
// // // // // // // // // // // // // // // // // // // // // // //             <tbody>
// // // // // // // // // // // // // // // // // // // // // // //               {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // // // // // // //                 <tr key={index}>
// // // // // // // // // // // // // // // // // // // // // // //                   <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // // //                     {/* When username is truncated, click on it to open popup */}
// // // // // // // // // // // // // // // // // // // // // // //                     <span
// // // // // // // // // // // // // // // // // // // // // // //                       className="show-more"
// // // // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleShowPopup("Username", row.username)}
// // // // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // // // //                       {row.username.length > 15
// // // // // // // // // // // // // // // // // // // // // // //                         ? row.username.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // // // // // //                         : row.username}
// // // // // // // // // // // // // // // // // // // // // // //                     </span>
// // // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.email}</td>
// // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.shippingAddress}</td>
// // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // // // // // // //                   <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // // //                     <button
// // // // // // // // // // // // // // // // // // // // // // //                       className="submit-btn"
// // // // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // // // //                       Submit
// // // // // // // // // // // // // // // // // // // // // // //                     </button>
// // // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // // // // // // // // // // //             </tbody>
// // // // // // // // // // // // // // // // // // // // // // //           </table>
// // // // // // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // // // // // //       )}

// // // // // // // // // // // // // // // // // // // // // // //       {popupVisible && (
// // // // // // // // // // // // // // // // // // // // // // //         <>
// // // // // // // // // // // // // // // // // // // // // // //           <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // // // // // // // // // // // //           <div className="popup show">
// // // // // // // // // // // // // // // // // // // // // // //             <h3>Details</h3>
// // // // // // // // // // // // // // // // // // // // // // //             <p>{popupContent}</p>
// // // // // // // // // // // // // // // // // // // // // // //             <button className="submit-btn" onClick={closePopup}>
// // // // // // // // // // // // // // // // // // // // // // //               Close
// // // // // // // // // // // // // // // // // // // // // // //             </button>
// // // // // // // // // // // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // // // // // // // // // // //         </>
// // // // // // // // // // // // // // // // // // // // // // //       )}
// // // // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design

// // // // // // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility

// // // // // // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // //         username: "JohnDoe123", // Username only
// // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK123456"
// // // // // // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // // //         username: "JaneSmith456", // Username only
// // // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK654321"
// // // // // // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // //     <div className="table-container">
// // // // // // // // // // // // // // // // // // // // // //       <h1 className="heading">Book Orders</h1>
// // // // // // // // // // // // // // // // // // // // // //       {loading ? (
// // // // // // // // // // // // // // // // // // // // // //         <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // // // // // // //       ) : (
// // // // // // // // // // // // // // // // // // // // // //         <div className="table-wrapper">
// // // // // // // // // // // // // // // // // // // // // //           <table className="book-table">
// // // // // // // // // // // // // // // // // // // // // //             <thead>
// // // // // // // // // // // // // // // // // // // // // //               <tr>
// // // // // // // // // // // // // // // // // // // // // //                 <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // // // // // //                 <th>User Name</th>
// // // // // // // // // // // // // // // // // // // // // //                 <th>Publication Title</th>
// // // // // // // // // // // // // // // // // // // // // //                 <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // // // // // //                 <th>Contact Number</th>
// // // // // // // // // // // // // // // // // // // // // //                 <th>Email ID</th>
// // // // // // // // // // // // // // // // // // // // // //                 <th>Payment Status</th>
// // // // // // // // // // // // // // // // // // // // // //                 <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // // // // // //                 <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // // // // // //                 <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // // // // // //                 <th>Action</th>
// // // // // // // // // // // // // // // // // // // // // //               </tr>
// // // // // // // // // // // // // // // // // // // // // //             </thead>
// // // // // // // // // // // // // // // // // // // // // //             <tbody>
// // // // // // // // // // // // // // // // // // // // // //               {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // // // // // //                 <tr key={index}>
// // // // // // // // // // // // // // // // // // // // // //                   <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // //                     <span
// // // // // // // // // // // // // // // // // // // // // //                       className="show-more"
// // // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleShowPopup("Username", row.username)}
// // // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // // //                       {row.username.length > 15
// // // // // // // // // // // // // // // // // // // // // //                         ? row.username.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // // // // //                         : row.username}
// // // // // // // // // // // // // // // // // // // // // //                     </span>
// // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // //                   <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // // // // // //                   <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // // // // // //                   <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // //                     {/* Clickable email */}
// // // // // // // // // // // // // // // // // // // // // //                     <span
// // // // // // // // // // // // // // // // // // // // // //                       className="show-more"
// // // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleShowPopup("Email", row.email)}
// // // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // // //                       {row.email.length > 15
// // // // // // // // // // // // // // // // // // // // // //                         ? row.email.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // // // // //                         : row.email}
// // // // // // // // // // // // // // // // // // // // // //                     </span>
// // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // //                   <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // //                     {/* Clickable shipping address */}
// // // // // // // // // // // // // // // // // // // // // //                     <span
// // // // // // // // // // // // // // // // // // // // // //                       className="show-more"
// // // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleShowPopup("Shipping Address", row.shippingAddress)}
// // // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // // //                       {row.shippingAddress.length > 15
// // // // // // // // // // // // // // // // // // // // // //                         ? row.shippingAddress.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // // // // //                         : row.shippingAddress}
// // // // // // // // // // // // // // // // // // // // // //                     </span>
// // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // //                   <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // // // // // //                   <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // // //                     <button
// // // // // // // // // // // // // // // // // // // // // //                       className="submit-btn"
// // // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // // //                       Submit
// // // // // // // // // // // // // // // // // // // // // //                     </button>
// // // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // // // // // // // // // //             </tbody>
// // // // // // // // // // // // // // // // // // // // // //           </table>
// // // // // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // // // // //       )}

// // // // // // // // // // // // // // // // // // // // // //       {popupVisible && (
// // // // // // // // // // // // // // // // // // // // // //         <>
// // // // // // // // // // // // // // // // // // // // // //           <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // // // // // // // // // // //           <div className="popup show">
// // // // // // // // // // // // // // // // // // // // // //             <h3>Details</h3>
// // // // // // // // // // // // // // // // // // // // // //             <p>{popupContent}</p>
// // // // // // // // // // // // // // // // // // // // // //             <button className="submit-btn" onClick={closePopup}>
// // // // // // // // // // // // // // // // // // // // // //               Close
// // // // // // // // // // // // // // // // // // // // // //             </button>
// // // // // // // // // // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // // // // // // // // // //         </>
// // // // // // // // // // // // // // // // // // // // // //       )}
// // // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // export default BookTableWithAPI;



// // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design

// // // // // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility

// // // // // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // //         username: "JohnDoe123", // Username only
// // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK123456"
// // // // // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // // //         username: "JaneSmith456", // Username only
// // // // // // // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // // // // // // //         trackingId: "TRK654321"
// // // // // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // //     <div className="table-container">
// // // // // // // // // // // // // // // // // // // // //       <h1 className="heading">Book Orders</h1>
// // // // // // // // // // // // // // // // // // // // //       {loading ? (
// // // // // // // // // // // // // // // // // // // // //         <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // // // // // //       ) : (
// // // // // // // // // // // // // // // // // // // // //         <div className="table-wrapper">
// // // // // // // // // // // // // // // // // // // // //           <table className="book-table">
// // // // // // // // // // // // // // // // // // // // //             <thead>
// // // // // // // // // // // // // // // // // // // // //               <tr>
// // // // // // // // // // // // // // // // // // // // //                 <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // // // // //                 <th>User Name</th>
// // // // // // // // // // // // // // // // // // // // //                 <th>Publication Title</th>
// // // // // // // // // // // // // // // // // // // // //                 <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // // // // //                 <th>Contact Number</th>
// // // // // // // // // // // // // // // // // // // // //                 <th>Email ID</th>
// // // // // // // // // // // // // // // // // // // // //                 <th>Payment Status</th>
// // // // // // // // // // // // // // // // // // // // //                 <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // // // // //                 <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // // // // //                 <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // // // // //                 <th>Action</th>
// // // // // // // // // // // // // // // // // // // // //               </tr>
// // // // // // // // // // // // // // // // // // // // //             </thead>
// // // // // // // // // // // // // // // // // // // // //             <tbody>
// // // // // // // // // // // // // // // // // // // // //               {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // // // // //                 <tr key={index}>
// // // // // // // // // // // // // // // // // // // // //                   <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // //                     <span
// // // // // // // // // // // // // // // // // // // // //                       className="show-more"
// // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleShowPopup("Username", row.username)}
// // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // //                       {row.username.length > 15
// // // // // // // // // // // // // // // // // // // // //                         ? row.username.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // // // //                         : row.username}
// // // // // // // // // // // // // // // // // // // // //                     </span>
// // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // //                   <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // // // // //                   <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // // // // //                   <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // //                     {/* Clickable email */}
// // // // // // // // // // // // // // // // // // // // //                     <span
// // // // // // // // // // // // // // // // // // // // //                       className="show-more"
// // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleShowPopup("Email", row.email)}
// // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // //                       {row.email.length > 15
// // // // // // // // // // // // // // // // // // // // //                         ? row.email.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // // // //                         : row.email}
// // // // // // // // // // // // // // // // // // // // //                     </span>
// // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // //                   <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // //                     {/* Clickable shipping address */}
// // // // // // // // // // // // // // // // // // // // //                     <span
// // // // // // // // // // // // // // // // // // // // //                       className="show-more"
// // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleShowPopup("Shipping Address", row.shippingAddress)}
// // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // //                       {row.shippingAddress.length > 15
// // // // // // // // // // // // // // // // // // // // //                         ? row.shippingAddress.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // // // //                         : row.shippingAddress}
// // // // // // // // // // // // // // // // // // // // //                     </span>
// // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // //                   <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // // // // //                   <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // // //                     <button
// // // // // // // // // // // // // // // // // // // // //                       className="submit-btn"
// // // // // // // // // // // // // // // // // // // // //                       onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // // //                       Submit
// // // // // // // // // // // // // // // // // // // // //                     </button>
// // // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // // // // // // // // //             </tbody>
// // // // // // // // // // // // // // // // // // // // //           </table>
// // // // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // // // //       )}

// // // // // // // // // // // // // // // // // // // // //       {popupVisible && (
// // // // // // // // // // // // // // // // // // // // //         <>
// // // // // // // // // // // // // // // // // // // // //           <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // // // // // // // // // //           <div className="popup show">
// // // // // // // // // // // // // // // // // // // // //             <p>{popupContent}</p>
// // // // // // // // // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // // // // // // // // //         </>
// // // // // // // // // // // // // // // // // // // // //       )}
// // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design

// // // // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility

// // // // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // //         username: "JohnDoe123", // Username only
// // // // // // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // // // // // //         trackingId: "TRK123456"
// // // // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // // //         username: "JaneSmith456", // Username only
// // // // // // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // // // // // //         trackingId: "TRK654321"
// // // // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // //   const handleUpdateStockInfo = () => {
// // // // // // // // // // // // // // // // // // // //     // Implement the logic for updating stock info here
// // // // // // // // // // // // // // // // // // // //     alert("Update Stock Info clicked!");
// // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // //     <div className="table-container">
// // // // // // // // // // // // // // // // // // // //       <div className="update-stock-btn-container">
// // // // // // // // // // // // // // // // // // // //         <button className="update-stock-btn" onClick={handleUpdateStockInfo}>
// // // // // // // // // // // // // // // // // // // //           Update Stock Info
// // // // // // // // // // // // // // // // // // // //         </button>
// // // // // // // // // // // // // // // // // // // //       </div>
      
// // // // // // // // // // // // // // // // // // // //       <h1 className="heading">Book Orders</h1>
// // // // // // // // // // // // // // // // // // // //       {loading ? (
// // // // // // // // // // // // // // // // // // // //         <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // // // // //       ) : (
// // // // // // // // // // // // // // // // // // // //         <div className="table-wrapper">
// // // // // // // // // // // // // // // // // // // //           <table className="book-table">
// // // // // // // // // // // // // // // // // // // //             <thead>
// // // // // // // // // // // // // // // // // // // //               <tr>
// // // // // // // // // // // // // // // // // // // //                 <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // // // //                 <th>User Name</th>
// // // // // // // // // // // // // // // // // // // //                 <th>Publication Title</th>
// // // // // // // // // // // // // // // // // // // //                 <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // // // //                 <th>Contact Number</th>
// // // // // // // // // // // // // // // // // // // //                 <th>Email ID</th>
// // // // // // // // // // // // // // // // // // // //                 <th>Payment Status</th>
// // // // // // // // // // // // // // // // // // // //                 <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // // // //                 <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // // // //                 <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // // // //                 <th>Action</th>
// // // // // // // // // // // // // // // // // // // //               </tr>
// // // // // // // // // // // // // // // // // // // //             </thead>
// // // // // // // // // // // // // // // // // // // //             <tbody>
// // // // // // // // // // // // // // // // // // // //               {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // // // //                 <tr key={index}>
// // // // // // // // // // // // // // // // // // // //                   <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // //                     <span
// // // // // // // // // // // // // // // // // // // //                       className="show-more"
// // // // // // // // // // // // // // // // // // // //                       onClick={() => handleShowPopup("Username", row.username)}
// // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // //                       {row.username.length > 15
// // // // // // // // // // // // // // // // // // // //                         ? row.username.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // // //                         : row.username}
// // // // // // // // // // // // // // // // // // // //                     </span>
// // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // //                   <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // // // //                   <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // // // //                   <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // //                     {/* Clickable email */}
// // // // // // // // // // // // // // // // // // // //                     <span
// // // // // // // // // // // // // // // // // // // //                       className="show-more"
// // // // // // // // // // // // // // // // // // // //                       onClick={() => handleShowPopup("Email", row.email)}
// // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // //                       {row.email.length > 15
// // // // // // // // // // // // // // // // // // // //                         ? row.email.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // // //                         : row.email}
// // // // // // // // // // // // // // // // // // // //                     </span>
// // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // //                   <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // //                     {/* Clickable shipping address */}
// // // // // // // // // // // // // // // // // // // //                     <span
// // // // // // // // // // // // // // // // // // // //                       className="show-more"
// // // // // // // // // // // // // // // // // // // //                       onClick={() => handleShowPopup("Shipping Address", row.shippingAddress)}
// // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // //                       {row.shippingAddress.length > 15
// // // // // // // // // // // // // // // // // // // //                         ? row.shippingAddress.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // // //                         : row.shippingAddress}
// // // // // // // // // // // // // // // // // // // //                     </span>
// // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // //                   <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // // // //                   <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // // //                     <button
// // // // // // // // // // // // // // // // // // // //                       className="submit-btn"
// // // // // // // // // // // // // // // // // // // //                       onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // // //                       Submit
// // // // // // // // // // // // // // // // // // // //                     </button>
// // // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // // // // // // // //             </tbody>
// // // // // // // // // // // // // // // // // // // //           </table>
// // // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // // //       )}

// // // // // // // // // // // // // // // // // // // //       {popupVisible && (
// // // // // // // // // // // // // // // // // // // //         <>
// // // // // // // // // // // // // // // // // // // //           <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // // // // // // // // //           <div className="popup show">
// // // // // // // // // // // // // // // // // // // //             <p>{popupContent}</p>
// // // // // // // // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // // // // // // // //         </>
// // // // // // // // // // // // // // // // // // // //       )}
// // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design

// // // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility

// // // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // //         username: "JohnDoe123", // Username only
// // // // // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // // // // //         trackingId: "TRK123456"
// // // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // //         username: "JaneSmith456", // Username only
// // // // // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // // // // //         trackingId: "TRK654321"
// // // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const handleUpdateStockInfo = () => {
// // // // // // // // // // // // // // // // // // //     // Implement the logic for updating stock info here
// // // // // // // // // // // // // // // // // // //     alert("Update Stock Info clicked!");
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // //     <div className="table-container">
// // // // // // // // // // // // // // // // // // //       {/* Flex container to align button and heading in the same line */}
// // // // // // // // // // // // // // // // // // //       {/* <div className="header-container">
// // // // // // // // // // // // // // // // // // //         <button className="update-stock-btn" onClick={handleUpdateStockInfo}>
// // // // // // // // // // // // // // // // // // //           Update Stock Info
// // // // // // // // // // // // // // // // // // //         </button>
// // // // // // // // // // // // // // // // // // //         <h1 className="heading">Book Orders</h1>
// // // // // // // // // // // // // // // // // // //       </div> */}
// // // // // // // // // // // // // // // // // // // <div class="button-container">
// // // // // // // // // // // // // // // // // // // <h2 class="book-order-heading">Book Order</h2>
// // // // // // // // // // // // // // // // // // //   <button class="update-stock-btn">Update Stock</button>
  
// // // // // // // // // // // // // // // // // // // </div>

      
// // // // // // // // // // // // // // // // // // //       {loading ? (
// // // // // // // // // // // // // // // // // // //         <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // // // //       ) : (
// // // // // // // // // // // // // // // // // // //         <div className="table-wrapper">
// // // // // // // // // // // // // // // // // // //           <table className="book-table">
// // // // // // // // // // // // // // // // // // //             <thead>
// // // // // // // // // // // // // // // // // // //               <tr>
// // // // // // // // // // // // // // // // // // //                 <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // // //                 <th>User Name</th>
// // // // // // // // // // // // // // // // // // //                 <th>Publication Title</th>
// // // // // // // // // // // // // // // // // // //                 <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // // //                 <th>Contact Number</th>
                
// // // // // // // // // // // // // // // // // // //                 <th>Email ID</th>
// // // // // // // // // // // // // // // // // // //                 <th>Payment Status</th>
// // // // // // // // // // // // // // // // // // //                 <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // // //                 <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // // //                 <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // // //                 <th>Action</th>
// // // // // // // // // // // // // // // // // // //               </tr>
// // // // // // // // // // // // // // // // // // //             </thead>
// // // // // // // // // // // // // // // // // // //             <tbody>
// // // // // // // // // // // // // // // // // // //               {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // // //                 <tr key={index}>
// // // // // // // // // // // // // // // // // // //                   <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // //                     <span
// // // // // // // // // // // // // // // // // // //                       className="show-more"
// // // // // // // // // // // // // // // // // // //                       onClick={() => handleShowPopup("Username", row.username)}
// // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // //                       {row.username.length > 15
// // // // // // // // // // // // // // // // // // //                         ? row.username.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // //                         : row.username}
// // // // // // // // // // // // // // // // // // //                     </span>
// // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // //                   <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // // //                   <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // // //                   <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // //                     {/* Clickable email */}
// // // // // // // // // // // // // // // // // // //                     <span
// // // // // // // // // // // // // // // // // // //                       className="show-more"
// // // // // // // // // // // // // // // // // // //                       onClick={() => handleShowPopup("Email", row.email)}
// // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // //                       {row.email.length > 15
// // // // // // // // // // // // // // // // // // //                         ? row.email.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // //                         : row.email}
// // // // // // // // // // // // // // // // // // //                     </span>
// // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // //                   <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // //                     {/* Clickable shipping address */}
// // // // // // // // // // // // // // // // // // //                     <span
// // // // // // // // // // // // // // // // // // //                       className="show-more"
// // // // // // // // // // // // // // // // // // //                       onClick={() => handleShowPopup("Shipping Address", row.shippingAddress)}
// // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // //                       {row.shippingAddress.length > 15
// // // // // // // // // // // // // // // // // // //                         ? row.shippingAddress.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // //                         : row.shippingAddress}
// // // // // // // // // // // // // // // // // // //                     </span>
// // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // //                   <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // // //                   <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // // //                   <td>
// // // // // // // // // // // // // // // // // // //                     <button
// // // // // // // // // // // // // // // // // // //                       className="submit-btn"
// // // // // // // // // // // // // // // // // // //                       onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // // // //                     >
// // // // // // // // // // // // // // // // // // //                       Submit
// // // // // // // // // // // // // // // // // // //                     </button>
// // // // // // // // // // // // // // // // // // //                   </td>
// // // // // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // // // // // // //             </tbody>
// // // // // // // // // // // // // // // // // // //           </table>
// // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // //       )}

// // // // // // // // // // // // // // // // // // //       {popupVisible && (
// // // // // // // // // // // // // // // // // // //         <>
// // // // // // // // // // // // // // // // // // //           <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // // // // // // // //           <div className="popup show">
// // // // // // // // // // // // // // // // // // //             <p>{popupContent}</p>
// // // // // // // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // // // // // // //         </>
// // // // // // // // // // // // // // // // // // //       )}
// // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // export default BookTableWithAPI;




// // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // // // // // // // // // // // // // // import Header from "./header"; // Import the Header component
// // // // // // // // // // // // // // // // // // // import Footer from "./footer";


// // // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility

// // // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // //         username: "JohnDoe123",
// // // // // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // // // // //         trackingId: "TRK123456",
// // // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // // //         username: "JaneSmith456",
// // // // // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // // // // //         trackingId: "TRK654321",
// // // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const handleUpdateStockInfo = () => {
// // // // // // // // // // // // // // // // // // //     alert("Update Stock Info clicked!");
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const handleLogout = () => {
// // // // // // // // // // // // // // // // // // //     alert("Log out clicked!");
// // // // // // // // // // // // // // // // // // //     // Add your logout logic here
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // //     <div>
// // // // // // // // // // // // // // // // // // //       {/* Header */}
// // // // // // // // // // // // // // // // // // //       <Header onUpdateStock={handleUpdateStockInfo} onLogout={handleLogout} />

// // // // // // // // // // // // // // // // // // //       {/* Table container */}
// // // // // // // // // // // // // // // // // // //       <div className="table-container">
// // // // // // // // // // // // // // // // // // //         {loading ? (
// // // // // // // // // // // // // // // // // // //           <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // // // // // // // //           <div className="table-wrapper">
// // // // // // // // // // // // // // // // // // //             <table className="book-table">
// // // // // // // // // // // // // // // // // // //               <thead>
// // // // // // // // // // // // // // // // // // //                 <tr>
// // // // // // // // // // // // // // // // // // //                   <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // // //                   <th>User Name</th>
// // // // // // // // // // // // // // // // // // //                   <th>Publication Title</th>
// // // // // // // // // // // // // // // // // // //                   <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // // //                   <th>Contact Number</th>
// // // // // // // // // // // // // // // // // // //                   <th>Email ID</th>
// // // // // // // // // // // // // // // // // // //                   <th>Payment Status</th>
// // // // // // // // // // // // // // // // // // //                   <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // // //                   <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // // //                   <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // // //                   <th>Action</th>
// // // // // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // // // // //               </thead>
// // // // // // // // // // // // // // // // // // //               <tbody>
// // // // // // // // // // // // // // // // // // //                 {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // // //                   <tr key={index}>
// // // // // // // // // // // // // // // // // // //                     <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // // // // // // //                         onClick={() => handleShowPopup("Username", row.username)}
// // // // // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // // // // //                         {row.username.length > 15
// // // // // // // // // // // // // // // // // // //                           ? row.username.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // //                           : row.username}
// // // // // // // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // // // // //                     <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // // //                     <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // // //                     <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // // // // // // //                         onClick={() =>
// // // // // // // // // // // // // // // // // // //                           handleShowPopup("Email", row.email)
// // // // // // // // // // // // // // // // // // //                         }
// // // // // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // // // // //                         {row.email.length > 15
// // // // // // // // // // // // // // // // // // //                           ? row.email.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // //                           : row.email}
// // // // // // // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // // // // //                     <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // // // // // // //                         onClick={() =>
// // // // // // // // // // // // // // // // // // //                           handleShowPopup("Shipping Address", row.shippingAddress)
// // // // // // // // // // // // // // // // // // //                         }
// // // // // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // // // // //                         {row.shippingAddress.length > 15
// // // // // // // // // // // // // // // // // // //                           ? row.shippingAddress.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // // //                           : row.shippingAddress}
// // // // // // // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // // // // //                     <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // // //                     <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // // // // //                       <button
// // // // // // // // // // // // // // // // // // //                         className="submit-btn"
// // // // // // // // // // // // // // // // // // //                         onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // // // // //                         Submit
// // // // // // // // // // // // // // // // // // //                       </button>
// // // // // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // // // // //                   </tr>
// // // // // // // // // // // // // // // // // // //                 ))}
// // // // // // // // // // // // // // // // // // //               </tbody>
// // // // // // // // // // // // // // // // // // //             </table>
// // // // // // // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // // // // // // //         )}

// // // // // // // // // // // // // // // // // // //         {popupVisible && (
// // // // // // // // // // // // // // // // // // //           <>
// // // // // // // // // // // // // // // // // // //             <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // // // // // // // //             <div className="popup show">
// // // // // // // // // // // // // // // // // // //               <p>{popupContent}</p>
// // // // // // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // // // // // //           </>
// // // // // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // // // // //       </div>
// // // // // // // // // // // // // // // // // // //       <Footer />
// // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // // // // // // // // // // // // // import Header from "./header"; // Import the Header component
// // // // // // // // // // // // // // // // // // import Footer from "./footer";

// // // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility

// // // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // //         username: "JohnDoe123",
// // // // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // // // //         trackingId: "TRK123456",
// // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // // //         username: "JaneSmith456",
// // // // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // // // //         trackingId: "TRK654321",
// // // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // //   const handleLogout = () => {
// // // // // // // // // // // // // // // // // //     alert("Log out clicked!");
// // // // // // // // // // // // // // // // // //     // Add your logout logic here
// // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // //     <div>
// // // // // // // // // // // // // // // // // //       {/* Header */}
// // // // // // // // // // // // // // // // // //       <Header onLogout={handleLogout} />

// // // // // // // // // // // // // // // // // //       {/* Table container */}
// // // // // // // // // // // // // // // // // //       <div className="table-container">
// // // // // // // // // // // // // // // // // //         {loading ? (
// // // // // // // // // // // // // // // // // //           <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // // // // // // //           <div className="table-wrapper">
// // // // // // // // // // // // // // // // // //             <table className="book-table">
// // // // // // // // // // // // // // // // // //               <thead>
// // // // // // // // // // // // // // // // // //                 <tr>
// // // // // // // // // // // // // // // // // //                   <th>Sl. No.</th>
// // // // // // // // // // // // // // // // // //                   <th>User Name</th>
// // // // // // // // // // // // // // // // // //                   <th>Publication Title</th>
// // // // // // // // // // // // // // // // // //                   <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // // //                   <th>Contact Number</th>
// // // // // // // // // // // // // // // // // //                   <th>Email ID</th>
// // // // // // // // // // // // // // // // // //                   <th>Payment Status</th>
// // // // // // // // // // // // // // // // // //                   <th>Shipping Address</th>
// // // // // // // // // // // // // // // // // //                   <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // // //                   <th>Tracking ID</th>
// // // // // // // // // // // // // // // // // //                   <th>Action</th>
// // // // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // // // //               </thead>
// // // // // // // // // // // // // // // // // //               <tbody>
// // // // // // // // // // // // // // // // // //                 {tableData.map((row, index) => (
// // // // // // // // // // // // // // // // // //                   <tr key={index}>
// // // // // // // // // // // // // // // // // //                     <td>{index + 1}</td>
// // // // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // // // // // //                         onClick={() => handleShowPopup("Username", row.username)}
// // // // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // // // //                         {row.username.length > 15
// // // // // // // // // // // // // // // // // //                           ? row.username.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // //                           : row.username}
// // // // // // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // // // //                     <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // // //                     <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // // //                     <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // // // // // //                         onClick={() => handleShowPopup("Email", row.email)}
// // // // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // // // //                         {row.email.length > 15
// // // // // // // // // // // // // // // // // //                           ? row.email.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // //                           : row.email}
// // // // // // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // // // //                     <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // // // // // //                         onClick={() =>
// // // // // // // // // // // // // // // // // //                           handleShowPopup("Shipping Address", row.shippingAddress)
// // // // // // // // // // // // // // // // // //                         }
// // // // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // // // //                         {row.shippingAddress.length > 15
// // // // // // // // // // // // // // // // // //                           ? row.shippingAddress.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // // //                           : row.shippingAddress}
// // // // // // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // // // //                     <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // // //                     <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // // // //                       <button
// // // // // // // // // // // // // // // // // //                         className="submit-btn"
// // // // // // // // // // // // // // // // // //                         onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // // // //                         Submit
// // // // // // // // // // // // // // // // // //                       </button>
// // // // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // // // //                   </tr>
// // // // // // // // // // // // // // // // // //                 ))}
// // // // // // // // // // // // // // // // // //               </tbody>
// // // // // // // // // // // // // // // // // //             </table>
// // // // // // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // // // // // //         )}

// // // // // // // // // // // // // // // // // //         {popupVisible && (
// // // // // // // // // // // // // // // // // //           <>
// // // // // // // // // // // // // // // // // //             <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // // // // // // //             <div className="popup show">
// // // // // // // // // // // // // // // // // //               <p>{popupContent}</p>
// // // // // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // // // // //           </>
// // // // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // // // //       </div>
// // // // // // // // // // // // // // // // // //       <Footer />
// // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // // // // // // // // // // // // import Header from "./header"; // Import the Header component
// // // // // // // // // // // // // // // // // import Footer from "./footer";

// // // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility
// // // // // // // // // // // // // // // // //   const [currentPage, setCurrentPage] = useState(1); // Current page number
// // // // // // // // // // // // // // // // //   const rowsPerPage = 9; // Number of rows per page (set to 9 for the first page)

// // // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // //         username: "JohnDoe123",
// // // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // // //         trackingId: "TRK123456",
// // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // // //         username: "JaneSmith456",
// // // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // // //         trackingId: "TRK654321",
// // // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // // //       // Add more data here as required to test pagination
// // // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // //   const handleLogout = () => {
// // // // // // // // // // // // // // // // //     alert("Log out clicked!");
// // // // // // // // // // // // // // // // //     // Add your logout logic here
// // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // //   // Logic for pagination
// // // // // // // // // // // // // // // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // // // // // // // // // // // // // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // // // // // // // // // // // // // // //   // Slice data for current page
// // // // // // // // // // // // // // // // //   let currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

// // // // // // // // // // // // // // // // //   // If it's the first page and there are fewer than 9 rows, fill the remaining rows with empty data
// // // // // // // // // // // // // // // // //   if (currentPage === 1 && currentRows.length < rowsPerPage) {
// // // // // // // // // // // // // // // // //     const remainingRows = rowsPerPage - currentRows.length;
// // // // // // // // // // // // // // // // //     for (let i = 0; i < remainingRows; i++) {
// // // // // // // // // // // // // // // // //       currentRows.push({
// // // // // // // // // // // // // // // // //         username: "",
// // // // // // // // // // // // // // // // //         publicationTitle: "",
// // // // // // // // // // // // // // // // //         noOfBooks: "",
// // // // // // // // // // // // // // // // //         contactNumber: "",
// // // // // // // // // // // // // // // // //         email: "",
// // // // // // // // // // // // // // // // //         orderedDate: "",
// // // // // // // // // // // // // // // // //         shippingAddress: "",
// // // // // // // // // // // // // // // // //         dispatchDate: "",
// // // // // // // // // // // // // // // // //         trackingId: "",
// // // // // // // // // // // // // // // // //       });
// // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // //   }

// // // // // // // // // // // // // // // // //   const totalPages = Math.ceil(tableData.length / rowsPerPage);

// // // // // // // // // // // // // // // // //   const handlePageChange = (pageNumber) => {
// // // // // // // // // // // // // // // // //     setCurrentPage(pageNumber);
// // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // //     <div>
// // // // // // // // // // // // // // // // //       {/* Header */}
// // // // // // // // // // // // // // // // //       <Header onLogout={handleLogout} />

// // // // // // // // // // // // // // // // //       {/* Table container */}
// // // // // // // // // // // // // // // // //       <div className="table-container">
// // // // // // // // // // // // // // // // //         {loading ? (
// // // // // // // // // // // // // // // // //           <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // // // // // //           <div className="table-wrapper">
// // // // // // // // // // // // // // // // //             <table className="book-table">
// // // // // // // // // // // // // // // // //               <thead>
// // // // // // // // // // // // // // // // //                 <tr>
// // // // // // // // // // // // // // // // //                   <th>Sl. No.</th>
// // // // // // // // // // // // // // // // //                   <th>User Name</th>
// // // // // // // // // // // // // // // // //                   <th>Publication Title</th>
// // // // // // // // // // // // // // // // //                   <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // // //                   <th>Contact Number</th>
// // // // // // // // // // // // // // // // //                   <th>Email ID</th>
// // // // // // // // // // // // // // // // //                   <th>Payment Status</th>
// // // // // // // // // // // // // // // // //                   <th>Shipping Address</th>
// // // // // // // // // // // // // // // // //                   <th>Dispatch Date</th>
// // // // // // // // // // // // // // // // //                   <th>Tracking ID</th>
// // // // // // // // // // // // // // // // //                   <th>Action</th>
// // // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // // //               </thead>
// // // // // // // // // // // // // // // // //               <tbody>
// // // // // // // // // // // // // // // // //                 {currentRows.map((row, index) => (
// // // // // // // // // // // // // // // // //                   <tr key={index}>
// // // // // // // // // // // // // // // // //                     <td>{indexOfFirstRow + index + 1}</td>
// // // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // // // // //                         onClick={() => handleShowPopup("Username", row.username)}
// // // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // // //                         {row.username.length > 15
// // // // // // // // // // // // // // // // //                           ? row.username.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // //                           : row.username}
// // // // // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // // //                     <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // // //                     <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // // //                     <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // // // // //                         onClick={() => handleShowPopup("Email", row.email)}
// // // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // // //                         {row.email.length > 15
// // // // // // // // // // // // // // // // //                           ? row.email.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // //                           : row.email}
// // // // // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // // //                     <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // // // // //                         onClick={() =>
// // // // // // // // // // // // // // // // //                           handleShowPopup("Shipping Address", row.shippingAddress)
// // // // // // // // // // // // // // // // //                         }
// // // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // // //                         {row.shippingAddress.length > 15
// // // // // // // // // // // // // // // // //                           ? row.shippingAddress.substring(0, 15) + "..."
// // // // // // // // // // // // // // // // //                           : row.shippingAddress}
// // // // // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // // //                     <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // // //                     <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // // //                       <button
// // // // // // // // // // // // // // // // //                         className="submit-btn"
// // // // // // // // // // // // // // // // //                         onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // // //                         Submit
// // // // // // // // // // // // // // // // //                       </button>
// // // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // // //                   </tr>
// // // // // // // // // // // // // // // // //                 ))}
// // // // // // // // // // // // // // // // //               </tbody>
// // // // // // // // // // // // // // // // //             </table>

// // // // // // // // // // // // // // // // //             {/* Pagination Controls */}
// // // // // // // // // // // // // // // // //             <div className="pagination">
// // // // // // // // // // // // // // // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // // // // // // // // // // // // // // //                 <button
// // // // // // // // // // // // // // // // //                   key={index + 1}
// // // // // // // // // // // // // // // // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // // // // // // // // // // // // // // // //                   onClick={() => handlePageChange(index + 1)}
// // // // // // // // // // // // // // // // //                 >
// // // // // // // // // // // // // // // // //                   {index + 1}
// // // // // // // // // // // // // // // // //                 </button>
// // // // // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // // // // //         )}

// // // // // // // // // // // // // // // // //         {popupVisible && (
// // // // // // // // // // // // // // // // //           <>
// // // // // // // // // // // // // // // // //             <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // // // // // //             <div className="popup show">
// // // // // // // // // // // // // // // // //               <p>{popupContent}</p>
// // // // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // // // //           </>
// // // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // // //       </div>
// // // // // // // // // // // // // // // // //       <Footer />
// // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // export default BookTableWithAPI;

// // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // // // // // // // // // // // import Header from "./header"; // Import the Header component
// // // // // // // // // // // // // // // // import Footer from "./footer";

// // // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility
// // // // // // // // // // // // // // // //   const [currentPage, setCurrentPage] = useState(1); // Current page number
// // // // // // // // // // // // // // // //   const rowsPerPage = 9; // Number of rows per page (set to 9 for the first page)

// // // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // //         username: "JohnDoe123",
// // // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // // //         trackingId: "TRK123456",
// // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // // //         username: "JaneSmith456",
// // // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // // //         dispatchDate: "2024-11-26",
// // // // // // // // // // // // // // // //         trackingId: "TRK654321",
// // // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // // //       // Add more data here as required to test pagination
// // // // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // //   const handleLogout = () => {
// // // // // // // // // // // // // // // //     alert("Log out clicked!");
// // // // // // // // // // // // // // // //     // Add your logout logic here
// // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // //   // Logic for pagination
// // // // // // // // // // // // // // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // // // // // // // // // // // // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // // // // // // // // // // // // // //   // Slice data for current page
// // // // // // // // // // // // // // // //   let currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

// // // // // // // // // // // // // // // //   // If it's the first page and there are fewer than 9 rows, fill the remaining rows with empty data
// // // // // // // // // // // // // // // //   if (currentPage === 1 && currentRows.length < rowsPerPage) {
// // // // // // // // // // // // // // // //     const remainingRows = rowsPerPage - currentRows.length;
// // // // // // // // // // // // // // // //     for (let i = 0; i < remainingRows; i++) {
// // // // // // // // // // // // // // // //       currentRows.push({
// // // // // // // // // // // // // // // //         username: "",
// // // // // // // // // // // // // // // //         publicationTitle: "",
// // // // // // // // // // // // // // // //         noOfBooks: "",
// // // // // // // // // // // // // // // //         contactNumber: "",
// // // // // // // // // // // // // // // //         email: "",
// // // // // // // // // // // // // // // //         orderedDate: "",
// // // // // // // // // // // // // // // //         shippingAddress: "",
// // // // // // // // // // // // // // // //         dispatchDate: "",
// // // // // // // // // // // // // // // //         trackingId: "",
// // // // // // // // // // // // // // // //       });
// // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // //   }

// // // // // // // // // // // // // // // //   const totalPages = Math.ceil(tableData.length / rowsPerPage);

// // // // // // // // // // // // // // // //   const handlePageChange = (pageNumber) => {
// // // // // // // // // // // // // // // //     setCurrentPage(pageNumber);
// // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // //     <div>
// // // // // // // // // // // // // // // //       {/* Header */}
// // // // // // // // // // // // // // // //       <Header onLogout={handleLogout} />

// // // // // // // // // // // // // // // //       {/* Table container */}
// // // // // // // // // // // // // // // //       <div className="table-container">
// // // // // // // // // // // // // // // //         {loading ? (
// // // // // // // // // // // // // // // //           <div className="loading">Loading...</div>
// // // // // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // // // // //           <div className="table-wrapper">
// // // // // // // // // // // // // // // //             <table className="book-table">
// // // // // // // // // // // // // // // //               <thead>
// // // // // // // // // // // // // // // //                 <tr>
// // // // // // // // // // // // // // // //                   <th>Sl. No.</th>
// // // // // // // // // // // // // // // //                   <th>User Name</th>
// // // // // // // // // // // // // // // //                   <th>Publication Title</th>
// // // // // // // // // // // // // // // //                   <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // // //                   <th>Contact Number</th>
// // // // // // // // // // // // // // // //                   <th>Email ID</th>
// // // // // // // // // // // // // // // //                   <th>Payment Status</th>
// // // // // // // // // // // // // // // //                   <th>Shipping Address</th>
// // // // // // // // // // // // // // // //                   <th>Dispatch Date</th>
// // // // // // // // // // // // // // // //                   <th>Tracking ID</th>
// // // // // // // // // // // // // // // //                   <th>Action</th>
// // // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // // //               </thead>
// // // // // // // // // // // // // // // //               <tbody>
// // // // // // // // // // // // // // // //                 {currentRows.map((row, index) => (
// // // // // // // // // // // // // // // //                   <tr key={index}>
// // // // // // // // // // // // // // // //                     <td>{indexOfFirstRow + index + 1}</td>
// // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // // // //                         onClick={() => handleShowPopup("Username", row.username)}
// // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // //                         {row.username.length > 15
// // // // // // // // // // // // // // // //                           ? row.username.substring(0, 15) + "..."
// // // // // // // // // // // // // // // //                           : row.username}
// // // // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // //                     <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // // //                     <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // // //                     <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // // // //                         onClick={() => handleShowPopup("Email", row.email)}
// // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // //                         {row.email.length > 15
// // // // // // // // // // // // // // // //                           ? row.email.substring(0, 15) + "..."
// // // // // // // // // // // // // // // //                           : row.email}
// // // // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // //                     <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // // // //                         onClick={() =>
// // // // // // // // // // // // // // // //                           handleShowPopup("Shipping Address", row.shippingAddress)
// // // // // // // // // // // // // // // //                         }
// // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // //                         {row.shippingAddress.length > 15
// // // // // // // // // // // // // // // //                           ? row.shippingAddress.substring(0, 15) + "..."
// // // // // // // // // // // // // // // //                           : row.shippingAddress}
// // // // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // //                     <td>{row.dispatchDate}</td>
// // // // // // // // // // // // // // // //                     <td>{row.trackingId}</td>
// // // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // // //                       <button
// // // // // // // // // // // // // // // //                         className="submit-btn"
// // // // // // // // // // // // // // // //                         onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // // //                         Submit
// // // // // // // // // // // // // // // //                       </button>
// // // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // // //                   </tr>
// // // // // // // // // // // // // // // //                 ))}
// // // // // // // // // // // // // // // //               </tbody>
// // // // // // // // // // // // // // // //             </table>

// // // // // // // // // // // // // // // //             {/* Pagination Controls */}
// // // // // // // // // // // // // // // //             <div className="pagination">
// // // // // // // // // // // // // // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // // // // // // // // // // // // // //                 <button
// // // // // // // // // // // // // // // //                   key={index + 1}
// // // // // // // // // // // // // // // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // // // // // // // // // // // // // // //                   onClick={() => handlePageChange(index + 1)}
// // // // // // // // // // // // // // // //                 >
// // // // // // // // // // // // // // // //                   {index + 1}
// // // // // // // // // // // // // // // //                 </button>
// // // // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // // // //         )}

// // // // // // // // // // // // // // // //         {popupVisible && (
// // // // // // // // // // // // // // // //           <>
// // // // // // // // // // // // // // // //             <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // // // // //             <div className="popup show">
// // // // // // // // // // // // // // // //               <p>{popupContent}</p>
// // // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // // //           </>
// // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // //       </div>
// // // // // // // // // // // // // // // //       <Footer />
// // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // import DatePicker from "react-datepicker";
// // // // // // // // // // // // // // // import "react-datepicker/dist/react-datepicker.css";
// // // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // // // // // // // // // // import Header from "./header"; // Import the Header component
// // // // // // // // // // // // // // // import Footer from "./footer";

// // // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility
// // // // // // // // // // // // // // //   const [currentPage, setCurrentPage] = useState(1); // Current page number
// // // // // // // // // // // // // // //   const rowsPerPage = 9; // Number of rows per page (set to 9 for the first page)
// // // // // // // // // // // // // // //   const [selectedDate, setSelectedDate] = useState(null); // State for managing selected date

// // // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // //         username: "JohnDoe123",
// // // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // // //         dispatchDate: "2024-11-25",
// // // // // // // // // // // // // // //         trackingId: "TRK123456",
// // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // //       {
// // // // // // // // // // // // // // //         username: "JaneSmith456",
// // // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // // //         dispatchDate: "",
// // // // // // // // // // // // // // //         trackingId: "",
// // // // // // // // // // // // // // //       },
// // // // // // // // // // // // // // //     ];
// // // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // //   const handleLogout = () => {
// // // // // // // // // // // // // // //     alert("Log out clicked!");
// // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // // // // // // // // // // // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;
// // // // // // // // // // // // // // //   let currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

// // // // // // // // // // // // // // //   if (currentPage === 1 && currentRows.length < rowsPerPage) {
// // // // // // // // // // // // // // //     const remainingRows = rowsPerPage - currentRows.length;
// // // // // // // // // // // // // // //     for (let i = 0; i < remainingRows; i++) {
// // // // // // // // // // // // // // //       currentRows.push({
// // // // // // // // // // // // // // //         username: "",
// // // // // // // // // // // // // // //         publicationTitle: "",
// // // // // // // // // // // // // // //         noOfBooks: "",
// // // // // // // // // // // // // // //         contactNumber: "",
// // // // // // // // // // // // // // //         email: "",
// // // // // // // // // // // // // // //         orderedDate: "",
// // // // // // // // // // // // // // //         shippingAddress: "",
// // // // // // // // // // // // // // //         dispatchDate: "",
// // // // // // // // // // // // // // //         trackingId: "",
// // // // // // // // // // // // // // //       });
// // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // //   }

// // // // // // // // // // // // // // //   const totalPages = Math.ceil(tableData.length / rowsPerPage);

// // // // // // // // // // // // // // //   const handlePageChange = (pageNumber) => {
// // // // // // // // // // // // // // //     setCurrentPage(pageNumber);
// // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // //   const handleDateChange = (date, rowIndex) => {
// // // // // // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // // // // // //     updatedData[rowIndex].dispatchDate = date;
// // // // // // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // // // // // //     setSelectedDate(date);
// // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // //     <div>
// // // // // // // // // // // // // // //       <Header onLogout={handleLogout} />

// // // // // // // // // // // // // // //       <div className="table-container">
// // // // // // // // // // // // // // //         {loading ? (
// // // // // // // // // // // // // // //           <div className="loading">Loading...</div>
// // // // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // // // //           <div className="table-wrapper">
// // // // // // // // // // // // // // //             <table className="book-table">
// // // // // // // // // // // // // // //               <thead>
// // // // // // // // // // // // // // //                 <tr>
// // // // // // // // // // // // // // //                   <th>Sl. No.</th>
// // // // // // // // // // // // // // //                   <th>User Name</th>
// // // // // // // // // // // // // // //                   <th>Publication Title</th>
// // // // // // // // // // // // // // //                   <th>No. of Books Ordered</th>
// // // // // // // // // // // // // // //                   <th>Contact Number</th>
// // // // // // // // // // // // // // //                   <th>Email ID</th>
// // // // // // // // // // // // // // //                   <th>Payment Status</th>
// // // // // // // // // // // // // // //                   <th>Shipping Address</th>
// // // // // // // // // // // // // // //                   <th>Dispatch Date</th>
// // // // // // // // // // // // // // //                   <th>Tracking ID</th>
// // // // // // // // // // // // // // //                   <th>Action</th>
// // // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // // //               </thead>
// // // // // // // // // // // // // // //               <tbody>
// // // // // // // // // // // // // // //                 {currentRows.map((row, index) => (
// // // // // // // // // // // // // // //                   <tr key={index}>
// // // // // // // // // // // // // // //                     <td>{indexOfFirstRow + index + 1}</td>
// // // // // // // // // // // // // // //                     <td>{row.username}</td>
// // // // // // // // // // // // // // //                     <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // // //                     <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // // //                     <td>{row.contactNumber}</td>
// // // // // // // // // // // // // // //                     <td>{row.email}</td>
// // // // // // // // // // // // // // //                     <td>{row.orderedDate}</td>
// // // // // // // // // // // // // // //                     <td>{row.shippingAddress}</td>
// // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // // //                         onClick={() => handleShowPopup("Dispatch Date", row.dispatchDate)}
// // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // //                         {row.dispatchDate ? row.dispatchDate : "Select Date"}
// // // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // // //                       <button onClick={() => setSelectedDate(row.dispatchDate)} className="calendar-icon-btn">
// // // // // // // // // // // // // // //                         📅
// // // // // // // // // // // // // // //                       </button>
// // // // // // // // // // // // // // //                       {selectedDate && (
// // // // // // // // // // // // // // //                         <DatePicker
// // // // // // // // // // // // // // //                           selected={new Date(sele)}
// // // // // // // // // // // // // // //                           onChange={(date) => handleDateChange(date, index)}
// // // // // // // // // // // // // // //                           dateFormat="yyyy/MM/dd"
// // // // // // // // // // // // // // //                           showPopperArrow={false}
// // // // // // // // // // // // // // //                         />
// // // // // // // // // // // // // // //                       )}
// // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // //                     <td>{row.trackingId}</td>
// // // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // // //                       <button
// // // // // // // // // // // // // // //                         className="submit-btn"
// // // // // // // // // // // // // // //                         onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // // //                         Submit
// // // // // // // // // // // // // // //                       </button>
// // // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // // //                   </tr>
// // // // // // // // // // // // // // //                 ))}
// // // // // // // // // // // // // // //               </tbody>
// // // // // // // // // // // // // // //             </table>

// // // // // // // // // // // // // // //             <div className="pagination">
// // // // // // // // // // // // // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // // // // // // // // // // // // //                 <button
// // // // // // // // // // // // // // //                   key={index + 1}
// // // // // // // // // // // // // // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // // // // // // // // // // // // // //                   onClick={() => handlePageChange(index + 1)}
// // // // // // // // // // // // // // //                 >
// // // // // // // // // // // // // // //                   {index + 1}
// // // // // // // // // // // // // // //                 </button>
// // // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // // //         )}

// // // // // // // // // // // // // // //         {popupVisible && (
// // // // // // // // // // // // // // //           <>
// // // // // // // // // // // // // // //             <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // // // //             <div className="popup show">
// // // // // // // // // // // // // // //               <p>{popupContent}</p>
// // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // //           </>
// // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // //       </div>
// // // // // // // // // // // // // // //       <Footer />
// // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // // // // // // // // // import Header from "./header"; // Import the Header component
// // // // // // // // // // // // // // import Footer from "./footer";

// // // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility
// // // // // // // // // // // // // //   const [currentPage, setCurrentPage] = useState(1); // Current page number
// // // // // // // // // // // // // //   const rowsPerPage = 9; // Number of rows per page (set to 9 for the first page)

// // // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // // //       {
// // // // // // // // // // // // // //         username: "JohnDoe123",
// // // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // // //         dispatchDate: "",  // Empty until user fills
// // // // // // // // // // // // // //         trackingId: "",    // Empty until user fills
// // // // // // // // // // // // // //       },
// // // // // // // // // // // // // //       {
// // // // // // // // // // // // // //         username: "JaneSmith456",
// // // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // // //         dispatchDate: "",  // Empty until user fills
// // // // // // // // // // // // // //         trackingId: "",    // Empty until user fills
// // // // // // // // // // // // // //       },
// // // // // // // // // // // // // //       // Add more data here as required to test pagination
// // // // // // // // // // // // // //     ];

// // // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // // //     }
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   const handleLogout = () => {
// // // // // // // // // // // // // //     alert("Log out clicked!");
// // // // // // // // // // // // // //     // Add your logout logic here
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   const handleDispatchDateChange = (index, value) => {
// // // // // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // // // // //     updatedData[index].dispatchDate = value;
// // // // // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   const handleTrackingIdChange = (index, value) => {
// // // // // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // // // // //     updatedData[index].trackingId = value;
// // // // // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   // Logic for pagination
// // // // // // // // // // // // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // // // // // // // // // // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // // // // // // // // // // // //   // Slice data for current page
// // // // // // // // // // // // // //   let currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

// // // // // // // // // // // // // //   // If it's the first page and there are fewer than 9 rows, fill the remaining rows with empty data
// // // // // // // // // // // // // //   if (currentPage === 1 && currentRows.length < rowsPerPage) {
// // // // // // // // // // // // // //     const remainingRows = rowsPerPage - currentRows.length;
// // // // // // // // // // // // // //     for (let i = 0; i < remainingRows; i++) {
// // // // // // // // // // // // // //       currentRows.push({
// // // // // // // // // // // // // //         username: "",
// // // // // // // // // // // // // //         publicationTitle: "",
// // // // // // // // // // // // // //         noOfBooks: "",
// // // // // // // // // // // // // //         contactNumber: "",
// // // // // // // // // // // // // //         email: "",
// // // // // // // // // // // // // //         orderedDate: "",
// // // // // // // // // // // // // //         shippingAddress: "",
// // // // // // // // // // // // // //         dispatchDate: "",
// // // // // // // // // // // // // //         trackingId: "",
// // // // // // // // // // // // // //       });
// // // // // // // // // // // // // //     }
// // // // // // // // // // // // // //   }

// // // // // // // // // // // // // //   const totalPages = Math.ceil(tableData.length / rowsPerPage);

// // // // // // // // // // // // // //   const handlePageChange = (pageNumber) => {
// // // // // // // // // // // // // //     setCurrentPage(pageNumber);
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // //     <div>
// // // // // // // // // // // // // //       {/* Header */}
// // // // // // // // // // // // // //       <Header onLogout={handleLogout} />

// // // // // // // // // // // // // //       {/* Table container */}
// // // // // // // // // // // // // //       <div className="table-container">
// // // // // // // // // // // // // //         {loading ? (
// // // // // // // // // // // // // //           <div className="loading">Loading...</div>
// // // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // // //           <div className="table-wrapper">
// // // // // // // // // // // // // //             <table className="book-table">
// // // // // // // // // // // // // //               <thead>
// // // // // // // // // // // // // //                 <tr>
// // // // // // // // // // // // // //                   <th>Sl. No.</th>
// // // // // // // // // // // // // //                   <th>User Name</th>
// // // // // // // // // // // // // //                   <th>Publication Title</th>
// // // // // // // // // // // // // //                   <th>No. of Books Ordered</th>
// // // // // // // // // // // // // //                   <th>Contact Number</th>
// // // // // // // // // // // // // //                   <th>Email ID</th>
// // // // // // // // // // // // // //                   <th>Payment Status</th>
// // // // // // // // // // // // // //                   <th>Shipping Address</th>
// // // // // // // // // // // // // //                   <th>Dispatch Date</th>
// // // // // // // // // // // // // //                   <th>Tracking ID</th>
// // // // // // // // // // // // // //                   <th>Action</th>
// // // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // // //               </thead>
// // // // // // // // // // // // // //               <tbody>
// // // // // // // // // // // // // //                 {currentRows.map((row, index) => (
// // // // // // // // // // // // // //                   <tr key={index}>
// // // // // // // // // // // // // //                     <td>{indexOfFirstRow + index + 1}</td>
// // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // //                         onClick={() => handleShowPopup("Username", row.username)}
// // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // //                         {row.username.length > 15
// // // // // // // // // // // // // //                           ? row.username.substring(0, 15) + "..."
// // // // // // // // // // // // // //                           : row.username}
// // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // //                     <td>{row.publicationTitle}</td>
// // // // // // // // // // // // // //                     <td>{row.noOfBooks}</td>
// // // // // // // // // // // // // //                     <td>{row.contactNumber}</td>
// // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // //                         onClick={() => handleShowPopup("Email", row.email)}
// // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // //                         {row.email.length > 15
// // // // // // // // // // // // // //                           ? row.email.substring(0, 15) + "..."
// // // // // // // // // // // // // //                           : row.email}
// // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // //                     <td>{row.orderedDate}</td>
// // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // //                       <span
// // // // // // // // // // // // // //                         className="show-more"
// // // // // // // // // // // // // //                         onClick={() =>
// // // // // // // // // // // // // //                           handleShowPopup("Shipping Address", row.shippingAddress)
// // // // // // // // // // // // // //                         }
// // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // //                         {row.shippingAddress.length > 15
// // // // // // // // // // // // // //                           ? row.shippingAddress.substring(0, 15) + "..."
// // // // // // // // // // // // // //                           : row.shippingAddress}
// // // // // // // // // // // // // //                       </span>
// // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // //                       <input
// // // // // // // // // // // // // //                         type="date"
// // // // // // // // // // // // // //                         value={row.dispatchDate}
// // // // // // // // // // // // // //                         onChange={(e) =>
// // // // // // // // // // // // // //                           handleDispatchDateChange(index, e.target.value)
// // // // // // // // // // // // // //                         }
// // // // // // // // // // // // // //                       />
// // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // //                       <input
// // // // // // // // // // // // // //                         type="text"
// // // // // // // // // // // // // //                         value={row.trackingId}
// // // // // // // // // // // // // //                         onChange={(e) =>
// // // // // // // // // // // // // //                           handleTrackingIdChange(index, e.target.value)
// // // // // // // // // // // // // //                         }
// // // // // // // // // // // // // //                       />
// // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // // //                       <button
// // // // // // // // // // // // // //                         className="submit-btn"
// // // // // // // // // // // // // //                         onClick={() => handleSubmit(row)}
// // // // // // // // // // // // // //                       >
// // // // // // // // // // // // // //                         Submit
// // // // // // // // // // // // // //                       </button>
// // // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // // //                   </tr>
// // // // // // // // // // // // // //                 ))}
// // // // // // // // // // // // // //               </tbody>
// // // // // // // // // // // // // //             </table>

// // // // // // // // // // // // // //             {/* Pagination Controls */}
// // // // // // // // // // // // // //             <div className="pagination">
// // // // // // // // // // // // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // // // // // // // // // // // //                 <button
// // // // // // // // // // // // // //                   key={index + 1}
// // // // // // // // // // // // // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // // // // // // // // // // // // //                   onClick={() => handlePageChange(index + 1)}
// // // // // // // // // // // // // //                 >
// // // // // // // // // // // // // //                   {index + 1}
// // // // // // // // // // // // // //                 </button>
// // // // // // // // // // // // // //               ))}
// // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // //           </div>
// // // // // // // // // // // // // //         )}

// // // // // // // // // // // // // //         {popupVisible && (
// // // // // // // // // // // // // //           <>
// // // // // // // // // // // // // //             <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // // //             <div className="popup show">
// // // // // // // // // // // // // //               <p>{popupContent}</p>
// // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // //           </>
// // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // //       </div>
// // // // // // // // // // // // // //       <Footer />
// // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // };

// // // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // // // // // // // // import Header from "./header"; // Import the Header component
// // // // // // // // // // // // // import Footer from "./footer";

// // // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility
// // // // // // // // // // // // //   const [currentPage, setCurrentPage] = useState(1); // Current page number
// // // // // // // // // // // // //   const rowsPerPage = 9; // Number of rows per page (set to 9 for the first page)

// // // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // // //   }, []);

// // // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // // //       {
// // // // // // // // // // // // //         username: "JohnDoe123",
// // // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // // //         dispatchDate: "",  // Empty until user fills
// // // // // // // // // // // // //         trackingId: "",    // Empty until user fills
// // // // // // // // // // // // //       },
// // // // // // // // // // // // //       {
// // // // // // // // // // // // //         username: "JaneSmith456",
// // // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // // //         dispatchDate: "",  // Empty until user fills
// // // // // // // // // // // // //         trackingId: "",    // Empty until user fills
// // // // // // // // // // // // //       },
// // // // // // // // // // // // //       // Add more data here as required to test pagination
// // // // // // // // // // // // //     ];

// // // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // // //     try {
// // // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // // //     }
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   const handleLogout = () => {
// // // // // // // // // // // // //     alert("Log out clicked!");
// // // // // // // // // // // // //     // Add your logout logic here
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   const handleDispatchDateChange = (index, value) => {
// // // // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // // // //     updatedData[index].dispatchDate = value;
// // // // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   const handleTrackingIdChange = (index, value) => {
// // // // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // // // //     updatedData[index].trackingId = value;
// // // // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   const handleFieldClick = (index, field) => {
// // // // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // // // //     updatedData[index][field] = true;
// // // // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   const handleFieldBlur = (index, field) => {
// // // // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // // // //     updatedData[index][field] = false;
// // // // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   // Logic for pagination
// // // // // // // // // // // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // // // // // // // // // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // // // // // // // // // // //   let currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

// // // // // // // // // // // // //   if (currentPage === 1 && currentRows.length < rowsPerPage) {
// // // // // // // // // // // // //     const remainingRows = rowsPerPage - currentRows.length;
// // // // // // // // // // // // //     for (let i = 0; i < remainingRows; i++) {
// // // // // // // // // // // // //       currentRows.push({
// // // // // // // // // // // // //         username: "",
// // // // // // // // // // // // //         publicationTitle: "",
// // // // // // // // // // // // //         noOfBooks: "",
// // // // // // // // // // // // //         contactNumber: "",
// // // // // // // // // // // // //         email: "",
// // // // // // // // // // // // //         orderedDate: "",
// // // // // // // // // // // // //         shippingAddress: "",
// // // // // // // // // // // // //         dispatchDate: "",
// // // // // // // // // // // // //         trackingId: "",
// // // // // // // // // // // // //       });
// // // // // // // // // // // // //     }
// // // // // // // // // // // // //   }

// // // // // // // // // // // // //   const totalPages = Math.ceil(tableData.length / rowsPerPage);

// // // // // // // // // // // // //   const handlePageChange = (pageNumber) => {
// // // // // // // // // // // // //     setCurrentPage(pageNumber);
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   return (
// // // // // // // // // // // // //     <div>
// // // // // // // // // // // // //       {/* Header */}
// // // // // // // // // // // // //       <Header onLogout={handleLogout} />

// // // // // // // // // // // // //       {/* Table container */}
// // // // // // // // // // // // //       <div className="table-container">
// // // // // // // // // // // // //         {loading ? (
// // // // // // // // // // // // //           <div className="loading">Loading...</div>
// // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // //           <div className="table-wrapper">
// // // // // // // // // // // // //             <table className="book-table">
// // // // // // // // // // // // //               <thead>
// // // // // // // // // // // // //                 <tr>
// // // // // // // // // // // // //                   <th>Sl. No.</th>
// // // // // // // // // // // // //                   <th>User Name</th>
// // // // // // // // // // // // //                   <th>Publication Title</th>
// // // // // // // // // // // // //                   <th>No. of Books Ordered</th>
// // // // // // // // // // // // //                   <th>Contact Number</th>
// // // // // // // // // // // // //                   <th>Email ID</th>
// // // // // // // // // // // // //                   <th>Payment Status</th>
// // // // // // // // // // // // //                   <th>Shipping Address</th>
// // // // // // // // // // // // //                   <th>Dispatch Date</th>
// // // // // // // // // // // // //                   <th>Tracking ID</th>
// // // // // // // // // // // // //                   <th>Action</th>
// // // // // // // // // // // // //                 </tr>
// // // // // // // // // // // // //               </thead>
// // // // // // // // // // // // //               <tbody>
// // // // // // // // // // // // //                 {currentRows.map((row, index) => (
// // // // // // // // // // // // //                   <tr key={index}>
// // // // // // // // // // // // //                     <td>{indexOfFirstRow + index + 1}</td>
// // // // // // // // // // // // //                     <td>{row.username}</td>
// // // // // // // // // // // // //                     <td>{row.publicationTitle}</td>
// // // // // // // // // // // // //                     <td>{row.noOfBooks}</td>
// // // // // // // // // // // // //                     <td>{row.contactNumber}</td>
// // // // // // // // // // // // //                     <td>{row.email}</td>
// // // // // // // // // // // // //                     <td>{row.orderedDate}</td>
// // // // // // // // // // // // //                     <td>{row.shippingAddress}</td>
// // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // //                       {row.dispatchDate ? (
// // // // // // // // // // // // //                         <span onClick={() => handleFieldClick(index, "dispatchDate")}>
// // // // // // // // // // // // //                           {row.dispatchDate}
// // // // // // // // // // // // //                         </span>
// // // // // // // // // // // // //                       ) : (
// // // // // // // // // // // // //                         <input
// // // // // // // // // // // // //                           type="date"
// // // // // // // // // // // // //                           value={row.dispatchDate}
// // // // // // // // // // // // //                           onBlur={() => handleFieldBlur(index, "dispatchDate")}
// // // // // // // // // // // // //                           onChange={(e) =>
// // // // // // // // // // // // //                             handleDispatchDateChange(index, e.target.value)
// // // // // // // // // // // // //                           }
// // // // // // // // // // // // //                         />
// // // // // // // // // // // // //                       )}
// // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // //                       {row.trackingId ? (
// // // // // // // // // // // // //                         <span onClick={() => handleFieldClick(index, "trackingId")}>
// // // // // // // // // // // // //                           {row.trackingId}
// // // // // // // // // // // // //                         </span>
// // // // // // // // // // // // //                       ) : (
// // // // // // // // // // // // //                         <input
// // // // // // // // // // // // //                           type="text"
// // // // // // // // // // // // //                           value={row.trackingId}
// // // // // // // // // // // // //                           onBlur={() => handleFieldBlur(index, "trackingId")}
// // // // // // // // // // // // //                           onChange={(e) =>
// // // // // // // // // // // // //                             handleTrackingIdChange(index, e.target.value)
// // // // // // // // // // // // //                           }
// // // // // // // // // // // // //                         />
// // // // // // // // // // // // //                       )}
// // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // //                     <td>
// // // // // // // // // // // // //                       <button
// // // // // // // // // // // // //                         className="submit-btn"
// // // // // // // // // // // // //                         onClick={() => handleSubmit(row)}
// // // // // // // // // // // // //                       >
// // // // // // // // // // // // //                         Submit
// // // // // // // // // // // // //                       </button>
// // // // // // // // // // // // //                     </td>
// // // // // // // // // // // // //                   </tr>
// // // // // // // // // // // // //                 ))}
// // // // // // // // // // // // //               </tbody>
// // // // // // // // // // // // //             </table>

// // // // // // // // // // // // //             {/* Pagination Controls */}
// // // // // // // // // // // // //             <div className="pagination">
// // // // // // // // // // // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // // // // // // // // // // //                 <button
// // // // // // // // // // // // //                   key={index + 1}
// // // // // // // // // // // // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // // // // // // // // // // // //                   onClick={() => handlePageChange(index + 1)}
// // // // // // // // // // // // //                 >
// // // // // // // // // // // // //                   {index + 1}
// // // // // // // // // // // // //                 </button>
// // // // // // // // // // // // //               ))}
// // // // // // // // // // // // //             </div>
// // // // // // // // // // // // //           </div>
// // // // // // // // // // // // //         )}

// // // // // // // // // // // // //         {popupVisible && (
// // // // // // // // // // // // //           <>
// // // // // // // // // // // // //             <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // // //             <div className="popup show">
// // // // // // // // // // // // //               <p>{popupContent}</p>
// // // // // // // // // // // // //             </div>
// // // // // // // // // // // // //           </>
// // // // // // // // // // // // //         )}
// // // // // // // // // // // // //       </div>
// // // // // // // // // // // // //       <Footer />
// // // // // // // // // // // // //     </div>
// // // // // // // // // // // // //   );
// // // // // // // // // // // // // };

// // // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // // // // // // // import Header from "./header"; // Import the Header component
// // // // // // // // // // // // import Footer from "./footer";

// // // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility
// // // // // // // // // // // //   const [currentPage, setCurrentPage] = useState(1); // Current page number
// // // // // // // // // // // //   const rowsPerPage = 9; // Number of rows per page (set to 9 for the first page)

// // // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // //     fetchTableData();
// // // // // // // // // // // //   }, []);

// // // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // // //     const tempData = [
// // // // // // // // // // // //       {
// // // // // // // // // // // //         username: "JohnDoe123",
// // // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // // //         dispatchDate: "",  // Empty until user fills
// // // // // // // // // // // //         trackingId: "",    // Empty until user fills
// // // // // // // // // // // //       },
// // // // // // // // // // // //       {
// // // // // // // // // // // //         username: "JaneSmith456",
// // // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // // //         dispatchDate: "",  // Empty until user fills
// // // // // // // // // // // //         trackingId: "",    // Empty until user fills
// // // // // // // // // // // //       },
// // // // // // // // // // // //       // Add more data here as required to test pagination
// // // // // // // // // // // //     ];

// // // // // // // // // // // //     // Set temporary data
// // // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // // //     setLoading(false);
// // // // // // // // // // // //   };

// // // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // // //     try {
// // // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // // //     }
// // // // // // // // // // // //   };

// // // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // // //   };

// // // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // // //   };

// // // // // // // // // // // //   const handleLogout = () => {
// // // // // // // // // // // //     alert("Log out clicked!");
// // // // // // // // // // // //     // Add your logout logic here
// // // // // // // // // // // //   };

// // // // // // // // // // // //   const handleDispatchDateChange = (index, value) => {
// // // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // // //     updatedData[index].dispatchDate = value;
// // // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // // //   };

// // // // // // // // // // // //   const handleTrackingIdChange = (index, value) => {
// // // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // // //     updatedData[index].trackingId = value;
// // // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // // //   };

// // // // // // // // // // // //   const handleFieldClick = (index, field) => {
// // // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // // //     updatedData[index][field] = true;
// // // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // // //   };

// // // // // // // // // // // //   const handleFieldBlur = (index, field) => {
// // // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // // //     updatedData[index][field] = false;
// // // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // // //   };

// // // // // // // // // // // //   // Logic for pagination
// // // // // // // // // // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // // // // // // // // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // // // // // // // // // //   let currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

// // // // // // // // // // // //   if (currentPage === 1 && currentRows.length < rowsPerPage) {
// // // // // // // // // // // //     const remainingRows = rowsPerPage - currentRows.length;
// // // // // // // // // // // //     for (let i = 0; i < remainingRows; i++) {
// // // // // // // // // // // //       currentRows.push({
// // // // // // // // // // // //         username: "",
// // // // // // // // // // // //         publicationTitle: "",
// // // // // // // // // // // //         noOfBooks: "",
// // // // // // // // // // // //         contactNumber: "",
// // // // // // // // // // // //         email: "",
// // // // // // // // // // // //         orderedDate: "",
// // // // // // // // // // // //         shippingAddress: "",
// // // // // // // // // // // //         dispatchDate: "",
// // // // // // // // // // // //         trackingId: "",
// // // // // // // // // // // //       });
// // // // // // // // // // // //     }
// // // // // // // // // // // //   }

// // // // // // // // // // // //   const totalPages = Math.ceil(tableData.length / rowsPerPage);

// // // // // // // // // // // //   const handlePageChange = (pageNumber) => {
// // // // // // // // // // // //     setCurrentPage(pageNumber);
// // // // // // // // // // // //   };

// // // // // // // // // // // //   return (
// // // // // // // // // // // //     <div>
// // // // // // // // // // // //       {/* Header */}
// // // // // // // // // // // //       <Header onLogout={handleLogout} />

// // // // // // // // // // // //       {/* Table container */}
// // // // // // // // // // // //       <div className="table-container">
// // // // // // // // // // // //         {loading ? (
// // // // // // // // // // // //           <div className="loading">Loading...</div>
// // // // // // // // // // // //         ) : (
// // // // // // // // // // // //           <div className="table-wrapper">
// // // // // // // // // // // //             <table className="book-table">
// // // // // // // // // // // //               <thead>
// // // // // // // // // // // //                 <tr>
// // // // // // // // // // // //                   <th>Sl. No.</th>
// // // // // // // // // // // //                   <th>User Name</th>
// // // // // // // // // // // //                   <th>Publication Title</th>
// // // // // // // // // // // //                   <th>No. of Books Ordered</th>
// // // // // // // // // // // //                   <th>Contact Number</th>
// // // // // // // // // // // //                   <th>Email ID</th>
// // // // // // // // // // // //                   <th>Payment Status</th>
// // // // // // // // // // // //                   <th>Shipping Address</th>
// // // // // // // // // // // //                   <th>Dispatch Date</th>
// // // // // // // // // // // //                   <th>Tracking ID</th>
// // // // // // // // // // // //                   <th>Action</th>
// // // // // // // // // // // //                 </tr>
// // // // // // // // // // // //               </thead>
// // // // // // // // // // // //               <tbody>
// // // // // // // // // // // //                 {currentRows.map((row, index) => (
// // // // // // // // // // // //                   <tr key={index}>
// // // // // // // // // // // //                     <td>{indexOfFirstRow + index + 1}</td>
// // // // // // // // // // // //                     <td>{row.username}</td>
// // // // // // // // // // // //                     <td>{row.publicationTitle}</td>
// // // // // // // // // // // //                     <td>{row.noOfBooks}</td>
// // // // // // // // // // // //                     <td>{row.contactNumber}</td>
// // // // // // // // // // // //                     <td>{row.email}</td>
// // // // // // // // // // // //                     <td>{row.orderedDate}</td>
// // // // // // // // // // // //                     <td>{row.shippingAddress}</td>
// // // // // // // // // // // //                     <td>
// // // // // // // // // // // //   <div className="calendar-wrapper">
// // // // // // // // // // // //     <input
// // // // // // // // // // // //       type="date"
// // // // // // // // // // // //       className="calendar-icon-only"
// // // // // // // // // // // //       value={row.dispatchDate}
// // // // // // // // // // // //       onChange={(e) => handleDispatchDateChange(index, e.target.value)}
// // // // // // // // // // // //     />
// // // // // // // // // // // //     <span className="selected-date">
// // // // // // // // // // // //       {row.dispatchDate || "Select Date"} 
// // // // // // // // // // // //     </span>
// // // // // // // // // // // //   </div>
// // // // // // // // // // // // </td>

// // // // // // // // // // // // <td>
// // // // // // // // // // // //   <input
// // // // // // // // // // // //     type="text"
// // // // // // // // // // // //     className="inline-input"
// // // // // // // // // // // //     value={row.trackingId}
// // // // // // // // // // // //     onChange={(e) => handleTrackingIdChange(index, e.target.value)}
// // // // // // // // // // // //     placeholder="Enter Tracking ID"
// // // // // // // // // // // //   />
// // // // // // // // // // // // </td>


// // // // // // // // // // // //                     <td>
// // // // // // // // // // // //                       <button
// // // // // // // // // // // //                         className="submit-btn"
// // // // // // // // // // // //                         onClick={() => handleSubmit(row)}
// // // // // // // // // // // //                       >
// // // // // // // // // // // //                         Submit
// // // // // // // // // // // //                       </button>
// // // // // // // // // // // //                     </td>
// // // // // // // // // // // //                   </tr>
// // // // // // // // // // // //                 ))}
// // // // // // // // // // // //               </tbody>
// // // // // // // // // // // //             </table>

// // // // // // // // // // // //             {/* Pagination Controls */}
// // // // // // // // // // // //             <div className="pagination">
// // // // // // // // // // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // // // // // // // // // //                 <button
// // // // // // // // // // // //                   key={index + 1}
// // // // // // // // // // // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // // // // // // // // // // //                   onClick={() => handlePageChange(index + 1)}
// // // // // // // // // // // //                 >
// // // // // // // // // // // //                   {index + 1}
// // // // // // // // // // // //                 </button>
// // // // // // // // // // // //               ))}
// // // // // // // // // // // //             </div>
// // // // // // // // // // // //           </div>
// // // // // // // // // // // //         )}

// // // // // // // // // // // //         {popupVisible && (
// // // // // // // // // // // //           <>
// // // // // // // // // // // //             <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // // //             <div className="popup show">
// // // // // // // // // // // //               <p>{popupContent}</p>
// // // // // // // // // // // //             </div>
// // // // // // // // // // // //           </>
// // // // // // // // // // // //         )}
// // // // // // // // // // // //       </div>
// // // // // // // // // // // //       <Footer />
// // // // // // // // // // // //     </div>
// // // // // // // // // // // //   );
// // // // // // // // // // // // };

// // // // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // // // // // // import Header from "./header"; // Import the Header component
// // // // // // // // // // // import Footer from "./footer";

// // // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility
// // // // // // // // // // //   const [currentPage, setCurrentPage] = useState(1); // Current page number
// // // // // // // // // // //   const rowsPerPage = 9; // Number of rows per page (set to 9 for the first page)

// // // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // //     fetchTableData();
// // // // // // // // // // //   }, []);

// // // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // // //     const tempData = [
// // // // // // // // // // //       {
// // // // // // // // // // //         username: "JohnDoe123",
// // // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // // //         dispatchDate: "",  // Empty until user fills
// // // // // // // // // // //         trackingId: "",    // Empty until user fills
// // // // // // // // // // //       },
// // // // // // // // // // //       {
// // // // // // // // // // //         username: "JaneSmith456",
// // // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // // //         dispatchDate: "",  // Empty until user fills
// // // // // // // // // // //         trackingId: "",    // Empty until user fills
// // // // // // // // // // //       },
// // // // // // // // // // //       // Add more data here as required to test pagination
// // // // // // // // // // //     ];

// // // // // // // // // // //     // Set temporary data
// // // // // // // // // // //     setTableData(tempData);
// // // // // // // // // // //     setLoading(false);
// // // // // // // // // // //   };

// // // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // // //     try {
// // // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // // //     } catch (error) {
// // // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // // //     }
// // // // // // // // // // //   };

// // // // // // // // // // //   const handleShowPopup = (columnName, content) => {
// // // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // // //     setPopupContent(content);
// // // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // // //   };

// // // // // // // // // // //   const closePopup = () => {
// // // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // // //   };

// // // // // // // // // // //   const handleLogout = () => {
// // // // // // // // // // //     alert("Log out clicked!");
// // // // // // // // // // //     // Add your logout logic here
// // // // // // // // // // //   };

// // // // // // // // // // //   const handleDispatchDateChange = (index, value) => {
// // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // //     updatedData[index].dispatchDate = value;
// // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // //   };

// // // // // // // // // // //   const handleTrackingIdChange = (index, value) => {
// // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // //     updatedData[index].trackingId = value;
// // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // //   };

// // // // // // // // // // //   const handleFieldClick = (index, field) => {
// // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // //     updatedData[index][field] = true;
// // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // //   };

// // // // // // // // // // //   const handleFieldBlur = (index, field) => {
// // // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // // //     updatedData[index][field] = false;
// // // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // // //   };

// // // // // // // // // // //   // Logic for pagination
// // // // // // // // // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // // // // // // // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // // // // // // // // //   let currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

// // // // // // // // // // //   if (currentPage === 1 && currentRows.length < rowsPerPage) {
// // // // // // // // // // //     const remainingRows = rowsPerPage - currentRows.length;
// // // // // // // // // // //     for (let i = 0; i < remainingRows; i++) {
// // // // // // // // // // //       currentRows.push({
// // // // // // // // // // //         username: "",
// // // // // // // // // // //         publicationTitle: "",
// // // // // // // // // // //         noOfBooks: "",
// // // // // // // // // // //         contactNumber: "",
// // // // // // // // // // //         email: "",
// // // // // // // // // // //         orderedDate: "",
// // // // // // // // // // //         shippingAddress: "",
// // // // // // // // // // //         dispatchDate: "",
// // // // // // // // // // //         trackingId: "",
// // // // // // // // // // //       });
// // // // // // // // // // //     }
// // // // // // // // // // //   }

// // // // // // // // // // //   const totalPages = Math.ceil(tableData.length / rowsPerPage);

// // // // // // // // // // //   const handlePageChange = (pageNumber) => {
// // // // // // // // // // //     setCurrentPage(pageNumber);
// // // // // // // // // // //   };

// // // // // // // // // // //   // Helper function to check if the row has data
// // // // // // // // // // //   const hasData = (row) => {
// // // // // // // // // // //     return row.username && row.publicationTitle && row.noOfBooks;
// // // // // // // // // // //   };

// // // // // // // // // // //   return (
// // // // // // // // // // //     <div>
// // // // // // // // // // //       {/* Header */}
// // // // // // // // // // //       <Header onLogout={handleLogout} />

// // // // // // // // // // //       {/* Table container */}
// // // // // // // // // // //       <div className="table-container">
// // // // // // // // // // //         {loading ? (
// // // // // // // // // // //           <div className="loading">Loading...</div>
// // // // // // // // // // //         ) : (
// // // // // // // // // // //           <div className="table-wrapper">
// // // // // // // // // // //             <table className="book-table">
// // // // // // // // // // //               <thead>
// // // // // // // // // // //                 <tr>
// // // // // // // // // // //                   <th>Sl. No.</th>
// // // // // // // // // // //                   <th>User Name</th>
// // // // // // // // // // //                   <th>Publication Title</th>
// // // // // // // // // // //                   <th>No. of Books Ordered</th>
// // // // // // // // // // //                   <th>Contact Number</th>
// // // // // // // // // // //                   <th>Email ID</th>
// // // // // // // // // // //                   <th>Payment Status</th>
// // // // // // // // // // //                   <th>Shipping Address</th>
// // // // // // // // // // //                   <th>Dispatch Date</th>
// // // // // // // // // // //                   <th>Tracking ID</th>
// // // // // // // // // // //                   <th>Action</th>
// // // // // // // // // // //                 </tr>
// // // // // // // // // // //               </thead>
// // // // // // // // // // //               <tbody>
// // // // // // // // // // //                 {currentRows.map((row, index) => (
// // // // // // // // // // //                   <tr key={index}>
// // // // // // // // // // //                <td>
// // // // // // // // // // //   {hasData(row) ? indexOfFirstRow + index + 1 : ""}
// // // // // // // // // // // </td>

// // // // // // // // // // //                     <td>{row.username}</td>
// // // // // // // // // // //                     <td>{row.publicationTitle}</td>
// // // // // // // // // // //                     <td>{row.noOfBooks}</td>
// // // // // // // // // // //                     <td>{row.contactNumber}</td>
// // // // // // // // // // //                     <td>{row.email}</td>
// // // // // // // // // // //                     <td>{row.orderedDate}</td>
// // // // // // // // // // //                     <td>{row.shippingAddress}</td>
                  
// // // // // // // // // // // <td>
// // // // // // // // // // //   {hasData(row) && (
// // // // // // // // // // //     <div className="calendar-wrapper">
// // // // // // // // // // //       <input
// // // // // // // // // // //         type="date"
// // // // // // // // // // //         className="calendar-icon-only"
// // // // // // // // // // //         value={row.dispatchDate}
// // // // // // // // // // //         onChange={(e) => handleDispatchDateChange(index, e.target.value)}
// // // // // // // // // // //       />
// // // // // // // // // // //       <span className="selected-date">
// // // // // // // // // // //         {row.dispatchDate || "Select Date"}
// // // // // // // // // // //       </span>
// // // // // // // // // // //     </div>
// // // // // // // // // // //   )}
// // // // // // // // // // // </td>

// // // // // // // // // // // <td>
// // // // // // // // // // //   {hasData(row) && (
// // // // // // // // // // //     <input
// // // // // // // // // // //       type="text"
// // // // // // // // // // //       className="inline-input"
// // // // // // // // // // //       value={row.trackingId}
// // // // // // // // // // //       onChange={(e) => handleTrackingIdChange(index, e.target.value)}
// // // // // // // // // // //       placeholder="Enter Tracking ID"
// // // // // // // // // // //     />
// // // // // // // // // // //   )}
// // // // // // // // // // // </td>

// // // // // // // // // // //                     <td>
// // // // // // // // // // //                       {hasData(row) && (
// // // // // // // // // // //                         <button
// // // // // // // // // // //                           className="submit-btn"
// // // // // // // // // // //                           onClick={() => handleSubmit(row)}
// // // // // // // // // // //                         >
// // // // // // // // // // //                           Submit
// // // // // // // // // // //                         </button>
// // // // // // // // // // //                       )}
// // // // // // // // // // //                     </td>
// // // // // // // // // // //                   </tr>
// // // // // // // // // // //                 ))}
// // // // // // // // // // //               </tbody>
// // // // // // // // // // //             </table>

// // // // // // // // // // //             {/* Pagination Controls */}
// // // // // // // // // // //             <div className="pagination">
// // // // // // // // // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // // // // // // // // //                 <button
// // // // // // // // // // //                   key={index + 1}
// // // // // // // // // // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // // // // // // // // // //                   onClick={() => handlePageChange(index + 1)}
// // // // // // // // // // //                 >
// // // // // // // // // // //                   {index + 1}
// // // // // // // // // // //                 </button>
// // // // // // // // // // //               ))}
// // // // // // // // // // //             </div>
// // // // // // // // // // //           </div>
// // // // // // // // // // //         )}

// // // // // // // // // // //         {popupVisible && (
// // // // // // // // // // //           <>
// // // // // // // // // // //             <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // // //             <div className="popup show">
// // // // // // // // // // //               <p>{popupContent}</p>
// // // // // // // // // // //             </div>
// // // // // // // // // // //           </>
// // // // // // // // // // //         )}
// // // // // // // // // // //       </div>
// // // // // // // // // // //       <Footer />
// // // // // // // // // // //     </div>
// // // // // // // // // // //   );
// // // // // // // // // // // };

// // // // // // // // // // // export default BookTableWithAPI;


// // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // import axios from "axios";
// // // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // // // // // import Header from "./header"; // Import the Header component
// // // // // // // // // // import Footer from "./footer";

// // // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility
// // // // // // // // // //   const [currentPage, setCurrentPage] = useState(1); // Current page number
// // // // // // // // // //   const rowsPerPage = 9; // Number of rows per page (set to 9 for the first page)

// // // // // // // // // //   // Fetch data from API on component mount
// // // // // // // // // //   useEffect(() => {
// // // // // // // // // //     fetchTableData();
// // // // // // // // // //   }, []);

// // // // // // // // // //   const fetchTableData = async () => {
// // // // // // // // // //     // Temporary data to simulate API response
// // // // // // // // // //     const tempData = [
// // // // // // // // // //       {
// // // // // // // // // //         username: "JohnDoe123",
// // // // // // // // // //         publicationTitle: "Scribner",
// // // // // // // // // //         noOfBooks: 2,
// // // // // // // // // //         contactNumber: "1234567890",
// // // // // // // // // //         email: "johndoe@example.com",
// // // // // // // // // //         orderedDate: "Paid",
// // // // // // // // // //         shippingAddress: "123 Main St, New York, NY",
// // // // // // // // // //         dispatchDate: "",  // Empty until user fills
// // // // // // // // // //         trackingId: "",    // Empty until user fills
// // // // // // // // // //       },
// // // // // // // // // //       {
// // // // // // // // // //         username: "JaneSmith456",
// // // // // // // // // //         publicationTitle: "Harper Perennial",
// // // // // // // // // //         noOfBooks: 1,
// // // // // // // // // //         contactNumber: "0987654321",
// // // // // // // // // //         email: "janesmith@example.com",
// // // // // // // // // //         orderedDate: "Pending",
// // // // // // // // // //         shippingAddress: "456 Elm St, Los Angeles, CA",
// // // // // // // // // //         dispatchDate: "",  // Empty until user fills
// // // // // // // // // //         trackingId: "",    // Empty until user fills
// // // // // // // // // //       },
// // // // // // // // // //       // Add more data here as required to test pagination
// // // // // // // // // //     ];

// // // // // // // // // //     // Set temporary data
// // // // // // // // // //     setTableData(tempData);
// // // // // // // // // //     setLoading(false);
// // // // // // // // // //   };

// // // // // // // // // //   const handleSubmit = async (row) => {
// // // // // // // // // //     try {
// // // // // // // // // //       const response = await axios.post("https://api.example.com/save-book", row); // Replace with your API endpoint
// // // // // // // // // //       console.log("Save Response:", response.data);
// // // // // // // // // //       alert(`Data for ${row.username} saved successfully!`);
// // // // // // // // // //     } catch (error) {
// // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // //       alert(`Failed to save data for ${row.username}.`);
// // // // // // // // // //     }
// // // // // // // // // //   };

// // // // // // // // // //   const handleShowPopup = (content) => {
// // // // // // // // // //     // When clicked, set the popup content and show it
// // // // // // // // // //     setPopupContent(content);
// // // // // // // // // //     setPopupVisible(true);
// // // // // // // // // //   };

// // // // // // // // // //   const closePopup = () => {
// // // // // // // // // //     setPopupVisible(false);
// // // // // // // // // //   };

// // // // // // // // // //   const handleLogout = () => {
// // // // // // // // // //     alert("Log out clicked!");
// // // // // // // // // //     // Add your logout logic here
// // // // // // // // // //   };

// // // // // // // // // //   const handleDispatchDateChange = (index, value) => {
// // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // //     updatedData[index].dispatchDate = value;
// // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // //   };

// // // // // // // // // //   const handleTrackingIdChange = (index, value) => {
// // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // //     updatedData[index].trackingId = value;
// // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // //   };

// // // // // // // // // //   const handleFieldClick = (index, field) => {
// // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // //     updatedData[index][field] = true;
// // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // //   };

// // // // // // // // // //   const handleFieldBlur = (index, field) => {
// // // // // // // // // //     const updatedData = [...tableData];
// // // // // // // // // //     updatedData[index][field] = false;
// // // // // // // // // //     setTableData(updatedData);
// // // // // // // // // //   };

// // // // // // // // // //   // Logic for pagination
// // // // // // // // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // // // // // // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // // // // // // // //   let currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

// // // // // // // // // //   if (currentPage === 1 && currentRows.length < rowsPerPage) {
// // // // // // // // // //     const remainingRows = rowsPerPage - currentRows.length;
// // // // // // // // // //     for (let i = 0; i < remainingRows; i++) {
// // // // // // // // // //       currentRows.push({
// // // // // // // // // //         username: "",
// // // // // // // // // //         publicationTitle: "",
// // // // // // // // // //         noOfBooks: "",
// // // // // // // // // //         contactNumber: "",
// // // // // // // // // //         email: "",
// // // // // // // // // //         orderedDate: "",
// // // // // // // // // //         shippingAddress: "",
// // // // // // // // // //         dispatchDate: "",
// // // // // // // // // //         trackingId: "",
// // // // // // // // // //       });
// // // // // // // // // //     }
// // // // // // // // // //   }

// // // // // // // // // //   const totalPages = Math.ceil(tableData.length / rowsPerPage);

// // // // // // // // // //   const handlePageChange = (pageNumber) => {
// // // // // // // // // //     setCurrentPage(pageNumber);
// // // // // // // // // //   };

// // // // // // // // // //   // Helper function to check if the row has data
// // // // // // // // // //   const hasData = (row) => {
// // // // // // // // // //     return row.username && row.publicationTitle && row.noOfBooks;
// // // // // // // // // //   };
// // // // // // // // // // const hasdata = (row) => {
// // // // // // // // // //   return row.username && row.publicationTitle && row.noofBooks;
// // // // // // // // // // }

// // // // // // // // // //   return (
// // // // // // // // // //     <div>
// // // // // // // // // //       {/* Header */}
// // // // // // // // // //       <Header onLogout={handleLogout} />

// // // // // // // // // //       {/* Table container */}
// // // // // // // // // //       <div className="table-container">
// // // // // // // // // //         {loading ? (
// // // // // // // // // //           <div className="loading">Loading...</div>
// // // // // // // // // //         ) : (
// // // // // // // // // //           <div className="table-wrapper">
// // // // // // // // // //             <table className="book-table">
// // // // // // // // // //               <thead>
// // // // // // // // // //                 <tr>
// // // // // // // // // //                   <th>Sl. No.</th>
// // // // // // // // // //                   <th>User Name</th>
// // // // // // // // // //                   <th>Publication Title</th>
// // // // // // // // // //                   <th>No. of Books Ordered</th>
// // // // // // // // // //                   <th>Contact Number</th>
// // // // // // // // // //                   <th>Email ID</th>
// // // // // // // // // //                   <th>Payment Status</th>
// // // // // // // // // //                   <th>Shipping Address</th>
// // // // // // // // // //                   <th>Dispatch Date</th>
// // // // // // // // // //                   <th>Tracking ID</th>
// // // // // // // // // //                   <th>Action</th>
// // // // // // // // // //                 </tr>
// // // // // // // // // //               </thead>
// // // // // // // // // //               <tbody>
// // // // // // // // // //                 {currentRows.map((row, index) => (
// // // // // // // // // //                   <tr key={index}>
// // // // // // // // // //                     <td>
// // // // // // // // // //                       {hasData(row) ? indexOfFirstRow + index + 1 : ""}
// // // // // // // // // //                     </td>

// // // // // // // // // //                     <td>{row.username}</td>
// // // // // // // // // //                     <td>{row.publicationTitle}</td>
// // // // // // // // // //                     <td>{row.noOfBooks}</td>
// // // // // // // // // //                     <td>{row.contactNumber}</td>
// // // // // // // // // //                     <td>
// // // // // // // // // //                       <span
// // // // // // // // // //                         className="field-link"
// // // // // // // // // //                         onClick={() => handleShowPopup(row.email)}
// // // // // // // // // //                       >
// // // // // // // // // //                         {row.email}
// // // // // // // // // //                       </span>
// // // // // // // // // //                     </td>
// // // // // // // // // //                     <td>{row.orderedDate}</td>
// // // // // // // // // //                     <td>
// // // // // // // // // //                       <span
// // // // // // // // // //                         className="field-link"
// // // // // // // // // //                         onClick={() => handleShowPopup(row.shippingAddress)}
// // // // // // // // // //                       >
// // // // // // // // // //                         {row.shippingAddress}
// // // // // // // // // //                       </span>
// // // // // // // // // //                     </td>

// // // // // // // // // //                     <td>
// // // // // // // // // //                       {hasData(row) && (
// // // // // // // // // //                         <div className="calendar-wrapper">
// // // // // // // // // //                           <input
// // // // // // // // // //                             type="date"
// // // // // // // // // //                             className="calendar-icon-only"
// // // // // // // // // //                             value={row.dispatchDate}
// // // // // // // // // //                             onChange={(e) => handleDispatchDateChange(index, e.target.value)}
// // // // // // // // // //                           />
// // // // // // // // // //                           <span className="selected-date">
// // // // // // // // // //                             {row.dispatchDate || "Select Date"}
// // // // // // // // // //                           </span>
// // // // // // // // // //                         </div>
// // // // // // // // // //                       )}
// // // // // // // // // //                     </td>

// // // // // // // // // //                     <td>
// // // // // // // // // //                       {hasData(row) && (
// // // // // // // // // //                         <input
// // // // // // // // // //                           type="text"
// // // // // // // // // //                           className="inline-input"
// // // // // // // // // //                           value={row.trackingId}
// // // // // // // // // //                           onChange={(e) => handleTrackingIdChange(index, e.target.value)}
// // // // // // // // // //                           placeholder="Enter Tracking ID"
// // // // // // // // // //                         />
// // // // // // // // // //                       )}
// // // // // // // // // //                     </td>

// // // // // // // // // //                     <td>
// // // // // // // // // //                       {hasData(row) && (
// // // // // // // // // //                         <button
// // // // // // // // // //                           className="submit-btn"
// // // // // // // // // //                           onClick={() => handleSubmit(row)}
// // // // // // // // // //                         >
// // // // // // // // // //                           Submit
// // // // // // // // // //                         </button>
// // // // // // // // // //                       )}
// // // // // // // // // //                     </td>
// // // // // // // // // //                   </tr>
// // // // // // // // // //                 ))}
// // // // // // // // // //               </tbody>
// // // // // // // // // //             </table>

// // // // // // // // // //             {/* Pagination Controls */}
// // // // // // // // // //             <div className="pagination">
// // // // // // // // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // // // // // // // //                 <button
// // // // // // // // // //                   key={index + 1}
// // // // // // // // // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // // // // // // // // //                   onClick={() => handlePageChange(index + 1)}
// // // // // // // // // //                 >
// // // // // // // // // //                   {index + 1}
// // // // // // // // // //                 </button>
// // // // // // // // // //               ))}
// // // // // // // // // //             </div>
// // // // // // // // // //           </div>
// // // // // // // // // //         )}

// // // // // // // // // //         {popupVisible && (
// // // // // // // // // //           <>
// // // // // // // // // //             <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // // //             <div className="popup show">
// // // // // // // // // //               <p>{popupContent}</p>
// // // // // // // // // //             </div>
// // // // // // // // // //           </>
// // // // // // // // // //         )}
// // // // // // // // // //       </div>
// // // // // // // // // //       <Footer />
// // // // // // // // // //     </div>
// // // // // // // // // //   );
// // // // // // // // // // };

// // // // // // // // // // export default BookTableWithAPI;
// // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // import axios from "axios";
// // // // // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // // // // import Header from "./header"; // Import the Header component
// // // // // // // // // import Footer from "./footer";
// // // // // // // // // import "react-datepicker/dist/react-datepicker.css"; // Import styles for DatePicker
// // // // // // // // // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL

// // // // // // // // // const BookTableWithAPI = () => {
// // // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility
// // // // // // // // //   const [currentPage, setCurrentPage] = useState(1); // Current page number
// // // // // // // // //   const [searchQuery, setSearchQuery] = useState(""); // State for search input
// // // // // // // // //   const [rowData, setRowData] = useState({}); // State to store editable row data
// // // // // // // // //   const rowsPerPage = 9; // Number of rows per page

// // // // // // // // //   useEffect(() => {
// // // // // // // // //     fetchData();
// // // // // // // // //   }, []);

// // // // // // // // //   const fetchData = async () => {
// // // // // // // // //     try {
// // // // // // // // //       setLoading(true);
// // // // // // // // //       const response = await axios.get(`${BASE_URL}/dashboard/orders`);

// // // // // // // // //       if (Array.isArray(response.data)) {
// // // // // // // // //         setTableData(response.data); // Update state with the response data
// // // // // // // // //         const initialRowData = response.data.reduce((acc, row) => {
// // // // // // // // //           acc[row.orderId] = {
// // // // // // // // //             dispatchDate: row.dispatchDate || "",
// // // // // // // // //             trackingId: row.trackingId || "",
// // // // // // // // //             deliveryStatus: row.deliveryStatus || "",
// // // // // // // // //           };
// // // // // // // // //           return acc;
// // // // // // // // //         }, {});
// // // // // // // // //         setRowData(initialRowData);
// // // // // // // // //       } else {
// // // // // // // // //         console.error("Invalid data format received:", response.data);
// // // // // // // // //       }
// // // // // // // // //     } catch (error) {
// // // // // // // // //       console.error("Error fetching data:", error);
// // // // // // // // //       alert("Failed to fetch data from the server.");
// // // // // // // // //     } finally {
// // // // // // // // //       setLoading(false);
// // // // // // // // //     }
// // // // // // // // //   };

// // // // // // // // //   const handleShowPopup = (content) => {
// // // // // // // // //     setPopupContent(content);
// // // // // // // // //     setPopupVisible(true);
// // // // // // // // //   };

// // // // // // // // //   const closePopup = () => {
// // // // // // // // //     setPopupVisible(false);
// // // // // // // // //   };

// // // // // // // // //   const handleInputChange = (orderId, field, value) => {
// // // // // // // // //     setRowData((prev) => ({
// // // // // // // // //       ...prev,
// // // // // // // // //       [orderId]: {
// // // // // // // // //         ...prev[orderId],
// // // // // // // // //         [field]: value,
// // // // // // // // //       },
// // // // // // // // //     }));
// // // // // // // // //   };

// // // // // // // // // const handleUpdateRow = async (orderId) => {
// // // // // // // // //   const updatedRow = rowData[orderId]; // Get the updated data for this row
// // // // // // // // //   try {
// // // // // // // // //     // Send PUT request to update the row
// // // // // // // // //     await axios.put(`${BASE_URL}/dashboard/update-orders?orderId=${orderId}`, {
// // // // // // // // //       deliveryStatus: updatedRow.deliveryStatus,
// // // // // // // // //       trackingId: updatedRow.trackingId,
// // // // // // // // //       dispatchDate: updatedRow.dispatchDate,
// // // // // // // // //     });

// // // // // // // // //     alert("Row updated successfully!");
// // // // // // // // //     fetchData(); // Refresh the table data after update
// // // // // // // // //   } catch (error) {
// // // // // // // // //     console.error("Error updating row:", error);
// // // // // // // // //     alert("Failed to update the row. Please try again.");
// // // // // // // // //   }
// // // // // // // // // };



// // // // // // // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // // // // // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // // // // // // //   const filteredData = tableData.filter((row) =>
// // // // // // // // //     row.name.toLowerCase().includes(searchQuery.toLowerCase())
// // // // // // // // //   );

// // // // // // // // //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// // // // // // // // //   const totalPages = Math.ceil(filteredData.length / rowsPerPage);

// // // // // // // // //   const handlePageChange = (pageNumber) => {
// // // // // // // // //     setCurrentPage(pageNumber);
// // // // // // // // //   };

// // // // // // // // //   return (
// // // // // // // // //     <div>
// // // // // // // // //       <Header />

// // // // // // // // //       <div className="search-bar-container">
// // // // // // // // //         <input
// // // // // // // // //           type="text"
// // // // // // // // //           className="search-bar"
// // // // // // // // //           placeholder="Search by username"
// // // // // // // // //           value={searchQuery}
// // // // // // // // //           onChange={(e) => setSearchQuery(e.target.value)}
// // // // // // // // //         />
// // // // // // // // //       </div>

// // // // // // // // //       <div className="table-container">
// // // // // // // // //         {loading ? (
// // // // // // // // //           <div className="loading">Loading...</div>
// // // // // // // // //         ) : (
// // // // // // // // //           <div className="table-wrapper">
// // // // // // // // //             <table className="book-table">
// // // // // // // // //               <thead>
// // // // // // // // //                 <tr>
// // // // // // // // //                   <th>SNo.</th>
// // // // // // // // //                   <th>User Name</th>
// // // // // // // // //                   <th>Publication Title</th>
// // // // // // // // //                   <th>No. of Books Ordered</th>
// // // // // // // // //                   <th>Contact Number</th>
// // // // // // // // //                   <th>Email ID</th>
// // // // // // // // //                   <th>Payment Status</th>
// // // // // // // // //                   <th>Shipping Address</th>
// // // // // // // // //                   <th>Dispatch Date</th>
// // // // // // // // //                   <th>Tracking ID</th>
// // // // // // // // //                   <th>Delivery Status</th>
// // // // // // // // //                   <th>Actions</th>
// // // // // // // // //                 </tr>
// // // // // // // // //               </thead>
// // // // // // // // //               <tbody>
// // // // // // // // //                 {currentRows.length === 0 ? (
// // // // // // // // //                   <tr>
// // // // // // // // //                     <td colSpan="12">No data available</td>
// // // // // // // // //                   </tr>
// // // // // // // // //                 ) : (
// // // // // // // // //                   currentRows.map((row, index) => (
// // // // // // // // //                     <tr key={row.orderId}>
// // // // // // // // //                       <td>{indexOfFirstRow + index + 1}</td>
// // // // // // // // //                       <td>{row.name}</td>
// // // // // // // // //                       <td>{row.publicationTitle}</td>
// // // // // // // // //                       <td>{row.quantity}</td>
// // // // // // // // //                       <td>{row.contact}</td>
// // // // // // // // //                       <td>
// // // // // // // // //                         <span
// // // // // // // // //                           className="field-link"
// // // // // // // // //                           onClick={() => handleShowPopup(row.email)}
// // // // // // // // //                         >
// // // // // // // // //                           {row.email}
// // // // // // // // //                         </span>
// // // // // // // // //                       </td>
// // // // // // // // //                       <td>{row.orderedDate}</td>
// // // // // // // // //                       <td>
// // // // // // // // //                         <span
// // // // // // // // //                           className="field-link"
// // // // // // // // //                           onClick={() => handleShowPopup(row.shipingAddress)}
// // // // // // // // //                         >
// // // // // // // // //                           {row.shipingAddress}
// // // // // // // // //                         </span>
// // // // // // // // //                       </td>
// // // // // // // // //                       <td>
// // // // // // // // //                         <input
// // // // // // // // //                           type="date"
// // // // // // // // //                           value={rowData[row.orderId]?.dispatchDate}
// // // // // // // // //                           onChange={(e) =>
// // // // // // // // //                             handleInputChange(
// // // // // // // // //                               row.orderId,
// // // // // // // // //                               "dispatchDate",
// // // // // // // // //                               e.target.value
// // // // // // // // //                             )
// // // // // // // // //                           }
// // // // // // // // //                         />
// // // // // // // // //                       </td>
// // // // // // // // //                       <td>
// // // // // // // // //                         <input
// // // // // // // // //                           type="text"
// // // // // // // // //                           value={rowData[row.orderId]?.trackingId}
// // // // // // // // //                           placeholder="Enter Tracking ID"
// // // // // // // // //                           onChange={(e) =>
// // // // // // // // //                             handleInputChange(
// // // // // // // // //                               row.orderId,
// // // // // // // // //                               "trackingId",
// // // // // // // // //                               e.target.value
// // // // // // // // //                             )
// // // // // // // // //                           }
// // // // // // // // //                         />
// // // // // // // // //                       </td>
// // // // // // // // //                       <td>
// // // // // // // // //                         <select
// // // // // // // // //                           value={rowData[row.orderId]?.deliveryStatus}
// // // // // // // // //                           onChange={(e) =>
// // // // // // // // //                             handleInputChange(
// // // // // // // // //                               row.orderId,
// // // // // // // // //                               "deliveryStatus",
// // // // // // // // //                               e.target.value
// // // // // // // // //                             )
// // // // // // // // //                           }
// // // // // // // // //                         >
// // // // // // // // //                           <option value="Delivered">Delivered</option>
// // // // // // // // //                           <option value="Processing">Processing</option>
// // // // // // // // //                           <option value="Dispatch">Dispatch</option>
// // // // // // // // //                         </select>
// // // // // // // // //                       </td>
// // // // // // // // //                       <td>
// // // // // // // // //                     <td>
// // // // // // // // //   <button onClick={() => handleUpdateRow(row.orderId)}>Save</button>
// // // // // // // // // </td>
// // // // // // // // //                       </td>
// // // // // // // // //                     </tr>
// // // // // // // // //                   ))
// // // // // // // // //                 )}
// // // // // // // // //               </tbody>
// // // // // // // // //             </table>

// // // // // // // // //             <div className="pagination">
// // // // // // // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // // // // // // //                 <button
// // // // // // // // //                   key={index + 1}
// // // // // // // // //                   className={`page-btn ${
// // // // // // // // //                     currentPage === index + 1 ? "active" : ""
// // // // // // // // //                   }`}
// // // // // // // // //                   onClick={() => handlePageChange(index + 1)}
// // // // // // // // //                 >
// // // // // // // // //                   {index + 1}
// // // // // // // // //                 </button>
// // // // // // // // //               ))}
// // // // // // // // //             </div>
// // // // // // // // //           </div>
// // // // // // // // //         )}
// // // // // // // // //       </div>

// // // // // // // // //       {popupVisible && (
// // // // // // // // //         <>
// // // // // // // // //           <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // // //           <div className="popup">
// // // // // // // // //             <div className="popup-header">
// // // // // // // // //               <h2>Details</h2>
// // // // // // // // //               <button onClick={closePopup} className="close-btn">
// // // // // // // // //                 X
// // // // // // // // //               </button>
// // // // // // // // //             </div>
// // // // // // // // //             <div className="popup-content">
// // // // // // // // //               <p>{popupContent}</p>
// // // // // // // // //             </div>
// // // // // // // // //           </div>
// // // // // // // // //         </>
// // // // // // // // //       )}

// // // // // // // // //       <Footer />
// // // // // // // // //     </div>
// // // // // // // // //   );
// // // // // // // // // };

// // // // // // // // // export default BookTableWithAPI;
// // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // import axios from "axios";
// // // // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // // // import Header from "./header"; // Import the Header component
// // // // // // // // import Footer from "./footer";
// // // // // // // // import DatePicker from "react-datepicker"; // Import DatePicker
// // // // // // // // import "react-datepicker/dist/react-datepicker.css"; // Import styles for DatePicker
// // // // // // // // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL

// // // // // // // // const BookTableWithAPI = () => {
// // // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility
// // // // // // // //   const [currentPage, setCurrentPage] = useState(1); // Current page number
// // // // // // // //   const [searchQuery, setSearchQuery] = useState(""); // State for search input
// // // // // // // //   const [rowData, setRowData] = useState({}); // State to store editable row data
// // // // // // // //   const rowsPerPage = 9; // Number of rows per page

// // // // // // // //   useEffect(() => {
// // // // // // // //     fetchData();
// // // // // // // //   }, []);

// // // // // // // //   const fetchData = async () => {
// // // // // // // //     try {
// // // // // // // //       setLoading(true);
// // // // // // // //       const response = await axios.get(`${BASE_URL}/dashboard/orders`);

// // // // // // // //       if (Array.isArray(response.data)) {
// // // // // // // //         setTableData(response.data); // Update state with the response data
// // // // // // // //         const initialRowData = response.data.reduce((acc, row) => {
// // // // // // // //           acc[row.orderId] = {
// // // // // // // //             dispatchDate: row.dispatchDate || "",
// // // // // // // //             trackingId: row.trackingId || "",
// // // // // // // //             deliveryStatus: row.deliveryStatus || "",
// // // // // // // //           };
// // // // // // // //           return acc;
// // // // // // // //         }, {});
// // // // // // // //         setRowData(initialRowData);
// // // // // // // //       } else {
// // // // // // // //         console.error("Invalid data format received:", response.data);
// // // // // // // //       }
// // // // // // // //     } catch (error) {
// // // // // // // //       console.error("Error fetching data:", error);
// // // // // // // //       alert("Failed to fetch data from the server.");
// // // // // // // //     } finally {
// // // // // // // //       setLoading(false);
// // // // // // // //     }
// // // // // // // //   };

// // // // // // // //   const handleShowPopup = (content) => {
// // // // // // // //     setPopupContent(content);
// // // // // // // //     setPopupVisible(true);
// // // // // // // //   };

// // // // // // // //   const closePopup = () => {
// // // // // // // //     setPopupVisible(false);
// // // // // // // //   };

// // // // // // // //   const handleInputChange = (orderId, field, value) => {
// // // // // // // //     setRowData((prev) => ({
// // // // // // // //       ...prev,
// // // // // // // //       [orderId]: {
// // // // // // // //         ...prev[orderId],
// // // // // // // //         [field]: value,
// // // // // // // //       },
// // // // // // // //     }));
// // // // // // // //   };

// // // // // // // //   const handleUpdateRow = async (orderId) => {
// // // // // // // //     const updatedRow = rowData[orderId]; // Get the updated data for this row
// // // // // // // //     try {
// // // // // // // //       // Send PUT request to update the row
// // // // // // // //       await axios.put(`${BASE_URL}/dashboard/update-orders?orderId=${orderId}`, {
// // // // // // // //         deliveryStatus: updatedRow.deliveryStatus,
// // // // // // // //         trackingId: updatedRow.trackingId,
// // // // // // // //         dispatchDate: updatedRow.dispatchDate,
// // // // // // // //       });

// // // // // // // //       alert("Row updated successfully!");
// // // // // // // //       fetchData(); // Refresh the table data after update
// // // // // // // //     } catch (error) {
// // // // // // // //       console.error("Error updating row:", error);
// // // // // // // //       alert("Failed to update the row. Please try again.");
// // // // // // // //     }
// // // // // // // //   };

// // // // // // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // // // // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // // // // // //   const filteredData = tableData.filter((row) =>
// // // // // // // //     row.name.toLowerCase().includes(searchQuery.toLowerCase())
// // // // // // // //   );

// // // // // // // //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// // // // // // // //   const totalPages = Math.ceil(filteredData.length / rowsPerPage);

// // // // // // // //   const handlePageChange = (pageNumber) => {
// // // // // // // //     setCurrentPage(pageNumber);
// // // // // // // //   };

// // // // // // // //   return (
// // // // // // // //     <div>
// // // // // // // //       <Header />

// // // // // // // //       <div className="search-bar-container">
// // // // // // // //         <input
// // // // // // // //           type="text"
// // // // // // // //           className="search-bar"
// // // // // // // //           placeholder="Search by username"
// // // // // // // //           value={searchQuery}
// // // // // // // //           onChange={(e) => setSearchQuery(e.target.value)}
// // // // // // // //         />
// // // // // // // //       </div>

// // // // // // // //       <div className="table-container">
// // // // // // // //         {loading ? (
// // // // // // // //           <div className="loading">Loading...</div>
// // // // // // // //         ) : (
// // // // // // // //           <div className="table-wrapper">
// // // // // // // //             <table className="book-table">
// // // // // // // //               <thead>
// // // // // // // //                 <tr>
// // // // // // // //                   <th>SNo.</th>
// // // // // // // //                   <th>User Name</th>
// // // // // // // //                   <th>Publication Title</th>
// // // // // // // //                   <th>No. of Books Ordered</th>
// // // // // // // //                   <th>Contact Number</th>
// // // // // // // //                   <th>Email ID</th>
// // // // // // // //                   <th>Payment Status</th>
// // // // // // // //                   <th>Shipping Address</th>
// // // // // // // //                   <th>Dispatch Date</th>
// // // // // // // //                   <th>Tracking ID</th>
// // // // // // // //                   <th>Delivery Status</th>
// // // // // // // //                   <th>Actions</th>
// // // // // // // //                 </tr>
// // // // // // // //               </thead>
// // // // // // // //               <tbody>
// // // // // // // //                 {currentRows.length === 0 ? (
// // // // // // // //                   <tr>
// // // // // // // //                     <td colSpan="12">No data available</td>
// // // // // // // //                   </tr>
// // // // // // // //                 ) : (
// // // // // // // //                   currentRows.map((row, index) => (
// // // // // // // //                     <tr key={row.orderId}>
// // // // // // // //                       <td>{indexOfFirstRow + index + 1}</td>
// // // // // // // //                       <td>{row.name}</td>
// // // // // // // //                       <td>{row.publicationTitle}</td>
// // // // // // // //                       <td>{row.quantity}</td>
// // // // // // // //                       <td>{row.contact}</td>
// // // // // // // //                       <td>
// // // // // // // //                         <span
// // // // // // // //                           className="field-link"
// // // // // // // //                           onClick={() => handleShowPopup(row.email)}
// // // // // // // //                         >
// // // // // // // //                           {row.email}
// // // // // // // //                         </span>
// // // // // // // //                       </td>
// // // // // // // //                       <td>{row.orderedDate}</td>
// // // // // // // //                       <td>
// // // // // // // //                         <span
// // // // // // // //                           className="field-link"
// // // // // // // //                           onClick={() => handleShowPopup(row.shipingAddress)}
// // // // // // // //                         >
// // // // // // // //                           {row.shipingAddress}
// // // // // // // //                         </span>
// // // // // // // //                       </td>
// // // // // // // //                       <td>
// // // // // // // //                         <DatePicker
// // // // // // // //                           selected={rowData[row.orderId]?.dispatchDate ? new Date(rowData[row.orderId]?.dispatchDate) : null}
// // // // // // // //                           onChange={(date) => handleInputChange(row.orderId, "dispatchDate", date)}
// // // // // // // //                           dateFormat="dd/MM/yyyy"
// // // // // // // //                           isClearable
// // // // // // // //                           showMonthDropdown
// // // // // // // //                           showYearDropdown
// // // // // // // //                           dropdownMode="select"
// // // // // // // //                           className="datepicker-input"
// // // // // // // //                         />
// // // // // // // //                       </td>
// // // // // // // //                       <td>
// // // // // // // //                         <input
// // // // // // // //                           type="text"
// // // // // // // //                           value={rowData[row.orderId]?.trackingId}
// // // // // // // //                           placeholder="Enter Tracking ID"
// // // // // // // //                           onChange={(e) =>
// // // // // // // //                             handleInputChange(
// // // // // // // //                               row.orderId,
// // // // // // // //                               "trackingId",
// // // // // // // //                               e.target.value
// // // // // // // //                             )
// // // // // // // //                           }
// // // // // // // //                         />
// // // // // // // //                       </td>
// // // // // // // //                       <td>
// // // // // // // //                         <select
// // // // // // // //                           value={rowData[row.orderId]?.deliveryStatus}
// // // // // // // //                           onChange={(e) =>
// // // // // // // //                             handleInputChange(
// // // // // // // //                               row.orderId,
// // // // // // // //                               "deliveryStatus",
// // // // // // // //                               e.target.value
// // // // // // // //                             )
// // // // // // // //                           }
// // // // // // // //                         >
// // // // // // // //                           <option value="Delivered">Delivered</option>
// // // // // // // //                           <option value="Processing">Processing</option>
// // // // // // // //                           <option value="Dispatch">Dispatch</option>
// // // // // // // //                         </select>
// // // // // // // //                       </td>
// // // // // // // //                       <td>
// // // // // // // //                         <button onClick={() => handleUpdateRow(row.orderId)}>
// // // // // // // //                           Save
// // // // // // // //                         </button>
// // // // // // // //                       </td>
// // // // // // // //                     </tr>
// // // // // // // //                   ))
// // // // // // // //                 )}
// // // // // // // //               </tbody>
// // // // // // // //             </table>

// // // // // // // //             <div className="pagination">
// // // // // // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // // // // // //                 <button
// // // // // // // //                   key={index + 1}
// // // // // // // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // // // // // // //                   onClick={() => handlePageChange(index + 1)}
// // // // // // // //                 >
// // // // // // // //                   {index + 1}
// // // // // // // //                 </button>
// // // // // // // //               ))}
// // // // // // // //             </div>
// // // // // // // //           </div>
// // // // // // // //         )}
// // // // // // // //       </div>

// // // // // // // //       {popupVisible && (
// // // // // // // //         <>
// // // // // // // //           <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // // //           <div className="popup">
// // // // // // // //             <div className="popup-header">
// // // // // // // //               <h2>Details</h2>
// // // // // // // //               <button onClick={closePopup} className="close-btn">
// // // // // // // //                 X
// // // // // // // //               </button>
// // // // // // // //             </div>
// // // // // // // //             <div className="popup-content">
// // // // // // // //               <p>{popupContent}</p>
// // // // // // // //             </div>
// // // // // // // //           </div>
// // // // // // // //         </>
// // // // // // // //       )}

// // // // // // // //       <Footer />
// // // // // // // //     </div>
// // // // // // // //   );
// // // // // // // // };

// // // // // // // // export default BookTableWithAPI;
// // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // import axios from "axios";
// // // // // // // import { FaCalendarAlt } from "react-icons/fa"; // Import the calendar icon from react-icons
// // // // // // // import DatePicker from "react-datepicker"; // Import DatePicker
// // // // // // // import "react-datepicker/dist/react-datepicker.css"; // Import styles for DatePicker
// // // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // // import Header from "./header"; // Import the Header component
// // // // // // // import Footer from "./footer";
// // // // // // // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL

// // // // // // // const BookTableWithAPI = () => {
// // // // // // //   const [tableData, setTableData] = useState([]);
// // // // // // //   const [loading, setLoading] = useState(true);
// // // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility
// // // // // // //   const [currentPage, setCurrentPage] = useState(1); // Current page number
// // // // // // //   const [searchQuery, setSearchQuery] = useState(""); // State for search input
// // // // // // //   const [rowData, setRowData] = useState({}); // State to store editable row data
// // // // // // //   const rowsPerPage = 9; // Number of rows per page

// // // // // // //   useEffect(() => {
// // // // // // //     fetchData();
// // // // // // //   }, []);

// // // // // // //   const fetchData = async () => {
// // // // // // //     try {
// // // // // // //       setLoading(true);
// // // // // // //       const response = await axios.get(`${BASE_URL}/dashboard/orders`);

// // // // // // //       if (Array.isArray(response.data)) {
// // // // // // //         setTableData(response.data); // Update state with the response data
// // // // // // //         const initialRowData = response.data.reduce((acc, row) => {
// // // // // // //           acc[row.orderId] = {
// // // // // // //             dispatchDate: row.dispatchDate || "",
// // // // // // //             trackingId: row.trackingId || "",
// // // // // // //             deliveryStatus: row.deliveryStatus || "",
// // // // // // //           };
// // // // // // //           return acc;
// // // // // // //         }, {});
// // // // // // //         setRowData(initialRowData);
// // // // // // //       } else {
// // // // // // //         console.error("Invalid data format received:", response.data);
// // // // // // //       }
// // // // // // //     } catch (error) {
// // // // // // //       console.error("Error fetching data:", error);
// // // // // // //       alert("Failed to fetch data from the server.");
// // // // // // //     } finally {
// // // // // // //       setLoading(false);
// // // // // // //     }
// // // // // // //   };

// // // // // // //   const handleShowPopup = (content) => {
// // // // // // //     setPopupContent(content);
// // // // // // //     setPopupVisible(true);
// // // // // // //   };

// // // // // // //   const closePopup = () => {
// // // // // // //     setPopupVisible(false);
// // // // // // //   };

// // // // // // //   const handleInputChange = (orderId, field, value) => {
// // // // // // //     setRowData((prev) => ({
// // // // // // //       ...prev,
// // // // // // //       [orderId]: {
// // // // // // //         ...prev[orderId],
// // // // // // //         [field]: value,
// // // // // // //       },
// // // // // // //     }));
// // // // // // //   };

// // // // // // //   const handleUpdateRow = async (orderId) => {
// // // // // // //     const updatedRow = rowData[orderId]; // Get the updated data for this row
// // // // // // //     try {
// // // // // // //       // Send PUT request to update the row
// // // // // // //       await axios.put(`${BASE_URL}/dashboard/update-orders?orderId=${orderId}`, {
// // // // // // //         deliveryStatus: updatedRow.deliveryStatus,
// // // // // // //         trackingId: updatedRow.trackingId,
// // // // // // //         dispatchDate: updatedRow.dispatchDate,
// // // // // // //       });

// // // // // // //       alert("Row updated successfully!");
// // // // // // //       fetchData(); // Refresh the table data after update
// // // // // // //     } catch (error) {
// // // // // // //       console.error("Error updating row:", error);
// // // // // // //       alert("Failed to update the row. Please try again.");
// // // // // // //     }
// // // // // // //   };

// // // // // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // // // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // // // // //   const filteredData = tableData.filter((row) =>
// // // // // // //     row.name.toLowerCase().includes(searchQuery.toLowerCase())
// // // // // // //   );

// // // // // // //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// // // // // // //   const totalPages = Math.ceil(filteredData.length / rowsPerPage);

// // // // // // //   const handlePageChange = (pageNumber) => {
// // // // // // //     setCurrentPage(pageNumber);
// // // // // // //   };

// // // // // // //   // State to control if the calendar is open
// // // // // // //   const [calendarOpen, setCalendarOpen] = useState(false);

// // // // // // //   return (
// // // // // // //     <div>
// // // // // // //       <Header />

// // // // // // //       <div className="search-bar-container">
// // // // // // //         <input
// // // // // // //           type="text"
// // // // // // //           className="search-bar"
// // // // // // //           placeholder="Search by username"
// // // // // // //           value={searchQuery}
// // // // // // //           onChange={(e) => setSearchQuery(e.target.value)}
// // // // // // //         />
// // // // // // //       </div>

// // // // // // //       <div className="table-container">
// // // // // // //         {loading ? (
// // // // // // //           <div className="loading">Loading...</div>
// // // // // // //         ) : (
// // // // // // //           <div className="table-wrapper">
// // // // // // //             <table className="book-table">
// // // // // // //               <thead>
// // // // // // //                 <tr>
// // // // // // //                   <th>SNo.</th>
// // // // // // //                   <th>User Name</th>
// // // // // // //                   <th>Publication Title</th>
// // // // // // //                   <th>No. of Books Ordered</th>
// // // // // // //                   <th>Contact Number</th>
// // // // // // //                   <th>Email ID</th>
// // // // // // //                   <th>Payment Status</th>
// // // // // // //                   <th>Shipping Address</th>
// // // // // // //                   <th>Dispatch Date</th>
// // // // // // //                   <th>Tracking ID</th>
// // // // // // //                   <th>Delivery Status</th>
// // // // // // //                   <th>Actions</th>
// // // // // // //                 </tr>
// // // // // // //               </thead>
// // // // // // //               <tbody>
// // // // // // //                 {currentRows.length === 0 ? (
// // // // // // //                   <tr>
// // // // // // //                     <td colSpan="12">No data available</td>
// // // // // // //                   </tr>
// // // // // // //                 ) : (
// // // // // // //                   currentRows.map((row, index) => (
// // // // // // //                     <tr key={row.orderId}>
// // // // // // //                       <td>{indexOfFirstRow + index + 1}</td>
// // // // // // //                       <td>{row.name}</td>
// // // // // // //                       <td>{row.publicationTitle}</td>
// // // // // // //                       <td>{row.quantity}</td>
// // // // // // //                       <td>{row.contact}</td>
// // // // // // //                       <td>
// // // // // // //                         <span
// // // // // // //                           className="field-link"
// // // // // // //                           onClick={() => handleShowPopup(row.email)}
// // // // // // //                         >
// // // // // // //                           {row.email}
// // // // // // //                         </span>
// // // // // // //                       </td>
// // // // // // //                       <td>{row.orderedDate}</td>
// // // // // // //                       <td>
// // // // // // //                         <span
// // // // // // //                           className="field-link"
// // // // // // //                           onClick={() => handleShowPopup(row.shipingAddress)}
// // // // // // //                         >
// // // // // // //                           {row.shipingAddress}
// // // // // // //                         </span>
// // // // // // //                       </td>
// // // // // // //                       <td>
// // // // // // //                         {/* Calendar Icon Button */}
// // // // // // //                         <button
// // // // // // //                           className="calendar-icon-btn"
// // // // // // //                           onClick={() => setCalendarOpen(!calendarOpen)} // Toggle calendar visibility
// // // // // // //                         >
// // // // // // //                           <FaCalendarAlt /> {/* Calendar icon */}
// // // // // // //                         </button>

// // // // // // //                         {calendarOpen && (
// // // // // // //                           <DatePicker
// // // // // // //                             selected={
// // // // // // //                               rowData[row.orderId]?.dispatchDate
// // // // // // //                                 ? new Date(rowData[row.orderId]?.dispatchDate)
// // // // // // //                                 : null
// // // // // // //                             }
// // // // // // //                             onChange={(date) =>
// // // // // // //                               handleInputChange(row.orderId, "dispatchDate", date)
// // // // // // //                             }
// // // // // // //                             dateFormat="dd/MM/yyyy"
// // // // // // //                             isClearable
// // // // // // //                             showMonthDropdown
// // // // // // //                             showYearDropdown
// // // // // // //                             dropdownMode="select"
// // // // // // //                             className="datepicker-input"
// // // // // // //                           />
// // // // // // //                         )}
// // // // // // //                       </td>
// // // // // // //                       <td>
// // // // // // //                         <input
// // // // // // //                           type="text"
// // // // // // //                           value={rowData[row.orderId]?.trackingId}
// // // // // // //                           placeholder="Enter Tracking ID"
// // // // // // //                           onChange={(e) =>
// // // // // // //                             handleInputChange(
// // // // // // //                               row.orderId,
// // // // // // //                               "trackingId",
// // // // // // //                               e.target.value
// // // // // // //                             )
// // // // // // //                           }
// // // // // // //                         />
// // // // // // //                       </td>
// // // // // // //                       <td>
// // // // // // //                         <select
// // // // // // //                           value={rowData[row.orderId]?.deliveryStatus}
// // // // // // //                           onChange={(e) =>
// // // // // // //                             handleInputChange(
// // // // // // //                               row.orderId,
// // // // // // //                               "deliveryStatus",
// // // // // // //                               e.target.value
// // // // // // //                             )
// // // // // // //                           }
// // // // // // //                         >
// // // // // // //                           <option value="Delivered">Delivered</option>
// // // // // // //                           <option value="Processing">Processing</option>
// // // // // // //                           <option value="Dispatch">Dispatch</option>
// // // // // // //                         </select>
// // // // // // //                       </td>
// // // // // // //                       <td>
// // // // // // //                         <button onClick={() => handleUpdateRow(row.orderId)}>
// // // // // // //                           Save
// // // // // // //                         </button>
// // // // // // //                       </td>
// // // // // // //                     </tr>
// // // // // // //                   ))
// // // // // // //                 )}
// // // // // // //               </tbody>
// // // // // // //             </table>

// // // // // // //             <div className="pagination">
// // // // // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // // // // //                 <button
// // // // // // //                   key={index + 1}
// // // // // // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // // // // // //                   onClick={() => handlePageChange(index + 1)}
// // // // // // //                 >
// // // // // // //                   {index + 1}
// // // // // // //                 </button>
// // // // // // //               ))}
// // // // // // //             </div>
// // // // // // //           </div>
// // // // // // //         )}
// // // // // // //       </div>

// // // // // // //       {popupVisible && (
// // // // // // //         <>
// // // // // // //           <div className="popup-overlay" onClick={closePopup}></div>
// // // // // // //           <div className="popup">
// // // // // // //             <div className="popup-header">
// // // // // // //               <h2>Details</h2>
// // // // // // //               <button onClick={closePopup} className="close-btn">
// // // // // // //                 X
// // // // // // //               </button>
// // // // // // //             </div>
// // // // // // //             <div className="popup-content">
// // // // // // //               <p>{popupContent}</p>
// // // // // // //             </div>
// // // // // // //           </div>
// // // // // // //         </>
// // // // // // //       )}

// // // // // // //       <Footer />
// // // // // // //     </div>
// // // // // // //   );
// // // // // // // };

// // // // // // // export default BookTableWithAPI;
// // // // // // import React, { useState, useEffect } from "react";
// // // // // // import axios from "axios";
// // // // // // import { FaCalendarAlt } from "react-icons/fa"; // Import the calendar icon from react-icons
// // // // // // import DatePicker from "react-datepicker"; // Import DatePicker
// // // // // // import "react-datepicker/dist/react-datepicker.css"; // Import styles for DatePicker
// // // // // // import "./bookinfo.scss"; // External CSS for professional design
// // // // // // import Header from "./header"; // Import the Header component
// // // // // // import Footer from "./footer";
// // // // // // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL

// // // // // // const BookTableWithAPI = () => {
// // // // // //   const [tableData, setTableData] = useState([]);
// // // // // //   const [loading, setLoading] = useState(true);
// // // // // //   const [popupContent, setPopupContent] = useState(""); // State for popup content
// // // // // //   const [popupVisible, setPopupVisible] = useState(false); // State to control popup visibility
// // // // // //   const [currentPage, setCurrentPage] = useState(1); // Current page number
// // // // // //   const [searchQuery, setSearchQuery] = useState(""); // State for search input
// // // // // //   const [rowData, setRowData] = useState({}); // State to store editable row data
// // // // // //   const rowsPerPage = 9; // Number of rows per page

// // // // // //   useEffect(() => {
// // // // // //     fetchData();
// // // // // //   }, []);

// // // // // //   const fetchData = async () => {
// // // // // //     try {
// // // // // //       setLoading(true);
// // // // // //       const response = await axios.get(`${BASE_URL}/dashboard/orders`);

// // // // // //       if (Array.isArray(response.data)) {
// // // // // //         setTableData(response.data); // Update state with the response data
// // // // // //         const initialRowData = response.data.reduce((acc, row) => {
// // // // // //           acc[row.orderId] = {
// // // // // //             dispatchDate: row.dispatchDate || "",
// // // // // //             trackingId: row.trackingId || "",
// // // // // //             deliveryStatus: row.deliveryStatus || "",
// // // // // //           };
// // // // // //           return acc;
// // // // // //         }, {});
// // // // // //         setRowData(initialRowData);
// // // // // //       } else {
// // // // // //         console.error("Invalid data format received:", response.data);
// // // // // //       }
// // // // // //     } catch (error) {
// // // // // //       console.error("Error fetching data:", error);
// // // // // //       alert("Failed to fetch data from the server.");
// // // // // //     } finally {
// // // // // //       setLoading(false);
// // // // // //     }
// // // // // //   };

// // // // // //   const handleShowPopup = (content) => {
// // // // // //     setPopupContent(content);
// // // // // //     setPopupVisible(true);
// // // // // //   };

// // // // // //   const closePopup = () => {
// // // // // //     setPopupVisible(false);
// // // // // //   };

// // // // // //   const handleInputChange = (orderId, field, value) => {
// // // // // //     setRowData((prev) => ({
// // // // // //       ...prev,
// // // // // //       [orderId]: {
// // // // // //         ...prev[orderId],
// // // // // //         [field]: value,
// // // // // //       },
// // // // // //     }));
// // // // // //   };

// // // // // //   const handleUpdateRow = async (orderId) => {
// // // // // //     const updatedRow = rowData[orderId]; // Get the updated data for this row
// // // // // //     try {
// // // // // //       // Send PUT request to update the row
// // // // // //       await axios.put(`${BASE_URL}/dashboard/update-orders?orderId=${orderId}`, {
// // // // // //         deliveryStatus: updatedRow.deliveryStatus,
// // // // // //         trackingId: updatedRow.trackingId,
// // // // // //         dispatchDate: updatedRow.dispatchDate,
// // // // // //       });

// // // // // //       alert("Row updated successfully!");
// // // // // //       fetchData(); // Refresh the table data after update
// // // // // //     } catch (error) {
// // // // // //       console.error("Error updating row:", error);
// // // // // //       alert("Failed to update the row. Please try again.");
// // // // // //     }
// // // // // //   };

// // // // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // // // //   const filteredData = tableData.filter((row) =>
// // // // // //     row.name.toLowerCase().includes(searchQuery.toLowerCase())
// // // // // //   );

// // // // // //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// // // // // //   const totalPages = Math.ceil(filteredData.length / rowsPerPage);

// // // // // //   const handlePageChange = (pageNumber) => {
// // // // // //     setCurrentPage(pageNumber);
// // // // // //   };

// // // // // //   // State to control if the calendar is open
// // // // // //   const [calendarOpen, setCalendarOpen] = useState(false);

// // // // // //   return (
// // // // // //     <div>
// // // // // //       <Header />

// // // // // //       <div className="search-bar-container">
// // // // // //         <input
// // // // // //           type="text"
// // // // // //           className="search-bar"
// // // // // //           placeholder="Search by username"
// // // // // //           value={searchQuery}
// // // // // //           onChange={(e) => setSearchQuery(e.target.value)}
// // // // // //         />
// // // // // //       </div>

// // // // // //       <div className="table-container">
// // // // // //         {loading ? (
// // // // // //           <div className="loading">Loading...</div>
// // // // // //         ) : (
// // // // // //           <div className="table-wrapper">
// // // // // //             <table className="book-table">
// // // // // //               <thead>
// // // // // //                 <tr>
// // // // // //                   <th>SNo.</th>
// // // // // //                   <th>User Name</th>
// // // // // //                   <th>Publication Title</th>
// // // // // //                   <th>No. of Books Ordered</th>
// // // // // //                   <th>Contact Number</th>
// // // // // //                   <th>Email ID</th>
// // // // // //                   <th>Payment Status</th>
// // // // // //                   <th>Shipping Address</th>
// // // // // //                   <th>Dispatch Date</th>
// // // // // //                   <th>Tracking ID</th>
// // // // // //                   <th>Delivery Status</th>
// // // // // //                   <th>Actions</th>
// // // // // //                 </tr>
// // // // // //               </thead>
// // // // // //               <tbody>
// // // // // //                 {currentRows.length === 0 ? (
// // // // // //                   <tr>
// // // // // //                     <td colSpan="12">No data available</td>
// // // // // //                   </tr>
// // // // // //                 ) : (
// // // // // //                   currentRows.map((row, index) => (
// // // // // //                     <tr key={row.orderId}>
// // // // // //                       <td>{indexOfFirstRow + index + 1}</td>
// // // // // //                       <td>{row.name}</td>
// // // // // //                       <td>{row.publicationTitle}</td>
// // // // // //                       <td>{row.quantity}</td>
// // // // // //                       <td>{row.contact}</td>
// // // // // //                       <td>
// // // // // //                         <span
// // // // // //                           className="field-link"
// // // // // //                           onClick={() => handleShowPopup(row.email)}
// // // // // //                         >
// // // // // //                           {row.email}
// // // // // //                         </span>
// // // // // //                       </td>
// // // // // //                       <td>{row.orderedDate}</td>
// // // // // //                       <td>
// // // // // //                         <span
// // // // // //                           className="field-link"
// // // // // //                           onClick={() => handleShowPopup(row.shipingAddress)}
// // // // // //                         >
// // // // // //                           {row.shipingAddress}
// // // // // //                         </span>
// // // // // //                       </td>
// // // // // //                       <td>
// // // // // //                         {/* If no dispatch date, show calendar icon */}
// // // // // //                         {!rowData[row.orderId]?.dispatchDate ? (
// // // // // //                           <button
// // // // // //                             className="calendar-icon-btn"
// // // // // //                             onClick={() => setCalendarOpen(!calendarOpen)} // Toggle calendar visibility
// // // // // //                           >
// // // // // //                             <FaCalendarAlt /> {/* Calendar icon */}
// // // // // //                           </button>
// // // // // //                         ) : (
// // // // // //                           <span>{rowData[row.orderId]?.dispatchDate}</span> // Show the selected date
// // // // // //                         )}

// // // // // //                         {calendarOpen && (
// // // // // //                           <DatePicker
// // // // // //                             selected={
// // // // // //                               rowData[row.orderId]?.dispatchDate
// // // // // //                                 ? new Date(rowData[row.orderId]?.dispatchDate)
// // // // // //                                 : null
// // // // // //                             }
// // // // // //                             onChange={(date) =>
// // // // // //                               handleInputChange(row.orderId, "dispatchDate", date)
// // // // // //                             }
// // // // // //                             dateFormat="dd/MM/yyyy"
// // // // // //                             isClearable
// // // // // //                             showMonthDropdown
// // // // // //                             showYearDropdown
// // // // // //                             dropdownMode="select"
// // // // // //                             className="datepicker-input"
// // // // // //                           />
// // // // // //                         )}
// // // // // //                       </td>
// // // // // //                       <td>
// // // // // //                         <input
// // // // // //                           type="text"
// // // // // //                           value={rowData[row.orderId]?.trackingId}
// // // // // //                           placeholder="Enter Tracking ID"
// // // // // //                           onChange={(e) =>
// // // // // //                             handleInputChange(
// // // // // //                               row.orderId,
// // // // // //                               "trackingId",
// // // // // //                               e.target.value
// // // // // //                             )
// // // // // //                           }
// // // // // //                         />
// // // // // //                       </td>
// // // // // //                       <td>
// // // // // //                         <select
// // // // // //                           value={rowData[row.orderId]?.deliveryStatus}
// // // // // //                           onChange={(e) =>
// // // // // //                             handleInputChange(
// // // // // //                               row.orderId,
// // // // // //                               "deliveryStatus",
// // // // // //                               e.target.value
// // // // // //                             )
// // // // // //                           }
// // // // // //                         >
// // // // // //                           <option value="Delivered">Delivered</option>
// // // // // //                           <option value="Processing">Processing</option>
// // // // // //                           <option value="Dispatch">Dispatch</option>
// // // // // //                         </select>
// // // // // //                       </td>
// // // // // //                       <td>
// // // // // //                         <button onClick={() => handleUpdateRow(row.orderId)}>
// // // // // //                           Save
// // // // // //                         </button>
// // // // // //                       </td>
// // // // // //                     </tr>
// // // // // //                   ))
// // // // // //                 )}
// // // // // //               </tbody>
// // // // // //             </table>

// // // // // //             <div className="pagination">
// // // // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // // // //                 <button
// // // // // //                   key={index + 1}
// // // // // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // // // // //                   onClick={() => handlePageChange(index + 1)}
// // // // // //                 >
// // // // // //                   {index + 1}
// // // // // //                 </button>
// // // // // //               ))}
// // // // // //             </div>
// // // // // //           </div>
// // // // // //         )}
// // // // // //       </div>

// // // // // //       {popupVisible && (
// // // // // //         <>
// // // // // //           <div className="popup-overlay" onClick={closePopup}></div>
// // // // // //           <div className="popup">
// // // // // //             <div className="popup-header">
// // // // // //               <h2>Details</h2>
// // // // // //               <button onClick={closePopup} className="close-btn">
// // // // // //                 X
// // // // // //               </button>
// // // // // //             </div>
// // // // // //             <div className="popup-content">
// // // // // //               <p>{popupContent}</p>
// // // // // //             </div>
// // // // // //           </div>
// // // // // //         </>
// // // // // //       )}

// // // // // //       <Footer />
// // // // // //     </div>
// // // // // //   );
// // // // // // };

// // // // // // export default BookTableWithAPI;
// // // // // import React, { useState, useEffect } from "react";
// // // // // import axios from "axios";
// // // // // import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// // // // // import DatePicker from "react-datepicker"; // DatePicker component
// // // // // import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// // // // // import "./bookinfo.scss"; // External styles
// // // // // import Header from "./header"; // Header component
// // // // // import Footer from "./footer"; // Footer component
// // // // // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL

// // // // // const BookTableWithAPI = () => {
// // // // //   const [tableData, setTableData] = useState([]);
// // // // //   const [loading, setLoading] = useState(true);
// // // // //   const [popupContent, setPopupContent] = useState(""); // Popup content
// // // // //   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
// // // // //   const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
// // // // //   const [searchQuery, setSearchQuery] = useState(""); // Search query for filtering rows
// // // // //   const [rowData, setRowData] = useState({}); // Store row data
// // // // //   const rowsPerPage = 9; // Number of rows per page

// // // // //   useEffect(() => {
// // // // //     fetchData();
// // // // //   }, []);

// // // // //   const fetchData = async () => {
// // // // //     try {
// // // // //       setLoading(true);
// // // // //       const response = await axios.get(`${BASE_URL}/dashboard/orders`);
// // // // //       if (Array.isArray(response.data)) {
// // // // //         setTableData(response.data); // Set fetched data
// // // // //         const initialRowData = response.data.reduce((acc, row) => {
// // // // //           acc[row.orderId] = {
// // // // //             dispatchDate: row.dispatchDate || "",
// // // // //             trackingId: row.trackingId || "",
// // // // //             deliveryStatus: row.deliveryStatus || "",
// // // // //           };
// // // // //           return acc;
// // // // //         }, {});
// // // // //         setRowData(initialRowData);
// // // // //       } else {
// // // // //         console.error("Invalid data format received:", response.data);
// // // // //       }
// // // // //     } catch (error) {
// // // // //       console.error("Error fetching data:", error);
// // // // //       alert("Failed to fetch data from the server.");
// // // // //     } finally {
// // // // //       setLoading(false);
// // // // //     }
// // // // //   };

// // // // //   const handleShowPopup = (content) => {
// // // // //     setPopupContent(content);
// // // // //     setPopupVisible(true);
// // // // //   };

// // // // //   const closePopup = () => {
// // // // //     setPopupVisible(false);
// // // // //   };

// // // // //   const handleInputChange = (orderId, field, value) => {
// // // // //     setRowData((prev) => ({
// // // // //       ...prev,
// // // // //       [orderId]: {
// // // // //         ...prev[orderId],
// // // // //         [field]: value,
// // // // //       },
// // // // //     }));
// // // // //   };

// // // // //   const handleUpdateRow = async (orderId) => {
// // // // //     const updatedRow = rowData[orderId]; // Get the updated row data
// // // // //     try {
// // // // //       // Send PUT request to update row data
// // // // //       await axios.put(`${BASE_URL}/dashboard/update-orders?orderId=${orderId}`, {
// // // // //         deliveryStatus: updatedRow.deliveryStatus,
// // // // //         trackingId: updatedRow.trackingId,
// // // // //         dispatchDate: updatedRow.dispatchDate,
// // // // //       });
// // // // //       alert("Row updated successfully!");
// // // // //       fetchData(); // Refresh table data after update
// // // // //     } catch (error) {
// // // // //       console.error("Error updating row:", error);
// // // // //       alert("Failed to update the row. Please try again.");
// // // // //     }
// // // // //   };

// // // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // // //   const filteredData = tableData.filter((row) =>
// // // // //     row.name.toLowerCase().includes(searchQuery.toLowerCase())
// // // // //   );

// // // // //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// // // // //   const totalPages = Math.ceil(filteredData.length / rowsPerPage);

// // // // //   const handlePageChange = (pageNumber) => {
// // // // //     setCurrentPage(pageNumber);
// // // // //   };

// // // // //   // State to control calendar visibility
// // // // //   const [calendarOpen, setCalendarOpen] = useState(false);

// // // // //   return (
// // // // //     <div>
// // // // //       <Header />

// // // // //       <div className="search-bar-container">
// // // // //         <input
// // // // //           type="text"
// // // // //           className="search-bar"
// // // // //           placeholder="Search by username"
// // // // //           value={searchQuery}
// // // // //           onChange={(e) => setSearchQuery(e.target.value)}
// // // // //         />
// // // // //       </div>

// // // // //       <div className="table-container">
// // // // //         {loading ? (
// // // // //           <div className="loading">Loading...</div>
// // // // //         ) : (
// // // // //           <div className="table-wrapper">
// // // // //             <table className="book-table">
// // // // //               <thead>
// // // // //                 <tr>
// // // // //                   <th>SNo.</th>
// // // // //                   <th>User Name</th>
// // // // //                   <th>Publication Title</th>
// // // // //                   <th>No. of Books Ordered</th>
// // // // //                   <th>Contact Number</th>
// // // // //                   <th>Email ID</th>
// // // // //                   <th>Payment Status</th>
// // // // //                   <th>Shipping Address</th>
// // // // //                   <th>Dispatch Date</th>
// // // // //                   <th>Tracking ID</th>
// // // // //                   <th>Delivery Status</th>
// // // // //                   <th>Actions</th>
// // // // //                 </tr>
// // // // //               </thead>
// // // // //               <tbody>
// // // // //                 {currentRows.length === 0 ? (
// // // // //                   <tr>
// // // // //                     <td colSpan="12">No data available</td>
// // // // //                   </tr>
// // // // //                 ) : (
// // // // //                   currentRows.map((row, index) => (
// // // // //                     <tr key={row.orderId}>
// // // // //                       <td>{indexOfFirstRow + index + 1}</td>
// // // // //                       <td>{row.name}</td>
// // // // //                       <td>{row.publicationTitle}</td>
// // // // //                       <td>{row.quantity}</td>
// // // // //                       <td>{row.contact}</td>
// // // // //                       <td>
// // // // //                         <span
// // // // //                           className="field-link"
// // // // //                           onClick={() => handleShowPopup(row.email)}
// // // // //                         >
// // // // //                           {row.email}
// // // // //                         </span>
// // // // //                       </td>
// // // // //                       <td>{row.orderedDate}</td>
// // // // //                       <td>
// // // // //                         <span
// // // // //                           className="field-link"
// // // // //                           onClick={() => handleShowPopup(row.shipingAddress)}
// // // // //                         >
// // // // //                           {row.shipingAddress}
// // // // //                         </span>
// // // // //                       </td>
// // // // //                       <td>
// // // // //                         {/* Display the calendar icon if no dispatch date is set */}
// // // // //                         {!rowData[row.orderId]?.dispatchDate ? (
// // // // //                           <button
// // // // //                             className="calendar-icon-btn"
// // // // //                             onClick={() => setCalendarOpen(true)} // Open the calendar on click
// // // // //                           >
// // // // //                             <FaCalendarAlt /> {/* Calendar icon */}
// // // // //                           </button>
// // // // //                         ) : (
// // // // //                           <span
// // // // //                             className="calendar-date"
// // // // //                             onClick={() => setCalendarOpen(true)} // Open calendar when date is clicked
// // // // //                           >
// // // // //                             {rowData[row.orderId]?.dispatchDate}
// // // // //                           </span>
// // // // //                         )}

// // // // //                         {/* Show DatePicker if calendar is open */}
// // // // //                         {calendarOpen && (
// // // // //                           <DatePicker
// // // // //                             selected={
// // // // //                               rowData[row.orderId]?.dispatchDate
// // // // //                                 ? new Date(rowData[row.orderId]?.dispatchDate)
// // // // //                                 : null
// // // // //                             }
// // // // //                             onChange={(date) =>
// // // // //                               handleInputChange(row.orderId, "dispatchDate", date)
// // // // //                             }
// // // // //                             dateFormat="dd/MM/yyyy"
// // // // //                             isClearable
// // // // //                             showMonthDropdown
// // // // //                             showYearDropdown
// // // // //                             dropdownMode="select"
// // // // //                             className="datepicker-input"
// // // // //                             onClickOutside={() => setCalendarOpen(false)} // Close calendar if clicked outside
// // // // //                           />
// // // // //                         )}
// // // // //                       </td>
// // // // //                       <td>
// // // // //                         <input
// // // // //                           type="text"
// // // // //                           value={rowData[row.orderId]?.trackingId}
// // // // //                           placeholder="Enter Tracking ID"
// // // // //                           onChange={(e) =>
// // // // //                             handleInputChange(
// // // // //                               row.orderId,
// // // // //                               "trackingId",
// // // // //                               e.target.value
// // // // //                             )
// // // // //                           }
// // // // //                         />
// // // // //                       </td>
// // // // //                       <td>
// // // // //                         <select
// // // // //                           value={rowData[row.orderId]?.deliveryStatus}
// // // // //                           onChange={(e) =>
// // // // //                             handleInputChange(
// // // // //                               row.orderId,
// // // // //                               "deliveryStatus",
// // // // //                               e.target.value
// // // // //                             )
// // // // //                           }
// // // // //                         >
// // // // //                           <option value="Delivered">Delivered</option>
// // // // //                           <option value="Processing">Processing</option>
// // // // //                           <option value="Dispatch">Dispatch</option>
// // // // //                         </select>
// // // // //                       </td>
// // // // //                       <td>
// // // // //                         <button onClick={() => handleUpdateRow(row.orderId)}>
// // // // //                           Save
// // // // //                         </button>
// // // // //                       </td>
// // // // //                     </tr>
// // // // //                   ))
// // // // //                 )}
// // // // //               </tbody>
// // // // //             </table>

// // // // //             <div className="pagination">
// // // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // // //                 <button
// // // // //                   key={index + 1}
// // // // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // // // //                   onClick={() => handlePageChange(index + 1)}
// // // // //                 >
// // // // //                   {index + 1}
// // // // //                 </button>
// // // // //               ))}
// // // // //             </div>
// // // // //           </div>
// // // // //         )}
// // // // //       </div>

// // // // //       {popupVisible && (
// // // // //         <>
// // // // //           <div className="popup-overlay" onClick={closePopup}></div>
// // // // //           <div className="popup">
// // // // //             <div className="popup-content">{popupContent}</div>
// // // // //             <button className="popup-close-btn" onClick={closePopup}>Close</button>
// // // // //           </div>
// // // // //         </>
// // // // //       )}

// // // // //       <Footer />
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default BookTableWithAPI;
// // // // import React, { useState, useEffect } from "react";
// // // // import axios from "axios";
// // // // import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// // // // import DatePicker from "react-datepicker"; // DatePicker component
// // // // import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// // // // import "./bookinfo.scss"; // External styles
// // // // import Header from "./header"; // Header component
// // // // import Footer from "./footer"; // Footer component
// // // // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL

// // // // const BookTableWithAPI = () => {
// // // //   const [tableData, setTableData] = useState([]);
// // // //   const [loading, setLoading] = useState(true);
// // // //   const [popupContent, setPopupContent] = useState(""); // Popup content
// // // //   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
// // // //   const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
// // // //   const [searchQuery, setSearchQuery] = useState(""); // Search query for filtering rows
// // // //   const [rowData, setRowData] = useState({}); // Store row data
// // // //   const rowsPerPage = 9; // Number of rows per page

// // // //   useEffect(() => {
// // // //     fetchData();
// // // //   }, []);

// // // //   const fetchData = async () => {
// // // //     try {
// // // //       setLoading(true);
// // // //       const response = await axios.get(`${BASE_URL}/dashboard/orders`);
// // // //       if (Array.isArray(response.data)) {
// // // //         setTableData(response.data); // Set fetched data
// // // //         const initialRowData = response.data.reduce((acc, row) => {
// // // //           acc[row.orderId] = {
// // // //             dispatchDate: row.dispatchDate || "",
// // // //             trackingId: row.trackingId || "",
// // // //             deliveryStatus: row.deliveryStatus || "",
// // // //           };
// // // //           return acc;
// // // //         }, {});
// // // //         setRowData(initialRowData);
// // // //       } else {
// // // //         console.error("Invalid data format received:", response.data);
// // // //       }
// // // //     } catch (error) {
// // // //       console.error("Error fetching data:", error);
// // // //       alert("Failed to fetch data from the server.");
// // // //     } finally {
// // // //       setLoading(false);
// // // //     }
// // // //   };

// // // //   const handleShowPopup = (content) => {
// // // //     setPopupContent(content);
// // // //     setPopupVisible(true);
// // // //   };

// // // //   const closePopup = () => {
// // // //     setPopupVisible(false);
// // // //   };

// // // //   const handleInputChange = (orderId, field, value) => {
// // // //     setRowData((prev) => ({
// // // //       ...prev,
// // // //       [orderId]: {
// // // //         ...prev[orderId],
// // // //         [field]: value,
// // // //       },
// // // //     }));
// // // //   };

// // // //   const handleUpdateRow = async (orderId) => {
// // // //     const updatedRow = rowData[orderId]; // Get the updated row data
// // // //     try {
// // // //       // Send PUT request to update row data
// // // //       await axios.put(`${BASE_URL}/dashboard/update-orders?orderId=${orderId}`, {
// // // //         deliveryStatus: updatedRow.deliveryStatus,
// // // //         trackingId: updatedRow.trackingId,
// // // //         dispatchDate: updatedRow.dispatchDate,
// // // //       });
// // // //       alert("Row updated successfully!");
// // // //       fetchData(); // Refresh table data after update
// // // //     } catch (error) {
// // // //       console.error("Error updating row:", error);
// // // //       alert("Failed to update the row. Please try again.");
// // // //     }
// // // //   };

// // // //   const indexOfLastRow = currentPage * rowsPerPage;
// // // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // //   const filteredData = tableData.filter((row) =>
// // // //     row.name.toLowerCase().includes(searchQuery.toLowerCase())
// // // //   );

// // // //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// // // //   const totalPages = Math.ceil(filteredData.length / rowsPerPage);

// // // //   const handlePageChange = (pageNumber) => {
// // // //     setCurrentPage(pageNumber);
// // // //   };

// // // //   // State to control calendar visibility
// // // //   const [calendarOpen, setCalendarOpen] = useState(false);

// // // //   return (
// // // //     <div>
// // // //       <Header />

// // // //       <div className="search-bar-container">
// // // //         <input
// // // //           type="text"
// // // //           className="search-bar"
// // // //           placeholder="Search by username"
// // // //           value={searchQuery}
// // // //           onChange={(e) => setSearchQuery(e.target.value)}
// // // //         />
// // // //       </div>

// // // //       <div className="table-container">
// // // //         {loading ? (
// // // //           <div className="loading">Loading...</div>
// // // //         ) : (
// // // //           <div className="table-wrapper">
// // // //             <table className="book-table">
// // // //               <thead>
// // // //                 <tr>
// // // //                   <th>SNo.</th>
// // // //                   <th>User Name</th>
// // // //                   <th>Publication Title</th>
// // // //                   <th>No. of Books Ordered</th>
// // // //                   <th>Contact Number</th>
// // // //                   <th>Email ID</th>
// // // //                   <th>Payment Status</th>
// // // //                   <th>Shipping Address</th>
// // // //                   <th>Dispatch Date</th>
// // // //                   <th>Tracking ID</th>
// // // //                   <th>Delivery Status</th>
// // // //                   <th>Actions</th>
// // // //                 </tr>
// // // //               </thead>
// // // //               <tbody>
// // // //                 {currentRows.length === 0 ? (
// // // //                   <tr>
// // // //                     <td colSpan="12">No data available</td>
// // // //                   </tr>
// // // //                 ) : (
// // // //                   currentRows.map((row, index) => (
// // // //                     <tr key={row.orderId}>
// // // //                       <td>{indexOfFirstRow + index + 1}</td>
// // // //                       <td>{row.name}</td>
// // // //                       <td>{row.publicationTitle}</td>
// // // //                       <td>{row.quantity}</td>
// // // //                       <td>{row.contact}</td>
// // // //                       <td>
// // // //                         <span
// // // //                           className="field-link"
// // // //                           onClick={() => handleShowPopup(row.email)}
// // // //                         >
// // // //                           {row.email}
// // // //                         </span>
// // // //                       </td>
// // // //                       <td>{row.orderedDate}</td>
// // // //                       <td>
// // // //                         <span
// // // //                           className="field-link"
// // // //                           onClick={() => handleShowPopup(row.shipingAddress)}
// // // //                         >
// // // //                           {row.shipingAddress}
// // // //                         </span>
// // // //                       </td>
// // // //                       <td>
// // // //                         {/* Show calendar icon only if dispatchDate is not set */}
// // // //                         {!rowData[row.orderId]?.dispatchDate ? (
// // // //                           <button
// // // //                             className="calendar-icon-btn"
// // // //                             onClick={() => setCalendarOpen(true)} // Open the calendar
// // // //                           >
// // // //                             <FaCalendarAlt /> {/* Calendar icon */}
// // // //                           </button>
// // // //                         ) : (
// // // //                           <span className="calendar-date">
// // // //                             {rowData[row.orderId]?.dispatchDate}
// // // //                           </span> // Show date if already set
// // // //                         )}

// // // //                         {/* Show DatePicker only when calendar icon is clicked and dispatchDate is empty */}
// // // //                         {calendarOpen && !rowData[row.orderId]?.dispatchDate && (
// // // //                           <DatePicker
// // // //                             selected={
// // // //                               rowData[row.orderId]?.dispatchDate
// // // //                                 ? new Date(rowData[row.orderId]?.dispatchDate)
// // // //                                 : null
// // // //                             }
// // // //                             onChange={(date) =>
// // // //                               handleInputChange(row.orderId, "dispatchDate", date)
// // // //                             }
// // // //                             dateFormat="dd/MM/yyyy"
// // // //                             isClearable
// // // //                             showMonthDropdown
// // // //                             showYearDropdown
// // // //                             dropdownMode="select"
// // // //                             className="datepicker-input"
// // // //                             onClickOutside={() => setCalendarOpen(false)} // Close calendar if clicked outside
// // // //                           />
// // // //                         )}
// // // //                       </td>
// // // //                       <td>
// // // //                         <input
// // // //                           type="text"
// // // //                           value={rowData[row.orderId]?.trackingId}
// // // //                           placeholder="Enter Tracking ID"
// // // //                           onChange={(e) =>
// // // //                             handleInputChange(
// // // //                               row.orderId,
// // // //                               "trackingId",
// // // //                               e.target.value
// // // //                             )
// // // //                           }
// // // //                         />
// // // //                       </td>
// // // //                       <td>
// // // //                         <select
// // // //                           value={rowData[row.orderId]?.deliveryStatus}
// // // //                           onChange={(e) =>
// // // //                             handleInputChange(
// // // //                               row.orderId,
// // // //                               "deliveryStatus",
// // // //                               e.target.value
// // // //                             )
// // // //                           }
// // // //                         >
// // // //                           <option value="Delivered">Delivered</option>
// // // //                           <option value="Processing">Processing</option>
// // // //                           <option value="Dispatch">Dispatch</option>
// // // //                         </select>
// // // //                       </td>
// // // //                       <td>
// // // //                         <button onClick={() => handleUpdateRow(row.orderId)}>
// // // //                           Save
// // // //                         </button>
// // // //                       </td>
// // // //                     </tr>
// // // //                   ))
// // // //                 )}
// // // //               </tbody>
// // // //             </table>

// // // //             <div className="pagination">
// // // //               {Array.from({ length: totalPages }, (_, index) => (
// // // //                 <button
// // // //                   key={index + 1}
// // // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // // //                   onClick={() => handlePageChange(index + 1)}
// // // //                 >
// // // //                   {index + 1}
// // // //                 </button>
// // // //               ))}
// // // //             </div>
// // // //           </div>
// // // //         )}
// // // //       </div>

// // // //       {popupVisible && (
// // // //         <>
// // // //           <div className="popup-overlay" onClick={closePopup}></div>
// // // //           <div className="popup">
// // // //             <div className="popup-content">{popupContent}</div>
// // // //             <button className="popup-close-btn" onClick={closePopup}>Close</button>
// // // //           </div>
// // // //         </>
// // // //       )}

// // // //       <Footer />
// // // //     </div>
// // // //   );
// // // // };

// // // // export default BookTableWithAPI;





// // // import React, { useState, useEffect } from "react";
// // // import axios from "axios";
// // // import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// // // import DatePicker from "react-datepicker"; // DatePicker component
// // // import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// // // import "./bookinfo.scss"; // External styles
// // // import Header from "./header"; // Header component
// // // import Footer from "./footer"; // Footer component
// // // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL

// // // const BookTableWithAPI = () => {
// // //   const [tableData, setTableData] = useState([]);
// // //   const [loading, setLoading] = useState(true);
// // //   const [popupContent, setPopupContent] = useState(""); // Popup content
// // //   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
// // //   const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
// // //   const [searchQuery, setSearchQuery] = useState(""); // Search query for filtering rows
// // //   const [rowData, setRowData] = useState({}); // Store row data
// // //   const rowsPerPage = 9; // Number of rows per page

// // //   useEffect(() => {
// // //     fetchData();
// // //   }, []);

// // //   const fetchData = async () => {
// // //     try {
// // //       setLoading(true);
// // //       const response = await axios.get(`${BASE_URL}/dashboard/orders`);
// // //       if (Array.isArray(response.data)) {
// // //         setTableData(response.data); // Set fetched data
// // //         const initialRowData = response.data.reduce((acc, row) => {
// // //           acc[row.orderId] = {
// // //             dispatchDate: row.dispatchDate || "",
// // //             trackingId: row.trackingId || "",
// // //             deliveryStatus: row.deliveryStatus || "",
// // //           };
// // //           return acc;
// // //         }, {});
// // //         setRowData(initialRowData);
// // //       } else {
// // //         console.error("Invalid data format received:", response.data);
// // //       }
// // //     } catch (error) {
// // //       console.error("Error fetching data:", error);
// // //       alert("Failed to fetch data from the server.");
// // //     } finally {
// // //       setLoading(false);
// // //     }
// // //   };

// // //   const handleShowPopup = (content) => {
// // //     setPopupContent(content);
// // //     setPopupVisible(true);
// // //   };

// // //   const closePopup = () => {
// // //     setPopupVisible(false);
// // //   };

// // //   const handleInputChange = (orderId, field, value) => {
// // //     setRowData((prev) => ({
// // //       ...prev,
// // //       [orderId]: {
// // //         ...prev[orderId],
// // //         [field]: value,
// // //       },
// // //     }));
// // //   };

// // //   const handleUpdateRow = async (orderId) => {
// // //     const updatedRow = rowData[orderId]; // Get the updated row data
// // //     try {
// // //       // Send PUT request to update row data
// // //       await axios.put(`${BASE_URL}/dashboard/update-orders?orderId=${orderId}`, {
// // //         deliveryStatus: updatedRow.deliveryStatus,
// // //         trackingId: updatedRow.trackingId,
// // //         dispatchDate: updatedRow.dispatchDate,
// // //       });
// // //       alert("Row updated successfully!");
// // //       fetchData(); // Refresh table data after update
// // //     } catch (error) {
// // //       console.error("Error updating row:", error);
// // //       alert("Failed to update the row. Please try again.");
// // //     }
// // //   };

// // //   const indexOfLastRow = currentPage * rowsPerPage;
// // //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // //   const filteredData = tableData.filter((row) =>
// // //     row.name.toLowerCase().includes(searchQuery.toLowerCase())
// // //   );

// // //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// // //   const totalPages = Math.ceil(filteredData.length / rowsPerPage);

// // //   const handlePageChange = (pageNumber) => {
// // //     setCurrentPage(pageNumber);
// // //   };

// // //   // State to control calendar visibility
// // //   const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open

// // //   return (
// // //     <div>
// // //       <Header />

// // //       <div className="search-bar-container">
// // //         <input
// // //           type="text"
// // //           className="search-bar"
// // //           placeholder="Search by username"
// // //           value={searchQuery}
// // //           onChange={(e) => setSearchQuery(e.target.value)}
// // //         />
// // //       </div>

// // //       <div className="table-container">
// // //         {loading ? (
// // //           <div className="loading">Loading...</div>
// // //         ) : (
// // //           <div className="table-wrapper">
// // //             <table className="book-table">
// // //               <thead>
// // //                 <tr>
// // //                   <th>SNo.</th>
// // //                   <th>User Name</th>
// // //                   <th>Publication Title</th>
// // //                   <th>No. of Books Ordered</th>
// // //                   <th>Contact Number</th>
// // //                   <th>Email ID</th>
// // //                   <th>Payment Status</th>
// // //                   <th>Shipping Address</th>
// // //                   <th>Dispatch Date</th>
// // //                   <th>Tracking ID</th>
// // //                   <th>Delivery Status</th>
// // //                   <th>Actions</th>
// // //                 </tr>
// // //               </thead>
// // //               <tbody>
// // //                 {currentRows.length === 0 ? (
// // //                   <tr>
// // //                     <td colSpan="12">No data available</td>
// // //                   </tr>
// // //                 ) : (
// // //                   currentRows.map((row, index) => (
// // //                     <tr key={row.orderId}>
// // //                       <td>{indexOfFirstRow + index + 1}</td>
// // //                       <td>{row.name}</td>
// // //                       <td>{row.publicationTitle}</td>
// // //                       <td>{row.quantity}</td>
// // //                       <td>{row.contact}</td>
// // //                       <td>
// // //                         <span
// // //                           className="field-link"
// // //                           onClick={() => handleShowPopup(row.email)}
// // //                         >
// // //                           {row.email}
// // //                         </span>
// // //                       </td>
// // //                       <td>{row.orderedDate}</td>
// // //                       <td>
// // //                         <span
// // //                           className="field-link"
// // //                           onClick={() => handleShowPopup(row.shipingAddress)}
// // //                         >
// // //                           {row.shipingAddress}
// // //                         </span>
// // //                       </td>
// // //                    <td>
// // //   {/* Show calendar icon if dispatchDate is empty */}
// // //   {!rowData[row.orderId]?.dispatchDate ? (
// // //     <button
// // //       className="calendar-icon-btn"
// // //       onClick={() => setOpenCalendarId(row.orderId)} // Open calendar when clicked
// // //     >
// // //       <FaCalendarAlt />
// // //     </button>
// // //   ) : (
// // //     // Convert the Date object to a readable format before displaying
// // //     <span className="calendar-date">
// // //       {new Date(rowData[row.orderId]?.dispatchDate).toLocaleDateString('en-GB')}
// // //     </span>
// // //   )}

// // //   {/* Display DatePicker if calendar icon was clicked */}
// // //   {openCalendarId === row.orderId && !rowData[row.orderId]?.dispatchDate && (
// // //     <DatePicker
// // //       selected={
// // //         rowData[row.orderId]?.dispatchDate
// // //           ? new Date(rowData[row.orderId]?.dispatchDate)
// // //           : null
// // //       }
// // //       onChange={(date) =>
// // //         handleInputChange(row.orderId, "dispatchDate", date)
// // //       }
// // //       dateFormat="dd/MM/yyyy"
// // //       isClearable
// // //       showMonthDropdown
// // //       showYearDropdown
// // //       dropdownMode="select"
// // //       className="datepicker-input"
// // //       onClickOutside={() => setOpenCalendarId(null)} // Close calendar when clicked outside
// // //     />
// // //   )}
// // // </td>


// // //                       <td>
// // //                         <input
// // //                           type="text"
// // //                            className="inline-input"
// // //                           value={rowData[row.orderId]?.trackingId}
// // //                           placeholder="Enter Tracking ID"
// // //                           onChange={(e) =>
// // //                             handleInputChange(
// // //                               row.orderId,
// // //                               "trackingId",
// // //                               e.target.value
// // //                             )
// // //                           }
// // //                         />
// // //                       </td>
// // //                       <td>
// // //                         <select
// // //                           className="inline-input"
// // //                           value={rowData[row.orderId]?.deliveryStatus}
// // //                           onChange={(e) =>
// // //                             handleInputChange(
// // //                               row.orderId,
// // //                               "deliveryStatus",
// // //                               e.target.value
// // //                             )
// // //                           }
// // //                         >
// //                           // <option value="Delivered">Delivered</option>
// //                           // <option value="Processing">Processing</option>
// //                           // <option value="Dispatch">Dispatch</option>
// // //                         </select>
// // //                       </td>
// // //                       <td>
                        
// // //                         <button  className="submit-btn" onClick={() => handleUpdateRow(row.orderId)}>
                          
// // //                           Save
// // //                         </button>
// // //                       </td>
// // //                     </tr>
// // //                   ))
// // //                 )}
// // //               </tbody>
// // //             </table>

// // //             <div className="pagination">
// // //               {Array.from({ length: totalPages }, (_, index) => (
// // //                 <button
// // //                   key={index + 1}
// // //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// // //                   onClick={() => handlePageChange(index + 1)}
// // //                 >
// // //                   {index + 1}
// // //                 </button>
// // //               ))}
// // //             </div>
// // //           </div>
// // //         )}
// // //       </div>

// // //       {popupVisible && (
// // //         <>
// // //           <div className="popup-overlay" onClick={closePopup}></div>
// // //           <div className="popup">
// // //             <div className="popup-content">{popupContent}</div>
// // //             <button className="popup-close" onClick={closePopup}>
// // //               Close
// // //             </button>
// // //           </div>
// // //         </>
// // //       )}

// // //       <Footer />
// // //     </div>
// // //   );
// // // };

// // // export default BookTableWithAPI;




// // ///================================================================final============================
// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// // import DatePicker from "react-datepicker"; // DatePicker component
// // import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// // import "./bookinfo.scss"; // External styles
// // import Header from "./header"; // Header component
// // import Footer from "./footer"; // Footer component
// // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
// // import { format } from 'date-fns';

// // const BookTableWithAPI = () => {
// //   const [tableData, setTableData] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [popupContent, setPopupContent] = useState(""); // Popup content
// //   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
// //   const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
// //   const [searchQuery, setSearchQuery] = useState(""); // Search query for filtering rows
// //   const [rowData, setRowData] = useState({}); // Store row data
// //   const rowsPerPage = 10; // Number of rows per page
// //   // const [notification, setNotification] = useState({ message: "", type: "" });
// //   const [notification, setNotification] = useState(""); // Message to show
// //   const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'

// //   // State to control calendar visibility
// //   const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open

// //   useEffect(() => {
// //     fetchData();
// //   }, []);

// //   // const fetchData = async () => {
// //   //   try {
// //   //     setLoading(true);
// //   //     const response = await axios.get(`${BASE_URL}/dashboard/orders`);
// //   //     if (Array.isArray(response.data)) {
// //   //       setTableData(response.data); // Set fetched data
// //   //       const initialRowData = response.data.reduce((acc, row) => {
// //   //         acc[row.orderId] = {
// //   //           dispatchDate: row.dispatchDate || "",
// //   //           trackingId: row.trackingId || "",
// //   //           deliveryStatus: row.deliveryStatus || "",
// //   //         };
// //   //         return acc;
// //   //       }, {});
// //   //       setRowData(initialRowData);
// //   //     } else {
// //   //       console.error("Invalid data format received:", response.data);
// //   //     }
// //   //   } catch (error) {
// //   //     console.error("Error fetching data:", error);
// //   //     alert("Failed to fetch data from the server.");
// //   //   } finally {
// //   //     setLoading(false);
// //   //   }
// //   // };
// // const fetchData = async () => {
// //   try {
// //     setLoading(true);
// //     const key = localStorage.getItem("jwtToken"); // Retrieve JWT token from local storage

// //     const response = await axios.get(`${BASE_URL}/dashboard/orders`, {
// //       headers: {
// //         Auth: `Bearer ${key}`, // Add the Authorization header with the JWT token
// //       },
// //     });

// //     if (Array.isArray(response.data)) {
// //       setTableData(response.data); // Set fetched data
// //       const initialRowData = response.data.reduce((acc, row) => {
// //         acc[row.orderId] = {
// //           dispatchDate: row.dispatchDate || "",
// //           trackingId: row.trackingId || "",
// //           deliveryStatus: row.deliveryStatus || "",
// //         };
// //         return acc;
// //       }, {});
// //       setRowData(initialRowData);
// //     } else {
// //       console.error("Invalid data format received:", response.data);
// //     }
// //   } catch (error) {
// //     console.error("Error fetching data:", error);
// //     alert("Failed to fetch data from the server.");
// //   } finally {
// //     setLoading(false);
// //   }
// // };

// //   const handleShowPopup = (content) => {
// //     setPopupContent(content);
// //     setPopupVisible(true);
// //   };

// //   const closePopup = () => {
// //     setPopupVisible(false);
// //   };

// //   const handleInputChange = (orderId, field, value) => {
// //     setRowData((prev) => ({
// //       ...prev,
// //       [orderId]: {
// //         ...prev[orderId],
// //         [field]: value,
// //       },
// //     }));
// //   };

// //   // const handleUpdateRow = async (orderId) => {
// //   //   const updatedRow = rowData[orderId]; // Get the updated row data
// //   //   try {
// //   //     // Send PUT request to update row data
// //   //     await axios.put(`${BASE_URL}/dashboard/update-orders?orderId=${orderId}`, {
// //   //       deliveryStatus: updatedRow.deliveryStatus,
// //   //       trackingId: updatedRow.trackingId,
// //   //       dispatchDate: updatedRow.dispatchDate,
// //   //     });
// //   //     alert("Row updated successfully!");
// //   //     fetchData(); // Refresh table data after update
// //   //   } catch (error) {
// //   //     console.error("Error updating row:", error);
// //   //     alert("Failed to update the row. Please try again.");
// //   //   }
// //   // };
// // //   const handleUpdateRow = async (orderId) => {
// // //   const updatedRow = rowData[orderId]; // Get the updated row data
// // //   const key = localStorage.getItem('jwtToken'); // Retrieve the JWT token from local storage

// // //   // try {
// // //     // Send PUT request to update row data
// // //     // await axios.put(
// // //     //   `${BASE_URL}/dashboard/update-orders?orderId=${orderId}`,
// // //     //   {
// // //     //     deliveryStatus: updatedRow.deliveryStatus,
// // //     //     trackingId: updatedRow.trackingId,
// // //     //     dispatchDate: updatedRow.dispatchDate,
// // //     //   },
// // //     //   {
// // //     //     headers: {
// // //     //       'Auth': `Bearer ${key}`, // Add the Authorization header
// // //     //     },
// // //     //   }
// // //     // );

// // //     // alert("Row updated successfully!");
// // //     // fetchData(); // Refresh table data after update
// // //     await axios.put(
// // //   `${BASE_URL}/dashboard/update-orders?orderId=${orderId}`,
// // //   {
// // //     deliveryStatus: updatedRow.deliveryStatus,
// // //     trackingId: updatedRow.trackingId,
// // //     dispatchDate: updatedRow.dispatchDate,
// // //   },
// // //   {
// // //     headers: {
// // //       Auth: `Bearer ${key}`,
// // //     },
// // //   }
// // // ).then((response) => {
// // //   console.log("Update Response:", response.data);
// // // }).catch((error) => {
// // //   console.error("Error:", error.response?.data || error.message);
// // // });

// // //   // } catch (error) {
// // //   //   console.error("Error updating row:", error);
// // //   //   alert("Failed to update the row. Please try again.");
// // //   // }
// // // };

// // // const handleUpdateRow = async (orderId) => {
// // //   const updatedRow = rowData[orderId];
// // //   const key = localStorage.getItem("jwtToken");

// // //   try {
// // //     const response = await axios.put(
// // //       `${BASE_URL}/dashboard/update-orders?orderId=${orderId}`,
// // //       {
// // //         deliveryStatus: updatedRow.deliveryStatus,
// // //         trackingId: updatedRow.trackingId,
// // //         dispatchDate: updatedRow.dispatchDate,
// // //       },
// // //       {
// // //         headers: {
// // //           Auth: `Bearer ${key}`,
// // //         },
// // //       }
// // //     );

// // //     // Show success notification
// // //     setNotification({ message: "Row updated successfully!", type: "success" });

// // //     // Refresh the data
// // //     fetchData();
// // //   } catch (error) {
// // //     console.error("Error updating row:", error.response?.data || error.message);

// // //     // Show error notification
// // //     setNotification({
// // //       message: "Failed to update the row. Please try again.",
// // //       type: "error",
// // //     });
// // //   }
// // // };
// //  const handleUpdateRow = async (orderId) => {
// //     const updatedRow = rowData[orderId]; // Get the updated row data
// //     const key = localStorage.getItem('jwtToken'); // Retrieve the JWT token from local storage

// //     try {
// //       // Send PUT request to update row data
// //       const response = await axios.put(
// //         `${BASE_URL}/dashboard/update-orders?orderId=${orderId}`,
// //         {
// //           deliveryStatus: updatedRow.deliveryStatus,
// //           trackingId: updatedRow.trackingId,
// //           dispatchDate: updatedRow.dispatchDate,
// //         },
// //         {
// //           headers: {
// //             Auth: `Bearer ${key}`,
// //           },
// //         }
// //       );

// //       setNotification("Row updated successfully!");
// //       setNotificationType("success");

// //       // Hide the notification after 5 seconds
// //       setTimeout(() => {
// //         setNotification("");
// //       }, 5000);

// //       fetchData(); // Refresh table data after update
// //     } catch (error) {
// //       console.error("Error updating row:", error);
// //       setNotification("Failed to update the row. Please try again.");
// //       setNotificationType("error");

// //       // Hide the notification after 5 seconds
// //       setTimeout(() => {
// //         setNotification("");
// //       }, 10000);
// //     }
// //   };

// //   const indexOfLastRow = currentPage * rowsPerPage;
// //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// //   const filteredData = tableData.filter((row) =>
// //     row.name?.toLowerCase().includes(searchQuery.toLowerCase())
// //   );

// //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// //   const totalPages = Math.ceil(filteredData.length / rowsPerPage);

// //   const handlePageChange = (pageNumber) => {
// //     setCurrentPage(pageNumber);
// //   };

// //   // Padding empty rows
// //   const paddedRows = [...currentRows];
// //   while (paddedRows.length < rowsPerPage) {
// //     paddedRows.push({}); // Add empty row
// //   }

// //   return (
// //     <div>
// //       <Header />
// //  {notification && (
// //         <div className={`notification ${notificationType}`}>
// //           {notification}
// //         </div>
// //       )}
// //       <div className="search-bar-container">
// //         <input
// //           type="text"
// //           className="search-bar"
// //           placeholder="Search by username"
// //           value={searchQuery}
// //           onChange={(e) => setSearchQuery(e.target.value)}
// //         />
// //       </div>

// //       <div className="table-container">
// //         {loading ? (
// //           <div className="loading">Loading...</div>
// //         ) : (
// //           <div className="table-wrapper">
// //             <table className="book-table">
// //               <thead>
// //                 <tr>
// //                   <th>SNo.</th>
// //                   <th>User Name</th>
// //                   <th>Publication Title</th>
// //                   <th>No. of Books Ordered</th>
// //                   <th>Contact Number</th>
// //                   <th>Email ID</th>
// //                   <th>Payment Status</th>
// //                   <th>Shipping Address</th>
// //                   <th>Dispatch Date</th>
// //                   <th>Tracking ID</th>
// //                   <th>Delivery Status</th>
// //                   <th>Actions</th>
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {paddedRows.map((row, index) => (
// //                   <tr key={index}>
// //                     <td>{row.orderId ? indexOfFirstRow + index + 1 : ""}</td>
// //                     <td>{row.name || ""}</td>
// //                     <td>{row.publicationTitle || ""}</td>
// //                     <td>{row.quantity || ""}</td>
// //                     <td>{row.contact || ""}</td>
// //                     <td>{row.email || ""}</td>
// //                     <td>{row.orderedDate || ""}</td>
// //                     <td>{row.shipingAddress || ""}</td>
// //                     <td>
// //   {row.orderId ? (
// // <DatePicker
// //   selected={
// //     rowData[row.orderId]?.dispatchDate
// //       ? new Date(rowData[row.orderId]?.dispatchDate)
// //       : row.dispatchDate
// //       ? new Date(row.dispatchDate)
// //       : null
// //   }
// //   onChange={(date) => handleInputChange(row.orderId, "dispatchDate", format(date, 'yyyy-MM-dd'))}
// //   placeholderText="Select Dispatch Date"
// //   dateFormat="yyyy-MM-dd"
// //   className="date-picker"
// // />


// //   ) : (
// //     ""
// //   )}
// // </td>
// // <td>
// //   {row.orderId ? (
// //     <input
// //       type="text"
// //       value={rowData[row.orderId]?.trackingId || row.trackingId || ""}
// //       onChange={(e) => handleInputChange(row.orderId, "trackingId", e.target.value)}
// //       placeholder="Enter Tracking ID"
// //     />
// //   ) : (
// //     ""
// //   )}
// // </td>
// // <td>
// //   {row.orderId ? (
// //     <select
// //       value={rowData[row.orderId]?.deliveryStatus || row.deliveryStatus || ""}
// //       onChange={(e) => handleInputChange(row.orderId, "deliveryStatus", e.target.value)}
// //     >
// //       <option value="" disabled>
// //         Select Status
// //       </option>
 
// //                           <option value="Delivered">Delivered</option>
// //                           <option value="Processing">Processing</option>
// //                           <option value="Dispatched">Dispatched</option>
// //     </select>
// //   ) : (
// //     ""
// //   )}
// // </td>


// //                     <td>
// //                       {row.orderId && (
// //                         <button
// //                           className="submit-btn"
// //                           onClick={() => handleUpdateRow(row.orderId)}
// //                         >
// //                           Save
// //                         </button>
// //                       )}
// //                     </td>
// //                   </tr>
                  
// //                 ))}
// //               </tbody>
// //             </table>
// // {notification.message && (
// //   <div className={`notification ${notification.type}`}>
// //     {notification.message}
// //     <button onClick={() => setNotification({ message: "", type: "" })}>×</button>
// //   </div>
// // )}

// //             <div className="pagination">
// //               {Array.from({ length: totalPages }, (_, index) => (
// //                 <button
// //                   key={index + 1}
// //                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
// //                   onClick={() => handlePageChange(index + 1)}
// //                 >
// //                   {index + 1}
// //                 </button>
// //               ))}
// //             </div>
// //           </div>
// //         )}
// //       </div>

// //       <Footer />
// //     </div>
// //   );
// // };

// // export default BookTableWithAPI;


// // =====================teasting==================================


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// import DatePicker from "react-datepicker"; // DatePicker component
// import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// import "./bookinfo.scss"; // External styles
// import Header from "./header"; // Header component
// import Footer from "./footer"; // Footer component
// import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
// import { format } from 'date-fns';

// const BookTableWithAPI = () => {
//   const [tableData, setTableData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [popupContent, setPopupContent] = useState(""); // Popup content
//   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
//   const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
//   const [searchQuery, setSearchQuery] = useState(""); // Search query for filtering rows
//   const [rowData, setRowData] = useState({}); // Store row data
//   const rowsPerPage = 10; // Number of rows per page
//   // const [notification, setNotification] = useState({ message: "", type: "" });
//   const [notification, setNotification] = useState(""); // Message to show
//   const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'

//   // State to control calendar visibility
//   const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open

//   useEffect(() => {
//     fetchData();
//   }, []);

// const fetchData = async () => {
//   try {
//     setLoading(true);
//     const key = localStorage.getItem("jwtToken"); // Retrieve JWT token from local storage

//     const response = await axios.get(`${BASE_URL}/dashboard/orders`, {
//       headers: {
//         Auth: `Bearer ${key}`, // Add the Authorization header with the JWT token
//       },
//     });

//     if (Array.isArray(response.data)) {
//       setTableData(response.data); // Set fetched data
//       const initialRowData = response.data.reduce((acc, row) => {
//         acc[row.orderId] = {
//           dispatchDate: row.dispatchDate || "",
//           trackingId: row.trackingId || "",
//           deliveryStatus: row.deliveryStatus || "",
//         };
//         return acc;
//       }, {});
//       setRowData(initialRowData);
//     } else {
//       console.error("Invalid data format received:", response.data);
//     }
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     alert("Failed to fetch data from the server.");
//   } finally {
//     setLoading(false);
//   }
// };

//   const handleShowPopup = (content) => {
//     setPopupContent(content);
//     setPopupVisible(true);
//   };

//   const closePopup = () => {
//     setPopupVisible(false);
//   };

//   const handleInputChange = (orderId, field, value) => {
//     setRowData((prev) => ({
//       ...prev,
//       [orderId]: {
//         ...prev[orderId],
//         [field]: value,
//       },
//     }));
//   };

 
//  const handleUpdateRow = async (orderId) => {
//     const updatedRow = rowData[orderId]; // Get the updated row data
//     const key = localStorage.getItem('jwtToken'); // Retrieve the JWT token from local storage

//     try {
//       // Send PUT request to update row data
//       const response = await axios.put(
//         `${BASE_URL}/dashboard/update-orders?orderId=${orderId}`,
//         {
//           deliveryStatus: updatedRow.deliveryStatus,
//           trackingId: updatedRow.trackingId,
//           dispatchDate: updatedRow.dispatchDate,
//         },
//         {
//           headers: {
//             Auth: `Bearer ${key}`,
//           },
//         }
//       );

//       setNotification("Row updated successfully!");
//       setNotificationType("success");

//       // Hide the notification after 5 seconds
//       setTimeout(() => {
//         setNotification("");
//       }, 5000);

//       fetchData(); // Refresh table data after update
//     } catch (error) {
//       console.error("Error updating row:", error);
//       setNotification("Failed to update the row. Please try again.");
//       setNotificationType("error");

//       // Hide the notification after 5 seconds
//       setTimeout(() => {
//         setNotification("");
//       }, 10000);
//     }
//   };

//   const indexOfLastRow = currentPage * rowsPerPage;
//   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

//   const filteredData = tableData.filter((row) =>
//     row.name?.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

//   const totalPages = Math.ceil(filteredData.length / rowsPerPage);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   // Padding empty rows
//   const paddedRows = [...currentRows];
//   while (paddedRows.length < rowsPerPage) {
//     paddedRows.push({}); // Add empty row
//   }

//   return (
//     <div>
//       <Header />
//  {notification && (
//         <div className={`notification ${notificationType}`}>
//           {notification}
//         </div>
//       )}
//       <div className="search-bar-container">
//         <input
//           type="text"
//           className="search-bar"
//           placeholder="Search by username"
//           value={searchQuery}
//           onChange={(e) => setSearchQuery(e.target.value)}
//         />
//       </div>

//       <div className="table-container">
//         {loading ? (
//           <div className="loading">Loading...</div>
//         ) : (
//           <div className="table-wrapper">
//             <table className="book-table">
//               <thead>
//                 <tr>
//                   <th>SNo.</th>
//                   <th>User Name</th>
//                   <th>Publication Title</th>
//                   <th>No. of Books Ordered</th>
//                   <th>Contact Number</th>
//                   <th>Email ID</th>
//                   <th>Payment Status</th>
//                   <th>Shipping Address</th>
//                   <th>Dispatch Date</th>
//                   <th>Tracking ID</th>
//                   <th>Delivery Status</th>
//                   <th>Actions</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {paddedRows.map((row, index) => (
//                   <tr key={index}>
//                     <td>{row.orderId ? indexOfFirstRow + index + 1 : ""}</td>
//                     <td>{row.name || ""}</td>
//                     <td>{row.publicationTitle || ""}</td>
//                     <td>{row.quantity || ""}</td>
//                     <td>{row.contact || ""}</td>
//                     <td>{row.email || ""}</td>
//                     <td>{row.orderedDate || ""}</td>
//                     <td>{row.shipingAddress || ""}</td>
//                     <td>
//   {row.orderId ? (
// <DatePicker
//   selected={
    
//     rowData[row.orderId]?.dispatchDate
//       ? new Date(rowData[row.orderId]?.dispatchDate)
//       : row.dispatchDate
//       ? new Date(row.dispatchDate)
//       : null
//   }
//   onChange={(date) => handleInputChange(row.orderId, "dispatchDate", format(date, 'yyyy-MM-dd'))}
//   placeholderText="Select Date"
//   dateFormat="yyyy-MM-dd"
//   className="date-picker inline-input"
  
// />


//   ) : (
//     ""
//   )}
// </td>
// <td>
//   {row.orderId ? (
//     <input
//       type="text"
//       className="inline-input"
//       value={rowData[row.orderId]?.trackingId || row.trackingId || ""}
//       onChange={(e) => handleInputChange(row.orderId, "trackingId", e.target.value)}
//       placeholder="Enter Tracking ID"
//     />
//   ) : (
//     ""
//   )}
// </td>
// <td>
//   {row.orderId ? (
//     <select
//       value={rowData[row.orderId]?.deliveryStatus || row.deliveryStatus || ""}
//       onChange={(e) => handleInputChange(row.orderId, "deliveryStatus", e.target.value)}
//       className="inline-input"
//     >
//       <option value="" disabled>
//         Select Status
//       </option>
 
                          // <option value="Delivered">Delivered</option>
                          // <option value="Processing">Processing</option>
                          // <option value="Dispatched">Dispatched</option>
//     </select>
//   ) : (
//     ""
//   )}
// </td>


//                     <td>
//                       {row.orderId && (
//                         <button
//                           className="submit-btn"
//                           onClick={() => handleUpdateRow(row.orderId)}
//                         >
//                           Save
//                         </button>
//                       )}
//                     </td>
//                   </tr>
                  
//                 ))}
//               </tbody>
//             </table>

//             <div className="pagination">
//               {Array.from({ length: totalPages }, (_, index) => (
//                 <button
//                   key={index + 1}
//                   className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
//                   onClick={() => handlePageChange(index + 1)}
//                 >
//                   {index + 1}
//                 </button>
//               ))}
//             </div>
//           </div>
//         )}
//       </div>

//       <Footer />
//     </div>
//   );
// };

// export default BookTableWithAPI;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
import DatePicker from "react-datepicker"; // DatePicker component
import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
import "./bookinfo.scss"; // External styles
import Header from "./header"; // Header component
import Footer from "./footer"; // Footer component
import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
import { format } from 'date-fns';
import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
const BookTableWithAPI = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupContent, setPopupContent] = useState(""); // Popup content
  const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
  const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
  const [searchQuery, setSearchQuery] = useState(""); // Search query for filtering rows
  const [rowData, setRowData] = useState({}); // Store row data
  const rowsPerPage = 9; // Number of rows per page
  const [notification, setNotification] = useState(""); // Message to show
  const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'

  // State to control calendar visibility
  const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const key = localStorage.getItem("jwtToken"); // Retrieve JWT token from local storage

      const response = await axios.get(`${BASE_URL}/dashboard/orders`, {
        headers: {
          Auth: `Bearer ${key}`, // Add the Authorization header with the JWT token
        },
      });

      if (Array.isArray(response.data)) {
        setTableData(response.data); // Set fetched data
        const initialRowData = response.data.reduce((acc, row) => {
          acc[row.orderId] = {
            dispatchDate: row.dispatchDate || "",
            trackingId: row.trackingId || "",
            deliveryStatus: row.deliveryStatus || "",
          };
          return acc;
        }, {});
        setRowData(initialRowData);
      } else {
        console.error("Invalid data format received:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Failed to fetch data from the server.");
    } finally {
      setLoading(false);
    }
  };

  const handleShowPopup = (content) => {
    setPopupContent(content);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const handleInputChange = (orderId, field, value) => {
    setRowData((prev) => ({
      ...prev,
      [orderId]: {
        ...prev[orderId],
        [field]: value,
      },
    }));
  };

  const handleUpdateRow = async (orderId) => {
    const updatedRow = rowData[orderId]; // Get the updated row data
    const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage

    try {
      // Send PUT request to update row data
      const response = await axios.put(
        `${BASE_URL}/dashboard/update-orders?orderId=${orderId}`,
        {
          deliveryStatus: updatedRow.deliveryStatus,
          trackingId: updatedRow.trackingId,
          dispatchDate: updatedRow.dispatchDate,
        },
        {
          headers: {
            Auth: `Bearer ${key}`,
          },
        }
      );

      setNotification("Row updated successfully!");
      setNotificationType("success");

      // Hide the notification after 5 seconds
      setTimeout(() => {
        setNotification("");
      }, 5000);

      fetchData(); // Refresh table data after update
    } catch (error) {
      console.error("Error updating row:", error);
      setNotification("Failed to update the row. Please try again.");
      setNotificationType("error");

      // Hide the notification after 5 seconds
      setTimeout(() => {
        setNotification("");
      }, 10000);
    }
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const filteredData = tableData.filter((row) =>
    row.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Padding empty rows
  const paddedRows = [...currentRows];
  while (paddedRows.length < rowsPerPage) {
    paddedRows.push({}); // Add empty row
  }

  return (
   <div id="root">
  <div className="page-container">
       <RedirectComponent />
      <Header />
    
    <main>
      {notification && (
        <div className={`notification ${notificationType}`}>
          {notification}
        </div>
      )}

      {/* <div className="search-bar-container">
        <input
          type="text"
          className="search-bar"
          placeholder="Search by username"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div> */}
      <div className="search-bar-container" style={{ marginLeft: '20px' , marginTop: '5px'}}>
  <input
    type="text"
    className="search-bar"
    placeholder="Search by username"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
  />
</div>


      <div className="table-container">
        {loading ? (
          <div className="loading">Loading...</div>
        ) : (
          <div className="table-wrapper">
            <table className="book-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>User Name</th>
                  <th>Book Title</th>
                  <th>Qty</th>
                  <th>Contact No.</th>
                  <th>Email ID</th>
                  <th>Order Date</th>
                  <th>Shipping Add.</th>
                  <th>Dispatch Date</th>
                  <th>Tracking ID</th>
                  <th>Delivery Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {paddedRows.map((row, index) => (
                  <tr key={index}>
                    <td>{row.orderId ? indexOfFirstRow + index + 1 : ""}</td>
                    <td>
                      {row.name ? (
                        <span
                          className="show-more"
                          onClick={() => handleShowPopup(row.name)}
                        >
                          {row.name.length > 15
                            ? row.name.substring(0, 15) + "..."
                            : row.name}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    {/* <td>{row.publicationTitle || ""}</td> */}

                    <td>
  {row.publicationTitle ? (
    <span
      className="show-more"
      onClick={() => handleShowPopup(row.publicationTitle)}
    >
      {row.publicationTitle.length > 15
        ? row.publicationTitle.substring(0, 15) + "..."
        : row.publicationTitle}
    </span>
  ) : (
    ""
  )}
</td>

                    <td>{row.quantity || ""}</td>
                    <td>{row.contact || ""}</td>
                    <td>
                      {row.email ? (
                        <span
                          className="show-more"
                          onClick={() => handleShowPopup(row.email)}
                        >
                          {row.email.length > 15
                            ? row.email.substring(0, 15) + "..."
                            : row.email}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{row.orderedDate || ""}</td>
                    {/* <td>{row.shipingAddress || ""}</td> */}
                    <td>
  {row.shipingAddress ? (
    <span
      className="show-more"
      onClick={() => handleShowPopup(row.shipingAddress)}
    >
      {row.shipingAddress.length > 15
        ? row.shipingAddress.substring(0, 15) + "..."
        : row.shipingAddress}
    </span>
  ) : (
    ""
  )}
</td>
                    <td>
                      {row.orderId ? (
                        <DatePicker
                          selected={
                            rowData[row.orderId]?.dispatchDate
                              ? new Date(rowData[row.orderId]?.dispatchDate)
                              : row.dispatchDate
                              ? new Date(row.dispatchDate)
                              : null
                          }
                          onChange={(date) =>
                            handleInputChange(row.orderId, "dispatchDate", format(date, "yyyy-MM-dd"))
                          }
                          placeholderText="Select Date"
                          dateFormat="yyyy-MM-dd"
                          className="date-picker inline-input"
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {row.orderId ? (
                        
                        <input
                          type="text"
                          className="inline-input"
                          value={rowData[row.orderId]?.trackingId || row.trackingId || ""}
                          onChange={(e) => handleInputChange(row.orderId, "trackingId", e.target.value)}
                          placeholder="Enter Tracking ID"
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {row.orderId ? (
                        <select
                          value={rowData[row.orderId]?.deliveryStatus || row.deliveryStatus || ""}
                          onChange={(e) => handleInputChange(row.orderId, "deliveryStatus", e.target.value)}
                          className="inline-input"
                        >
                          {/* <option value="">Select Status</option> */}
                         
                                            {/* <option value="Delivered">Delivered</option> */}
                          <option value="Processing">In Process</option>
                          <option value="Dispatched">Dispatched</option>
                                <option value="Delivered">Delivered</option>
                        </select>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {row.orderId ? (
                        <button
                          onClick={() => handleUpdateRow(row.orderId)}
                          className="submit-btn"
                        >
                          Save
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  onClick={() => handlePageChange(i + 1)}
                  className={currentPage === i + 1 ? "active" : ""}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Popup Modal */}
      {/* {popupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-popup" onClick={closePopup}>
              X
            </button>
            <div className="popup-body">
              <p>{popupContent}</p>
            </div>
          </div>
        </div>
      )} */}
      {popupVisible && (
  <div className="popup-overlay">
    <div className="popup-content">
      <button className="close-popup" onClick={closePopup}>
        X
      </button>
      <div className="popup-body">
        <p>{popupContent}</p>
      </div>
    </div>
  </div>
)}

</main>
 </div>
      <Footer />
      
    
    </div>
  );
};

export default BookTableWithAPI;
