// // // // // // import React from 'react';
// // // // // // import styled from 'styled-components'; // Add this line to import styled-components

// // // // // // // Footer Section
// // // // // // const Footer = styled.footer`
// // // // // //   background-color: #324e6a;
// // // // // //   color: white;
// // // // // //   text-align: center;
// // // // // //   padding: 20px;
// // // // // //   font-size: 1rem;
// // // // // //   position: relative;
// // // // // //   bottom: 0;
// // // // // //   width: 100%;
// // // // // // `;

// // // // // // const FooterLinks = styled.div`
// // // // // //   margin-top: 10px;
// // // // // //   a {
// // // // // //     color: #7cb2d0;
// // // // // //     text-decoration: none;
// // // // // //     margin: 0 10px;
// // // // // //     font-weight: bold;

// // // // // //     &:hover {
// // // // // //       text-decoration: underline;
// // // // // //     }
// // // // // //   }
// // // // // // `;

// // // // // // const AppFooter = () => {
// // // // // //   return (
// // // // // //     <Footer>
// // // // // //       <p>© 2024 National Institute of Nutrition. All rights reserved.</p>
// // // // // //       <FooterLinks>
// // // // // //         <a href="#contact-us">Contact Us</a>
// // // // // //         <a href="#privacy-policy">Privacy Policy</a>
// // // // // //       </FooterLinks>
// // // // // //     </Footer>
// // // // // //   );
// // // // // // };

// // // // // // export default AppFooter;
// // // // // import styled from 'styled-components';

// // // // // // Footer Section
// // // // // const Footer = styled.footer`
// // // // //   background-color: #324e6a;
// // // // //   color: white;
// // // // //   text-align: center;
// // // // //   padding: 20px;
// // // // //   font-size: 1rem;
// // // // //   position: relative;
// // // // //   bottom: 0;
// // // // //   width: 100%;
// // // // // `;

// // // // // const FooterLinks = styled.div`
// // // // //   margin-top: 10px;
// // // // //   display: flex;
// // // // //   justify-content: center;
// // // // //   gap: 20px;

// // // // //   a {
// // // // //     color: #7cb2d0;
// // // // //     text-decoration: none;
// // // // //     font-weight: bold;
// // // // //     transition: color 0.3s;

// // // // //     &:hover {
// // // // //       text-decoration: underline;
// // // // //       color: #a9d6eb; /* A lighter shade for hover */
// // // // //     }
// // // // //   }
// // // // // `;

// // // // // const AppFooter = () => {
// // // // //   return (
// // // // //     <Footer>
// // // // //       <p>© 2024 National Institute of Nutrition. All rights reserved.</p>
// // // // //       <FooterLinks>
// // // // //         <a href="#contact-us">Contact Us</a>
// // // // //         <a href="#privacy-policy">Privacy Policy</a>
// // // // //       </FooterLinks>
// // // // //     </Footer>
// // // // //   );
// // // // // };

// // // // // export default AppFooter;
// // // // import styled from 'styled-components'; // Import styled-components

// // // // // Footer Section
// // // // const Footer = styled.footer`
// // // //   background-color: #324e6a; /* Footer background color */
// // // //   color: white; /* Footer text color */
// // // //   text-align: center;
// // // //   padding: 20px;
// // // //   font-size: 1rem;
// // // //   position: relative;
// // // //   width: 100%;
// // // // `;

// // // // const FooterLinks = styled.div`
// // // //   margin-top: 10px;

// // // //   a {
// // // //     color: #7cb2d0; /* Link color */
// // // //     text-decoration: none;
// // // //     margin: 0 10px;
// // // //     font-weight: bold;

// // // //     &:hover {
// // // //       text-decoration: underline;
// // // //     }
// // // //   }
// // // // `;

// // // // const AppFooter = () => {
// // // //   return (
// // // //     <Footer>
// // // //       <p>© 2024 National Institute of Nutrition. All rights reserved.</p>
// // // //       <FooterLinks>
// // // //         <a href="#contact-us">Contact Us</a>
// // // //         <a href="#privacy-policy">Privacy Policy</a>
// // // //       </FooterLinks>
// // // //     </Footer>
// // // //   );
// // // // };

// // // // export default AppFooter;
// // // import { Height } from "@mui/icons-material";
// // // import React from "react";

// // // const Footer = () => {
// // //   const footerStyle = {
// // //     backgroundColor: "#34495e",
// // //     color: "#fff",
// // //     textAlign: "center",
// // //     padding: "20px 0", // Increased the padding to make the footer taller
// // //     // position: "fixed",
// // //     bottom: "0",
// // //     width: "100%",
// // //     fontSize: "14px",
// // //     boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
// // //   };

// // //   return (
// // //     <footer style={footerStyle}>
// // //       © 2024 National Institute of Nutrition. All rights reserved.
// // //     </footer>
// // //   );
// // // };

// // // export default Footer;

// // // 

// // import React from "react";

// // const Footer = () => {
// //   const footerStyle = {
// //     backgroundColor: "#34495e",
// //     color: "#fff",
// //     textAlign: "center",
// //     padding: "30px 20px", // Increased padding for taller footer
// //     minHeight: "100px", // Ensures a minimum height for the footer
// //     bottom: "0",
// //     width: "100%",
// //     fontSize: "14px",
// //     boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
// //   };

// //   const linkStyle = {
// //     color: "#ecf0f1",
// //     textDecoration: "none",
// //     margin: "0 10px",
// //   };

// //   return (
// //     <footer style={footerStyle}>
// //       <p>
// //         <a href="/contact-us" style={linkStyle}>
// //           Contact Us
// //         </a>
// //         |
// //         <a href="/privacy-policy" style={linkStyle}>
// //           Privacy Policy
// //         </a>
// //       </p>
// //       <p>© 2024 National Institute of Nutrition. All rights reserved.</p>
// //     </footer>
// //   );
// // };

// // export default Footer;
// import React from "react";

// const Footer = () => {
//   const footerStyle = {
//     backgroundColor: "#34495e",
//     color: "#fff",
//     textAlign: "center",
//     padding: "25px 15px",
//     minHeight: "100px",
//     bottom: "0",
//     width: "100%",
//     fontSize: "14px",
//     boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
//     display: "flex", // Enable flexbox layout
//     flexDirection: "column", // Arrange items in a column
//     justifyContent: "center", // Vertically center content
//     alignItems: "center", // Horizontally center content
//   };

//   const linkStyle = {
//     color: "#ecf0f1",
//     textDecoration: "none",
//     margin: "0 10px",
//   };

//   return (
//     <footer style={footerStyle}>
//        <p>© 2024 National Institute of Nutrition. All rights reserved.</p>
//       <p>
//         <a href="/contact-us" style={linkStyle}>
//           Contact Us
//         </a>
//         |
//         <a href="/privacypolicy" style={linkStyle}>
//           Privacy Policy
//         </a>
//       </p>
     
//     </footer>
//   );
// };

// export default Footer;
import React from "react";
import { Link } from "react-router-dom"; // Use Link from react-router-dom

const Footer = () => {
  const footerStyle = {
    backgroundColor: "#34495e",
    color: "#fff",
    textAlign: "center",
    padding: "25px 15px",
    minHeight: "100px",
    bottom: "0",
    width: "100%",
    fontSize: "14px",
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const linkStyle = {
    color: "#ecf0f1",
    textDecoration: "none",
    margin: "0 10px",
  };

  return (
    <footer style={footerStyle}>
      <p>© 2024 National Institute of Nutrition. All rights reserved.</p>
      <p>
        <Link to="/contactUs" style={linkStyle}>
          Contact Us
        </Link>
        |
        <Link to="/privacypolicy" style={linkStyle}>
          Privacy Policy
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
