
// //=====================================review=====================================
// import React from 'react';

// const RatingsAndReviews = () => {
//   // Data for ratings
//   const ratingsData = [
//     { stars: '5★', count: 332 },
//     { stars: '4★', count: 700 },
//     { stars: '3★', count: 346 },
//     { stars: '2★', count: 127 },
//     { stars: '1★', count: 368 }
//   ];

//   // Find maximum count
//   const maxCount = Math.max(...ratingsData.map(item => item.count));

//   return (
//     <div className="card z-index-3 ratings-container" style={{ marginTop: '40px' , height: '360px'}}>
//       <style>{`
//         .ratings-container {
//           width: 135%; /* Adjusted width */
//           max-width: 1200px; /* Increased maximum width */
//           margin: 20px auto; /* Add top and bottom margins */
//           padding: 10px; /* Add padding for better spacing */
//           overflow-x: hidden; /* Hide horizontal scrollbar */
//         }

//         /* Adjust width for smaller screens */
//         @media only screen and (max-width: 768px) {
//           .ratings-container {
//             max-width: 100%; /* Full width for smaller screens */
//             width: auto; /* Reset width */
//             overflow-x: auto; /* Add horizontal scrollbar on smaller screens */
//           }
//         }
        
//         .rating-row {
//           display: flex;
//           align-items: center;
//           justify-content: flex-start;
//           margin-bottom: 5px;
//         }
//         .star-label {
//           margin-right: 10px;
//         }
//         .progress-bar1 {
//           flex: 1; /* Take remaining space */
//           height: 10px;
//           background-color: #ddd;
//           border-radius: 5px;
//           overflow: hidden;
//           margin-right: 10px;
//         }
//         .progress-value {
//           height: 100%;
//           background-color: #5cb85c;
//         }
//         .ratings-summary {
//           text-align: center; /* Center align content */
//           margin-bottom: 5px; /* Adjust bottom margin */
//         }
//         h6 {
//           font-size: 24px; /* Set font size */
//           font-weight: bold; /* Set font weight to bold */
//           margin-bottom: 10px; /* Add margin between heading and rating info */
//           margin-top: 0; /* Remove default top margin */
//         }
//         .ratings-reviews {
//           font-size: 18px; /* Adjust font size for ratings and reviews */
//           margin-top: 5px; /* Add margin between ratings and reviews */
//         }
//       `}</style>

//       <div className="ratings-summary">
//         <h6>Ratings &amp; Reviews</h6>
//         <h6>4.6★</h6>
//         <div className="ratings-reviews">
//           220 Ratings &amp; <br /> 18 Reviews
//         </div>
//       </div>

//       <div className="ratings-summary">
//         <div className="overall-rating">
//           {ratingsData.map((rating, index) => (
//             <div className="rating-row" key={index}>
//               <span className="star-label">{rating.stars}</span>
//               <div className="progress-bar1" style={{ width: '50%' }}>
//                 <div className="progress-value" style={{ width: `${(rating.count / maxCount) * 100}%` }}></div>
//               </div>
//               <span className="rating-count">{rating.count}</span>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RatingsAndReviews;

import React from 'react';

const RatingsAndReviews = () => {
  // Data for ratings
  const ratingsData = [
    { stars: '5★', count: 332 },
    { stars: '4★', count: 700 },
    { stars: '3★', count: 346 },
    { stars: '2★', count: 127 },
    { stars: '1★', count: 368 }
  ];

  // Find maximum count
  const maxCount = Math.max(...ratingsData.map(item => item.count));

  return (
    <div className="card z-index-3 ratings-container" style={{ marginTop: '40px', maxWidth: '100%', overflowX: 'auto' }}>
      <style>{`
        .ratings-container {
          width: 100%;
          margin: 20px auto;
          padding: 10px;
        }

        .rating-row {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 5px;
        }

        .star-label {
          margin-right: 10px;
        }

        .progress-bar1 {
          flex: 1;
          height: 10px;
          background-color: #ddd;
          border-radius: 5px;
          overflow: hidden;
          margin-right: 10px;
        }

        .progress-value {
          height: 100%;
          background-color: #5cb85c;
        }

        .ratings-summary {
          text-align: center;
          margin-bottom: 5px;
        }

        h6 {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
          margin-top: 0;
        }

        .ratings-reviews {
          font-size: 18px;
          margin-top: 5px;
        }
      `}</style>

      <div className="ratings-summary">
        <h6>Ratings &amp; Reviews</h6>
        <h6>4.6★</h6>
        <div className="ratings-reviews">
          220 Ratings &amp; <br /> 18 Reviews
        </div>
      </div>

      <div className="ratings-summary">
        <div className="overall-rating">
          {ratingsData.map((rating, index) => (
            <div className="rating-row" key={index}>
              <span className="star-label">{rating.stars}</span>
              <div className="progress-bar1">
                <div className="progress-value" style={{ width: `${(rating.count / maxCount) * 100}%` }}></div>
              </div>
              <span className="rating-count">{rating.count}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RatingsAndReviews;
